import { FC, HTMLAttributes } from 'react';
import { ErrorMessage, FieldHookConfig, useField } from 'formik';
import style from './phone-number.module.scss';
import { AsYouType, E164Number } from 'libphonenumber-js';

type AllowedValue = string;

type PhoneNumberProps = FieldHookConfig<AllowedValue> &
  HTMLAttributes<HTMLInputElement> & {
    name: string;
    label?: string;
  };

const PhoneNumber: FC<PhoneNumberProps> = ({ label, onChange, ...props }) => {
  const [field, { error, touched }, { setValue }] = useField({ ...props });

  const generic = props as HTMLAttributes<HTMLInputElement>;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    field.onChange(e);
    const value = e.currentTarget.value;
    const shouldFormat = /(.?\d){4,}/.test(value);
    if (shouldFormat) {
      const newValue = new AsYouType('US').input(value as E164Number);
      return setValue(newValue);
    }
    return value;
  };

  return (
    <div className={style.wrapper}>
      {label && <label htmlFor={props.id ?? props.name}>{label}</label>}
      <input
        className={touched && error ? style.error : ''}
        {...generic}
        {...field}
        onChange={handleChange}
      />
      <span className={style.error}>
        <ErrorMessage name={props.name} />
      </span>
    </div>
  );
};

export default PhoneNumber;
