import { defineObjectProperty } from './utils';

type Subscriber<TMessage> = (message: TMessage) => void;

interface Observer<TMessage> {
  notify: Subscriber<TMessage>;
}

class ObserverWrapper<TMessage> implements Observer<TMessage> {
  public constructor(private readonly subscriber: Subscriber<TMessage>) {
    this.subscriber = subscriber;
  }

  public notify(message: TMessage): void {
    this.subscriber(message);
  }
}

class Subscription {
  public constructor(private readonly callback: () => void) {}

  public unsubscribe() {
    this.callback();
  }
}

export class Observable<TMessage = void> {
  private readonly subscribers: Array<Observer<TMessage>> = [];

  public subscribe(subscriber: Subscriber<TMessage> | Observer<TMessage>) {
    const observer =
      typeof subscriber === 'function'
        ? new ObserverWrapper(subscriber)
        : subscriber;

    this.subscribers.push(observer);

    return new Subscription(() => {
      this.unsubscribe(observer);
    });
  }

  public dispatch(message: TMessage) {
    this.subscribers.forEach((subscriber) => {
      subscriber.notify(message);
    });
  }

  public clear() {
    this.subscribers.length = 0;
  }

  private unsubscribe(subscriber: Observer<TMessage>): void {
    const index = this.subscribers.indexOf(subscriber);

    if (index !== -1) {
      this.subscribers.splice(index, 1);
    }
  }
}

export const withObservable = <
  TObject extends object,
  TKey extends string,
  TMessage = void
>(
  object: TObject,
  key: TKey
) => defineObjectProperty(object, key, new Observable<TMessage>());
