import { FC } from 'react';
import { SVGIconProps } from './types';

const SVGIconHeaderNotes: FC<SVGIconProps> = ({
  width = 25,
  height = 24,
  stroke = 'white'
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.1318 5.46664H8.2651C7.23417 5.46664 6.39844 6.30238 6.39844 7.33331V18.5333C6.39844 19.5642 7.23417 20.4 8.2651 20.4H17.5984C18.6294 20.4 19.4651 19.5642 19.4651 18.5333V7.33331C19.4651 6.30238 18.6294 5.46664 17.5984 5.46664H15.7318M10.1318 5.46664C10.1318 6.49757 10.9675 7.33331 11.9984 7.33331H13.8651C14.896 7.33331 15.7318 6.49757 15.7318 5.46664M10.1318 5.46664C10.1318 4.43571 10.9675 3.59998 11.9984 3.59998H13.8651C14.896 3.59998 15.7318 4.43571 15.7318 5.46664M12.9318 12H15.7318M12.9318 15.7333H15.7318M10.1318 12H10.1411M10.1318 15.7333H10.1411"
      stroke={stroke}
      strokeWidth="1.67"
      strokeLinecap="round"
    />
  </svg>
);

export default SVGIconHeaderNotes;
