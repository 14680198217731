import { FC } from 'react';
import { SVGIconProps as BaseSVGIconProps } from './types';
import cx from 'classnames';

/** Avaliable icons */
import SVGIconBars from './SVGIconBars';
import SVGIconBuilding from './SVGIconBuilding';
import SVGIconLogout from './SVGIconLogout';
import SVGIconOffice from './SVGIconOffice';
import SVGIconCog from './SVGIconCog';
import SVGIconCash from './SVGIconCash';
import SVGIconQuestion from './SVGIconQuestion';
import SVGIconQuestionCircle from './SVGIconQuestionCircle';
import SVGIconCarretDown from './SVGIconCarretDown';
import SVGIconCheckmark from './SVGIconCheckmark';
import SVGIconCheckmarkCircle from './SVGIconCheckmarkCircle';
import SVGIconCloseCircle from './SVGIconCloseCircle';
import SVGIconDollarCircle from './SVGIconDollarCircle';
import SVGIconEye from './SVGIconEye';
import SVGIconEyeSlash from './SVGIconEyeSlash';
import SVGIconFiles from './SVGIconFiles';
import SVGIconForms from './SVGIconForms';
import SVGIconMinus from './SVGIconMinus';
import SVGIconMoney from './SVGIconMoney';
import SVGIconNotes from './SVGIconNotes';
import SVGIconPlus from './SVGIconPlus';
import SVGIconSun from './SVGIconSun';
import SVGIconTimes from './SVGIconTimes';
import SVGIconUpDownArrow from './SVGIconUpDownArrow';
import SVGIconUpload from './SVGIconUpload';
import SVGIconDownload from './SVGIconDownload';
import SVGIconUser from './SVGIconUser';
import SVGIconManager from './SVGIconManager';
import SVGIconAddUsers from './SVGIconAddUser';
import SVGIconUsers from './SVGIconUsers';
import SVGIconWarning from './SVGIconWarning';
import SVGIconLock from './SVGIconLock';
import SVGIconLockCircle from './SVGIconLockCircle';
import SVGIconLink from './SVGIconLink';
import SVGIconMessage from './SVGIconMessage';
import SVGIconPhone from './SVGIconPhone';
import SVGIconArrowLeft from './SVGIconArrowLeft';
import SVGIconArrowRight from './SVGIconArrowRight';
import SVGIconArrowLeftRight from './SVGIconArrowLeftRight';
import SVGIconEdit from './SVGIconEdit';
import SVGIconExclamationCircle from './SVGIconExclamationCircle';
import SVGIconSearch from './SVGIconSearch';
import SVGIconInfo from './SVGIconInfo';
import SVGIconDashboard from './SVGIconDashboard';
import SVGIconBenefits from './SVGIconBenefits';
import SVGIconBulkUploads from './SVGIconBulkUploads';
import SVGIconCompanyProfile from './SVGIconCompanyProfile';
import SVGIconCovidInfo from './SVGIconCovidInfo';
import SVGIconPPP from './SVGIconPPP';
import SVGIconProfitAndLoss from './SVGIconProfitAndLoss';
import SVGIconAllFiles from './SVGIconAllFiles';
import SVGIconPayrollWages from './SVGIconPayrollWages';
import SVGIconCustomerProfile from './SVGIconCustomerProfile';
import SVGIconPayrollTaxReturns from './SVGIconPayrollTaxReturns';
import SVGIconHeaderNotes from './SVGIconHeaderNotes';
import SVGIconChat from './SVGIconChat';
import SVGIconChatBubble from './SVGIconChatBubble';
import SVGIconTrash from './SVGIconTrash';
import SVGIconEnvelope from './SVGIconEnvelope';
import SvgIconAddFIle from './SVGIconAddFile';
import SVGIconHamburgerMenu from './SVGIconHamburgerMenu';
import SVGIconClose from './SVGIconClose';
import SVGIconSignedAgreements from './SVGIconSignedAgreements';
import SVGIconSignOut from './SVGIconSignOut';
import SVGIconFab from './SVGIconFab';
import SVGIconAlert from './SVGIconAlert';
import SVGIconRejected from './SVGIconRejected';
import SVGIconChevronDown from './SVGIconChevronDown';
import SVGIconDropdown from './SVGIconDropdown';
import SVGIconChevronLeft from './SVGIconChevronLeft';
import SVGIconPageRight from './SVGIconPageRight';
import SVGIconPageLeft from './SVGIconPageLeft';
import SVGIconCheckmarkSidebar from './SVGIconCheckmarkSidebar';
import SVGIconErrataNotCompleted from './SVGIconErrataNotCompleted';
import SVGIconNavChevronDdown from './SVGIconNavChevronDown';
import SVGIconClockCircle from './SVGIconClockCircle';
import SVGIconXFiles from './SVGIconXFiles';
import SVGIconCaseNotes from './SVGIconCaseNotes';
import SVGIconRotateLeft from './SVGIconRotateLeft';
import SVGIconRotateRight from './SVGIconRotateRight';
import SVGIconCarretLeft from './SVGIconCarretLeft';
import SVGIconCarretRight from './SVGIconCarretRight';
import SVGIconZoomIn from './SVGIconZoomIn';
import SVGIconZoomOut from './SVGIconZoomOut';
import SVGIconPlusFiles from './SVGIconPlusFiles';
import SVGIconFlag from './SVGIconFlag';
import SVGIconSquare from './SVGIconSquare';
import SVGIconIR from './SVGIconIR';
import SVGIconReturn from './SVGIconReturn';
import SVGIconTriangleAlert from './SVGIconTriangleAlert';
import SVGIconTriangleAlertCircle from './SVGIconTriangleAlertCircle';
import SVGIconArrowUp from './SVGIconArrowUp';
import SVGIconShield from './SVGIconShield';
import SVGIconEditPen from './SVGIconEditPen';
import SVGIconFileList from './SVGIconFileList';
import SVGIconPaperAirplane from './SVGIconPaperAirplane';
import SVGIconReplace from './SVGIconReplace';
import SVGIconConsentForm from './SVGIconConsentForm';
import SVGIconFileController from './SVGIconFileController';
import SVGIconUploadCloud from './SVGIconUploadCloud';

/* eslint no-useless-computed-key: "off" */
export const Variants = {
  ['bars']: SVGIconBars,
  ['building']: SVGIconBuilding,
  ['checkmark']: SVGIconCheckmark,
  ['eye']: SVGIconEye,
  ['exclamation-circle']: SVGIconExclamationCircle,
  ['files']: SVGIconFiles,
  ['forms']: SVGIconForms,
  ['minus']: SVGIconMinus,
  ['money']: SVGIconMoney,
  ['notes']: SVGIconNotes,
  ['case-notes']: SVGIconCaseNotes,
  ['plus']: SVGIconPlus,
  ['sun']: SVGIconSun,
  ['times']: SVGIconTimes,
  ['upload']: SVGIconUpload,
  ['download']: SVGIconDownload,
  ['user']: SVGIconUser,
  ['manager']: SVGIconManager,
  ['add-user']: SVGIconAddUsers,
  ['users']: SVGIconUsers,
  ['warning']: SVGIconWarning,
  ['logout']: SVGIconLogout,
  ['office']: SVGIconOffice,
  ['cog']: SVGIconCog,
  ['cash']: SVGIconCash,
  ['question']: SVGIconQuestion,
  ['question-circle']: SVGIconQuestionCircle,
  ['carret-down']: SVGIconCarretDown,
  ['carret-left']: SVGIconCarretLeft,
  ['carret-right']: SVGIconCarretRight,
  ['checkmark-circle']: SVGIconCheckmarkCircle,
  ['checkmark-sidebar']: SVGIconCheckmarkSidebar,
  ['close-circle']: SVGIconCloseCircle,
  ['dollar-circle']: SVGIconDollarCircle,
  ['eye-slash']: SVGIconEyeSlash,
  ['up-down-arrow']: SVGIconUpDownArrow,
  ['lock']: SVGIconLock,
  ['lock-circle']: SVGIconLockCircle,
  ['link']: SVGIconLink,
  ['phone']: SVGIconPhone,
  ['message']: SVGIconMessage,
  ['arrow-right']: SVGIconArrowRight,
  ['arrow-left']: SVGIconArrowLeft,
  ['arrow-left-right']: SVGIconArrowLeftRight,
  ['edit']: SVGIconEdit,
  ['search']: SVGIconSearch,
  ['info']: SVGIconInfo,
  ['dashboard']: SVGIconDashboard,
  ['benefits']: SVGIconBenefits,
  ['bulk-uploads']: SVGIconBulkUploads,
  ['company-profile']: SVGIconCompanyProfile,
  ['covid-info']: SVGIconCovidInfo,
  ['ppp']: SVGIconPPP,
  ['profit-and-loss']: SVGIconProfitAndLoss,
  ['all-files']: SVGIconAllFiles,
  ['payroll-wages']: SVGIconPayrollWages,
  ['customer-profile']: SVGIconCustomerProfile,
  ['payroll-tax-returns']: SVGIconPayrollTaxReturns,
  ['header-notes']: SVGIconHeaderNotes,
  ['chat']: SVGIconChat,
  ['chat-bubble']: SVGIconChatBubble,
  ['trash']: SVGIconTrash,
  ['envelope']: SVGIconEnvelope,
  ['add-file']: SvgIconAddFIle,
  ['hamburger-menu']: SVGIconHamburgerMenu,
  ['close']: SVGIconClose,
  ['signed-agreements']: SVGIconSignedAgreements,
  ['sign-out']: SVGIconSignOut,
  ['fab']: SVGIconFab,
  ['alert']: SVGIconAlert,
  ['chevron-down']: SVGIconChevronDown,
  ['chevron-left']: SVGIconChevronLeft,
  ['page-right']: SVGIconPageRight,
  ['page-left']: SVGIconPageLeft,
  ['rejected']: SVGIconRejected,
  ['dropdown']: SVGIconDropdown,
  ['errata-not-completed']: SVGIconErrataNotCompleted,
  ['nav-chevron-dropdown']: SVGIconNavChevronDdown,
  ['clock-circle']: SVGIconClockCircle,
  ['x-files']: SVGIconXFiles,
  ['rotate-left']: SVGIconRotateLeft,
  ['rotate-right']: SVGIconRotateRight,
  ['zoom-in']: SVGIconZoomIn,
  ['zoom-out']: SVGIconZoomOut,
  ['plus-files']: SVGIconPlusFiles,
  ['flag']: SVGIconFlag,
  ['square']: SVGIconSquare,
  ['ir']: SVGIconIR,
  ['return']: SVGIconReturn,
  ['triangleAlert']: SVGIconTriangleAlert,
  ['triangle-alert-circle']: SVGIconTriangleAlertCircle,
  ['arrow-up']: SVGIconArrowUp,
  ['shield']: SVGIconShield,
  ['edit-pen']: SVGIconEditPen,
  ['file-list']: SVGIconFileList,
  ['paper-airplane']: SVGIconPaperAirplane,
  ['replace']: SVGIconReplace,
  ['consent-form']: SVGIconConsentForm,
  ['file-controller']: SVGIconFileController,
  ['file-upload-cloud']: SVGIconUploadCloud
};

interface SVGIconProps extends BaseSVGIconProps {
  variant: keyof typeof Variants;
  className?: string;
}

const SVGIcon: FC<SVGIconProps> = ({ variant, className, ...props }) => (
  <span className={cx(className, 'd--fi')}>{Variants[variant](props)}</span>
);

export default SVGIcon;
