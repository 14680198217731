import { useMemo, useCallback } from 'react';
import dayjs from 'dayjs';
import { AnalyticsBrowser } from '@segment/analytics-next';
import Cookies from 'js-cookie';
import { useBeforeUnload } from 'react-router-dom';
import { useAuthContext } from 'context/AuthContext';
import client from 'api/client';

const WRITE_KEY = process.env.REACT_APP_SEGMENT_WRITE_KEY || 'WRITE_KEY';

// We can export this instance to share with rest of our codebase.
export const analytics = AnalyticsBrowser.load({
  writeKey: WRITE_KEY
});

const EVENT_VERSION = process.env.SEGMENT_EVENT_VERSION || '1.0';

const useTracker = () => {
  const onMountTime = useMemo(() => dayjs(), []);

  // The Segment Identify call lets you tie a user to their actions and record
  // traits about them. It includes a unique User ID and any optional traits you
  // know about the user, like their email, name, and more.
  const identifyUser = useCallback(
    async ({ email, ...props }: { email: string }) => {
      try {
        if (typeof analytics !== 'undefined') {
          return analytics.identify(email?.toLowerCase(), { ...props });
        } else {
          return client.post('segment/identify/', { email: email, ...props });
        }
      } catch (error) {
        console.error(error);
      }
    },
    []
  );

  // The group API call is how you associate an individual user with a group—be
  // it a company, organization, account, project, team or whatever other crazy
  // name you came up with for the same concept!
  const groupEvent = useCallback(
    async ({
      email,
      groupId,
      ...props
    }: {
      email?: string;
      groupId: string | undefined;
    }) => {
      if (groupId === undefined) return;

      try {
        if (typeof analytics !== 'undefined') {
          return analytics.group(groupId, { ...props });
        } else {
          return client.post('segment/group/', { email: email, ...props });
        }
      } catch (error) {
        console.error(error);
      }
    },
    []
  );

  //The track API call is how you record any actions your users perform, along
  //with any properties that describe the action.
  const trackEvent = useCallback(
    async ({
      label = 'Application Page Submission Completed',
      stepName,
      stepNumber,
      status = 'completed',
      email = undefined,
      ...props
    }: {
      label?: string;
      stepName?: string;
      stepNumber?: number;
      status?: string;
      email?: undefined;
      step?: keyof typeof STEP_EVENT;
    }) => {
      const now = dayjs();
      const time = now.diff(onMountTime, 'second'); // Diff defaults to miliseconds

      try {
        if (typeof analytics !== 'undefined') {
          return analytics.track(
            label,
            {
              step_name: stepName,
              step_number: stepNumber,
              time_to_complete: time, //time in seconds
              status: status,
              version: EVENT_VERSION,
              ...props
            },
            {
              // context properties SEND WITH EVERY EVENT
              amplitude_session_id: Cookies.get('analytics_session_id'), // THIS IS AUTOMATICALLY STORED BY AMPLITUDE IN THE LOCAL STORAGE

              fb_data: JSON.stringify({
                fbc: Cookies.get('_fbc'), // AUTOMATICALLY STORED BY FACEBOOK IN COOKIES
                fbp: Cookies.get('_fbp') // AUTOMATICALLY STORED BY FACEBOOK IN COOKIES
              })
            }
          );
        } else {
          return client.post('segment/track/', {
            email: email,
            label: label,
            step_name: stepName,
            step_number: stepNumber,
            time_to_complete: time, //time in seconds
            status: status,
            version: EVENT_VERSION,
            ...props
          });
        }
      } catch (error) {
        console.error(error);
      }
    },
    [onMountTime]
  );

  // The page call lets you record whenever a user sees a page of your website,
  // along with any optional properties about the page
  const pageEvent = useCallback(
    ({
      category,
      name,
      email,
      ...props
    }: { category?: string; name?: string; email?: string } = {}) => {
      try {
        if (typeof analytics !== 'undefined') {
          return analytics.page(category, name, { ...props });
        } else {
          return client.post('segment/page/', {
            email: email,
            category: category,
            ...props
          });
        }
      } catch (error) {
        console.error(error);
      }
    },
    []
  );

  const { user } = useAuthContext();

  return {
    identifyUser: identifyUser,
    groupEvent: groupEvent,
    trackEvent: trackEvent,
    pageEvent: pageEvent,
    trackStepCompleted: (
      step: keyof typeof STEP_EVENT,
      props?: { [key: string]: any },
      groupId?: string | null
    ) => {
      const stepEvent = STEP_EVENT[step];
      const userId: string | undefined = user ? user.uid : props?.uid;

      if (!userId) return;

      identifyUser({
        email: userId,
        ...{
          steps_completed: stepEvent.stepNumber,
          stepKey: step
        }
      });

      if (groupId) groupEvent({ groupId: groupId, ...props });

      trackEvent({
        label: 'Application Page Submission',
        step: step,
        ...stepEvent,
        ...props
      });

      trackEvent({
        label: 'Application Page Submission Completed',
        step: step,
        ...stepEvent
      });

      trackEvent({
        label: `Application Step Completed ${stepEvent.stepNumber}`,
        step: step,
        ...stepEvent
      });
    },
    useTrackStepAbandoned: (
      step: keyof typeof STEP_EVENT,
      props?: { [key: string]: any },
      groupId?: string | null
    ) => {
      useBeforeUnload(
        useCallback(() => {
          const stepEvent = STEP_EVENT[step];
          const userId: string | undefined = user ? user.uid : props?.uid;

          if (!userId) return;

          identifyUser({ email: userId });

          if (groupId) groupEvent({ groupId: groupId });

          trackEvent({
            label: 'Application Page Submission',
            status: 'abandoned',
            step: step,
            ...stepEvent,
            ...props
          });

          // Double tagging with different label.
          trackEvent({
            label: 'Application Page Abandoned',
            status: 'abandoned',
            step: step,
            ...stepEvent,
            ...props
          });
        }, [step, props, groupId])
      );
    }
  };
};

export default useTracker;

const STEP_EVENT = {
  'intake-form-1': {
    stepName: 'Intake Form 1',
    stepNumber: 0.91
  },
  'intake-form-2': {
    stepName: 'Intake Form 2',
    stepNumber: 0.92
  },
  'intake-form-3': {
    stepName: 'Intake Form 3',
    stepNumber: 0.93
  },
  register: {
    stepName: 'Start Application',
    stepNumber: 1,
    steps_completed: 1
  },
  'getting-started': {
    stepName: 'Account Manager Interstitial',
    stepNumber: 2
  },
  'user-profile': {
    stepName: 'Confirm your contact information',
    stepNumber: 3
  },
  'company-info-general': {
    stepName: 'Company information',
    stepNumber: 4
  },
  'company-info-ownership': {
    stepName: 'Company information',
    stepNumber: 5
  },
  'company-info-affiliated': {
    stepName: 'Affiliated Company Information',
    stepNumber: 6
  },
  'company-info-peo': { stepName: 'PEO Information', stepNumber: 7 },
  'company-info-banking': {
    stepName: 'Banking Information',
    stepNumber: 8
  },
  'company-info-employee-general': {
    stepName: 'Employee Information',
    stepNumber: 9
  },
  'company-info-employee-owners': {
    stepName: 'Employee Information',
    stepNumber: 10
  },
  'company-info-employee-family': {
    stepName: 'Employee Information',
    stepNumber: 11
  },
  'covid-disruptions': { stepName: 'COVID-19 Information', stepNumber: 12 },
  'covid-quarters-impact': {
    stepName: 'COVID-19 Information',
    stepNumber: 13
  },
  'covid-disruptions-additional': {
    stepName: 'COVID-19 Information',
    stepNumber: 14
  },
  'covid-disruptions-description': {
    stepName: 'COVID-19 Information',
    stepNumber: 15
  },
  'covid-completed': { stepName: 'COVID-19 Information', stepNumber: 16 },
  'ppp-general': { stepName: 'PPP Information', stepNumber: 17 },
  'ppp-upload': { stepName: 'PPP Information', stepNumber: 18 },
  'payroll-started': {
    stepName: "You're Almost There Interstitial",
    stepNumber: 19
  },
  'payroll-wages': { stepName: 'Monthly Payroll Wages', stepNumber: 20 },
  'benefits-general': {
    stepName: 'Employer Benefit Invoices',
    stepNumber: 21
  },
  'payroll-tax-returns-general': {
    stepName: 'Payroll Tax Returns',
    stepNumber: 22
  },
  'payroll-tax-returns-941': {
    stepName: 'Payroll Tax Returns',
    stepNumber: 23,
    payroll_tax_form_type: '941'
  },
  'payroll-tax-returns-943': {
    stepName: 'Payroll Tax Returns',
    stepNumber: 24,
    payroll_tax_form_type: '943'
  },
  'profit-and-loss': {
    stepName: 'Profit and Loss Statements',
    stepNumber: 25
  },
  review: { stepName: 'Review Your Application', stepNumber: 26 }
} as const;
