import { FC } from 'react';
import { SVGIconProps } from './types';

const SVGIconAddUsers: FC<SVGIconProps> = ({
  width = 14,
  height = 14,
  stroke = '#5514E6',
  strokeWidth = 1.67
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.7337 5.13372V7.00039M10.7337 7.00039V8.86706M10.7337 7.00039H12.6004M10.7337 7.00039H8.86706M7.62261 3.88928C7.62261 5.26386 6.5083 6.37817 5.13372 6.37817C3.75915 6.37817 2.64484 5.26386 2.64484 3.88928C2.64484 2.5147 3.75915 1.40039 5.13372 1.40039C6.5083 1.40039 7.62261 2.5147 7.62261 3.88928ZM1.40039 11.9782C1.40039 9.91631 3.07186 8.24484 5.13372 8.24484C7.19559 8.24484 8.86706 9.91631 8.86706 11.9782V12.6004H1.40039V11.9782Z"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SVGIconAddUsers;
