import { SVGIconProps } from './types';

export default function SVGIconPaperAirplane({
  width = 16,
  height = 16,
  stroke = '#2E2E2E',
  fill = '#5514E6'
}: SVGIconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.2127 8.62611C13.4498 8.50754 13.5996 8.26516 13.5996 8.00002C13.5996 7.73488 13.4498 7.49249 13.2127 7.37392L3.41268 2.47393C3.1644 2.34979 2.86621 2.38409 2.65259 2.56137C2.43898 2.73866 2.35031 3.02542 2.42657 3.29233L3.42657 6.79232C3.51243 7.09283 3.7871 7.30001 4.09963 7.30001L7.29963 7.30001C7.68622 7.30001 7.99962 7.61341 7.99962 8.00001C7.99962 8.38661 7.68622 8.70001 7.29963 8.70001L4.09964 8.70001C3.7871 8.70001 3.51243 8.90719 3.42657 9.2077L2.42657 12.7077C2.35031 12.9746 2.43898 13.2614 2.65259 13.4387C2.8662 13.6159 3.16439 13.6502 3.41268 13.5261L13.2127 8.62611Z"
        fill={fill}
        stroke={stroke}
      />
    </svg>
  );
}
