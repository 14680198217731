import { FC } from 'react';
import { SVGIconProps } from './types';

const SVGIconCash: FC<SVGIconProps> = ({
  width = 16,
  height = 13,
  stroke = '#2E2E2E'
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.8889 4.06717V2.51161C11.8889 1.6525 11.1924 0.956055 10.3333 0.956055H2.55556C1.69645 0.956055 1 1.6525 1 2.51161V7.17828C1 8.03739 1.69645 8.73383 2.55556 8.73383H4.11111M5.66667 11.8449H13.4444C14.3036 11.8449 15 11.1485 15 10.2894V5.62272C15 4.76361 14.3036 4.06717 13.4444 4.06717H5.66667C4.80756 4.06717 4.11111 4.76361 4.11111 5.62272V10.2894C4.11111 11.1485 4.80756 11.8449 5.66667 11.8449ZM11.1111 7.95605C11.1111 8.81516 10.4147 9.51161 9.55556 9.51161C8.69645 9.51161 8 8.81516 8 7.95605C8 7.09694 8.69645 6.4005 9.55556 6.4005C10.4147 6.4005 11.1111 7.09694 11.1111 7.95605Z"
      stroke={stroke}
      strokeWidth="1.67"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SVGIconCash;

