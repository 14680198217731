import { useParams } from 'react-router-dom';

export function useApplicationId(): {
  applicationId: string;
} {
  const { applicationId = '' } = useParams();

  return {
    applicationId
  };
}
