import { AuthActionType, useAuthContext } from 'context/AuthContext';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { ReactComponent as Logo } from 'assets/Logo.svg';
import style from './header.module.scss';
import SVGIcon from 'components/icons/SVGIcon';
import { FC } from 'react';
// import { useTaxAttorneyApplication } from 'apps/tax-attorney/hooks/TaxAttorneyApplication/TaxAttorneyApplication';
import cx from 'classnames';
import { NavLink, useLocation } from 'react-router-dom';
import InitialsAvatar from 'components/atomic/InitialsAvatar/InitialsAvatar';

interface HeaderProps {
  hasSidebar?: boolean;
  // TODO: Longterm solution add way to include navs on a per nav item basis
  includeDealSpecificNavs?: boolean;
}

const Header: FC<HeaderProps> = ({
  hasSidebar = false
  // includeDealSpecificNavs = true
}) => {
  // const { applicationId } = useTaxAttorneyApplication();
  const { dispatch, user } = useAuthContext();
  const { pathname } = useLocation();
  const isSuper = pathname.includes('super-admin');
  return (
    <div className={cx(style.wrapper, hasSidebar ? style.hasSidebar : '')}>
      <Navbar variant="dark" className={style.wrapper}>
        <Navbar.Brand>
          <Logo />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
          <Nav className="justify-content-end">
            <NavLink
              to="/"
              className={cx(style.navLink, isSuper && style.activeNav)}
            >
              <SVGIcon variant="ir" stroke="white" />
              <span className={style.navIRLabel}>IR admins</span>
            </NavLink>
            <NavLink
              to="/tax-attorney/admin"
              className={cx(style.navLink, 'mr--7')}
            >
              <SVGIcon variant="company-profile" stroke="white" />
              <span className={style.navIRLabel}>Tax firms</span>
            </NavLink>
            <NavDropdown
              title={
                <>
                  {user?.avatar ? (
                    <div className="avatar-wrap">
                      <span className="gradient-border"></span>
                      <img
                        src={user?.avatar}
                        alt={user?.first_name}
                        className="cs-avatar--sm"
                      />
                    </div>
                  ) : (
                    <InitialsAvatar
                      name={`${user?.first_name} ${user?.last_name}`}
                      size="sm"
                    />
                  )}
                  <div className="ml--3 mr--2 d--f f--jc f--dc">
                    <div className={style.navItemLabel}>
                      {user?.first_name} {user?.last_name}
                    </div>
                    <div className="text-neutral-400 lh--0 fs--1">
                      {user?.title}
                    </div>
                  </div>
                  <SVGIcon variant="nav-chevron-dropdown" stroke="#2E2E2E" />
                </>
              }
              id="nav-dropdown-dark-example"
              className={cx(style.navDropdown, 'my-auto')}
            >
              {/* <NavDropdown.Item as={Link} to={'/tax-attorney/settings'}>
                <SVGIcon variant="cog" stroke="#2E2E2E" />
                Settings
              </NavDropdown.Item> */}
              <NavDropdown.Item
                className="logout-menu-item"
                onClick={() => {
                  dispatch({ type: AuthActionType.LOGOUT });
                }}
              >
                <SVGIcon variant="logout" stroke="#2E2E2E" />
                Logout
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
};

export default Header;
