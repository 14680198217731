import { FC } from 'react';
import ImageViewer from 'components/atomic/FileViewer/ImageViewer/ImageViewer';
import PDFViewer from 'components/atomic/FileViewer/PDFViewer/PDFViewer';
import { CSVViewer } from './CSVViewer/CSVViewer';
import SpreadsheetViewer from './SpreadsheetViewer/SpreadsheetViewer';
import { MicrosoftOfficeViewer } from './MicrosoftOfficeViewer/MicrosoftOfficeViewer';

interface FileViewerProps {
  url: string;
  extension?: string;
  label?: string;
}

const FileViewer: FC<FileViewerProps> = ({ url, extension, label }) => {
  switch (extension?.toLowerCase()) {
    case 'gif':
    case 'jpg':
    case 'jpeg':
    case 'png':
    case 'svg':
    case 'webp':
      return <ImageViewer url={url} label={label} />;

    case 'pdf':
      return <PDFViewer url={url} label={label} />;

    case 'csv':
      return <CSVViewer url={url} label={label} />;

    case 'xlsx':
      return <SpreadsheetViewer url={url} label={label} />;

    case 'doc':
    case 'docx':
    case 'xls':
    case 'xlsm':
      return <MicrosoftOfficeViewer url={url} />;

    default:
      return (
        <>
          Can't preview this file format, please <a href={url}>download</a> the
          document instead.
        </>
      );
  }
};

export default FileViewer;
