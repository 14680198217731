import { useMutation, useQueries } from '@tanstack/react-query';
import { Modal } from 'antd';
import {
  Button,
  Col,
  Dropbox,
  Loader,
  PhoneNumber,
  Row,
  Select,
  TextField,
  UploadField
} from 'components/atomic';
import { Formik, Form } from 'formik';
import { Dispatch, FC, SetStateAction } from 'react';
import { toast } from 'react-toastify';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import * as Yup from 'yup';
import { Nullable } from 'api/services/app/common/interfaces/common';
import {
  addMember,
  getAllMembersGroups,
  getAllMembersRoles
} from 'api/services/super-admin/requests';
import {
  MemberUser,
  MembersRequest
} from 'api/services/super-admin/interfaces';
import { SVGIcon } from 'components/icons';
import { SendbirdCreateUserRequest } from 'api/services/app/common/interfaces/sendbird-responses';
import { postCreateSendBirdUser } from 'api/services/app/common/requests/sendbird';
import { getAllTaxFirms } from 'api/services/tax-attorney-admin/requests';
import { isAxiosError } from 'axios';
import { collect } from 'helpers/Collection';

const AddMemberSchema = Yup.object().shape({
  user: Yup.object().shape({
    first_name: Yup.string().required('You must enter member first name'),
    last_name: Yup.string().required('You must enter member last name'),
    email: Yup.string()
      .email('Invalid email address')
      .required('Email address is required')
  }),
  phone: Yup.string()
    .test(
      'phone',
      'Invalid phone number',
      (value) => parsePhoneNumberFromString(value!, 'US')?.isValid() ?? false
    )
    .required('Please provide a mobile number'),
  title: Yup.string().required('You must enter a title'),
  role: Yup.number().required('You must select a member role'),
  hubspot_id: Yup.string().optional(),
  role_group: Yup.number().required('You must select a member group'),
  avatar: Yup.mixed().required('You must upload an avatar')
});

interface AddMemberValues {
  avatar: Nullable<File>;
  phone: string;
  title: string;
  hubspot_id: string;
  role: number;
  role_group: number;
  user: MemberUser;
  firm?: number;
}

interface AddMemberModalProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  onAction: () => void;
}

const AddMemberModal: FC<AddMemberModalProps> = ({
  open = false,
  setOpen,
  onAction
}) => {
  const [
    { data: roles, isLoading: rolesLoading },
    { data: groups, isLoading: groupsLoading },
    { data: firms, isLoading: firmsLoading }
  ] = useQueries({
    queries: [
      {
        queryKey: ['super-admin/member-roles'],
        queryFn: () => getAllMembersRoles()
      },
      {
        queryKey: ['super-admin/member-groups'],
        queryFn: () => getAllMembersGroups()
      },
      {
        queryKey: ['super-admin/firms'],
        queryFn: () => getAllTaxFirms()
      }
    ]
  });

  const { mutate } = useMutation({
    mutationFn: (payload: MembersRequest) => addMember(payload)
  });

  const { mutate: sendBirdPost } = useMutation({
    mutationFn: (payload: SendbirdCreateUserRequest) =>
      postCreateSendBirdUser(payload)
  });

  const initialValues: AddMemberValues = {
    phone: '',
    title: '',
    hubspot_id: '',
    role: 0,
    role_group: 0,
    avatar: null,
    firm: undefined,
    user: {
      first_name: '',
      last_name: '',
      email: '',
      username: ''
    }
  };

  if (rolesLoading || groupsLoading || firmsLoading) {
    return <Loader inContext={true} />;
  }

  const requiredRoleForSendbird = (role: number) => {
    const sendbirdRoles = ['CSC User', 'CPA'];
    const selectedRole = roles?.find(r => r.id === role)?.name

    if (selectedRole !== undefined) {
      return sendbirdRoles.includes(selectedRole)
    }
    return false;
  }

  return (
    <Modal
      open={open}
      footer={null}
      afterClose={() => { }}
      centered
      width={600}
      onCancel={() => setOpen(false)}
      closeIcon={<SVGIcon variant="close" stroke="black" />}
    >
      <h2 className="fs--3 fw--600 mb--4">Add a member</h2>

      <Formik
        initialValues={initialValues}
        validationSchema={AddMemberSchema}
        enableReinitialize
        validateOnMount
        onSubmit={(values, { setSubmitting, resetForm, setFieldError }) => {
          setSubmitting(true);
          values.user.username = values.user.email;
          mutate(values, {
            onSuccess() {
              toast.success('Member added successfully.');
              setOpen(false);
              onAction();
              resetForm();
              //Create a user if they're a CSC User or TA
              const role = requiredRoleForSendbird(values.role);
              if (role) {
                const payload: SendbirdCreateUserRequest = {
                  user_id: values.user.email,
                  is_customer: false,
                  email: values.user.email,
                  first_name: values.user.first_name,
                  last_name: values.user.last_name,
                  avatar: values.avatar
                };
                sendBirdPost(payload);
              }
            },
            onError(error) {
              if (isAxiosError(error)) {
                const { response } = error;
                if (response?.data?.type === 'validation-error') {
                  collect(response?.data?.errors).forEach((errors, key) => {
                    setFieldError(key as string, errors);
                  });
                }
                toast.error('An error occurred while adding the member');
              }
            },
            onSettled() {
              setSubmitting(false);
            }
          });
        }}
      >
        {({ values, isSubmitting, isValid, setFieldValue }) => (
          <Form>
            <Row>
              <Col md={6} sm={12} className="mb--6">
                <TextField name="user.first_name" label="First name*" />
              </Col>
              <Col md={6} sm={12} className="mb--6">
                <TextField name="user.last_name" label="Last name*" />
              </Col>
            </Row>
            <Row>
              <Col md={12} sm={12} className="mb--6">
                <TextField name="user.email" label="Email*" />
              </Col>
            </Row>
            <Row>
              <Col md={12} sm={12} className="mb--6">
                <PhoneNumber name="phone" label="Phone*" />
              </Col>
            </Row>
            <Row>
              <Col md={12} sm={12} className="mb--6">
                <TextField name="title" label="Title*" />
              </Col>
            </Row>
            <Row>
              <Col md={12} sm={12} className="mb--6">
                <Select
                  name="role"
                  label="Role*"
                  options={roles?.map(({ id, name }) => ({
                    value: id,
                    label: name
                  }))}
                />
              </Col>
            </Row>
            {(values.role === 7 || values.role === 10) && (
              <Row>
                <Col md={12} sm={12} className="mb--6">
                  <Select
                    name="firm"
                    label="Choose tax firm*"
                    options={firms?.map(({ id, name }) => ({
                      value: id,
                      label: name
                    }))}
                  />
                </Col>
              </Row>
            )}
            <Row>
              <Col md={12} className="mb--6">
                <TextField name="hubspot_id" label="Hubspot ID" />
              </Col>
            </Row>
            <Row>
              <Col md={12} className="mb--6">
                <Select
                  name="role_group"
                  label="Member group*"
                  options={groups?.map(({ id, name }) => ({
                    value: id,
                    label: name
                  }))}
                />
              </Col>
            </Row>

            <Row>
              <Col md={12}>
                <label className="cs-label">Avatar*</label>
                <UploadField
                  key="avatar"
                  name="avatar"
                  onError={() => {
                    toast.error('Failed to upload file.');
                  }}
                  onDrop={(files) => {
                    setFieldValue('avatar', files[0]);
                  }}
                  isDropbox={true}
                  className="cs-dropbox-landscape"
                >
                  {(props) => {
                    const file: Nullable<File> = values.avatar;
                    const url = file ? URL.createObjectURL(file) : '';

                    const hideDropbox = !!values.avatar;
                    return (
                      <>
                        {!hideDropbox && <Dropbox state={props} />}

                        {url && (
                          <div className="cs-dropbox-image">
                            <img src={url} alt="" />
                            <button
                              className="text-purple-500 fw--500 mt--2"
                              onClick={() => props.open()}
                              type="button"
                            >
                              Change logo
                            </button>
                          </div>
                        )}
                      </>
                    );
                  }}
                </UploadField>
              </Col>
            </Row>

            <Row>
              <Col md={12} className="mt--6 d--f f--ac f--jb">
                <Button
                  variant="quaternary"
                  type="button"
                  className="w--100 mr--2"
                  onClick={() => setOpen(false)}
                >
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  className="w--100 ml--2"
                  disabled={!isSubmitting && isValid ? false : true}
                  type="submit"
                >
                  Add
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default AddMemberModal;
