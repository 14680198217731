import { FC } from 'react';
import { SVGIconProps } from './types';

const SVGIconArrowLeftRight: FC<SVGIconProps> = ({
  width = 10,
  height = 12,
  fill = 'black',
  stroke = 'black',
  strokeWidth = 2
}) => (
  <svg
    width={width}
    height={height}
    fill={fill}
    viewBox="0 0 10 12"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.59961 2.50039C3.21301 2.50039 2.89961 2.81379 2.89961 3.20039C2.89961 3.58699 3.21301 3.90039 3.59961 3.90039L7.50966 3.90039L6.60464 4.80542C6.33127 5.07878 6.33127 5.522 6.60464 5.79537C6.878 6.06873 7.32122 6.06873 7.59458 5.79537L9.69459 3.69537C9.82586 3.56409 9.89961 3.38604 9.89961 3.20039C9.89961 3.01474 9.82586 2.83669 9.69459 2.70542L7.59459 0.605416C7.32122 0.332049 6.878 0.332049 6.60464 0.605416C6.33127 0.878783 6.33127 1.322 6.60464 1.59537L7.50966 2.50039L3.59961 2.50039Z"
      fill={fill}
    />
    <path
      d="M6.39961 9.50039C6.78621 9.50039 7.09961 9.18699 7.09961 8.80039C7.09961 8.41379 6.78621 8.10039 6.39961 8.10039L2.48956 8.10039L3.39459 7.19537C3.66795 6.922 3.66795 6.47878 3.39459 6.20542C3.12122 5.93205 2.678 5.93205 2.40464 6.20542L0.304634 8.30542C0.173359 8.43669 0.0996094 8.61474 0.0996094 8.80039C0.0996094 8.98604 0.173359 9.16409 0.304634 9.29537L2.40463 11.3954C2.678 11.6687 3.12122 11.6687 3.39458 11.3954C3.66795 11.122 3.66795 10.6788 3.39458 10.4054L2.48956 9.50039L6.39961 9.50039Z"
      fill={fill}
    />
  </svg>
);

export default SVGIconArrowLeftRight;
