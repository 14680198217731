import { FC } from 'react';
import { SVGIconProps } from './types';

const SVGIconLock: FC<SVGIconProps> = ({
  width = 16,
  height = 16,
  stroke = 'black',
  strokeWidth = 1.67
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 12 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.99926 8.86669V10.1111M2.26593 12.6H9.73259C10.4199 12.6 10.977 12.0429 10.977 11.3556V7.62225C10.977 6.93496 10.4199 6.3778 9.73259 6.3778H2.26593C1.57864 6.3778 1.02148 6.93496 1.02148 7.62225V11.3556C1.02148 12.0429 1.57864 12.6 2.26593 12.6ZM8.48815 6.3778V3.88891C8.48815 2.51434 7.37384 1.40002 5.99926 1.40002C4.62469 1.40002 3.51037 2.51434 3.51037 3.88891V6.3778H8.48815Z"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
    />
  </svg>
);

export default SVGIconLock;
