import { FC } from 'react';
import { SVGIconProps } from './types';

const SVGIconEyeSlash: FC<SVGIconProps> = ({
  width = 24,
  height = 20,
  fill = 'black',
  stroke = 'black'
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill={fill}
      stroke={stroke}
      d="M5.65124 3.47887C7.31249 2.18512 9.44249 1.19999 12 1.19999C15.03 1.19999 17.4562 2.58149 19.2225 4.22249C20.9775 5.84999 22.1512 7.76624 22.7062 9.13874C22.83 9.43499 22.83 9.76499 22.7062 10.0612C22.2037 11.2725 21.195 12.9787 19.71 14.5012L23.655 17.5912C24.045 17.8987 24.1162 18.465 23.8087 18.855C23.5012 19.245 22.935 19.3162 22.545 19.0087L0.344843 1.60837C-0.0463567 1.30162 -0.114944 0.736118 0.191693 0.344843C0.498368 -0.0463567 1.06387 -0.114944 1.45537 0.191693L5.65124 3.47887ZM7.11749 4.63124L8.84249 5.98124C9.68624 5.24624 10.7925 4.79999 12 4.79999C14.6512 4.79999 16.8 6.94874 16.8 9.59999C16.8 10.395 16.6087 11.1412 16.2675 11.8012L18.285 13.3837C19.5412 12.105 20.4712 10.6162 20.9475 9.59999C20.4037 8.44124 19.44 6.88124 17.9962 5.53874C16.455 4.10999 14.445 2.96624 12 2.96624C10.1062 2.96624 8.44124 3.66487 7.11749 4.63124ZM14.8087 10.6575C14.9325 10.3275 15 9.97124 15 9.56624C15 7.94249 13.6575 6.56624 12 6.56624C11.9737 6.56624 11.9512 6.59999 11.8912 6.59999C11.9737 6.79124 12 6.99374 12 7.16624C12 7.58249 11.91 7.94249 11.7525 8.26124L14.8087 10.6575ZM15.1612 15.5437L16.7325 16.7812C15.3712 17.5162 13.7925 18 12 18C8.96999 18 6.54374 16.62 4.77749 14.9775C3.02324 13.3162 1.85024 11.4 1.29224 10.0612C1.23135 9.91509 1.19999 9.75833 1.19999 9.59999C1.19999 9.44166 1.23135 9.2849 1.29224 9.13874C1.64999 8.27999 2.26087 7.16999 3.11587 6.05624L4.52999 7.16999C3.82874 8.04374 3.36599 8.90999 3.05437 9.56624C3.56324 10.725 4.55999 12.3187 6.00374 13.6612C7.54499 15.09 9.55499 16.2 12 16.2C13.1512 16.2 14.205 15.9525 15.1612 15.5437ZM7.19999 9.56624C7.19999 9.49124 7.20374 9.38624 7.21124 9.28124L9.31499 10.9387C9.70874 11.73 10.4437 12.3225 11.325 12.4912L13.4325 14.1825C12.9787 14.2912 12.4987 14.4 11.9662 14.4C9.34874 14.4 7.16624 12.2512 7.16624 9.56624H7.19999Z"
    />
  </svg>
);

export default SVGIconEyeSlash;
