import { useState, FC } from 'react';
import style from './verification-card.module.scss';
import SVGIcon from 'components/icons/SVGIcon';
import { Button } from 'components/atomic';
import { Modal, Col, Row } from 'antd';
import cx from 'classnames';
import { useMutation } from '@tanstack/react-query';
import { verifyApplicationStep } from 'api/services/account-manager/general/requests';
import {
  Step,
  StepVerificationRequest
} from 'api/services/account-manager/general/interfaces';
import { useAccountManagerApplication } from 'apps/account-manager/hooks/AccountManagerApplication/AccountManagerApplication';
import { NavLink } from 'react-router-dom';

interface VerificationProps {
  step: Step;
  isVerified: boolean;
  approvalDate?: string;
  title?: string;
  onVerify?: () => void;
  disabled?: boolean;
}

const VerificationCard: FC<VerificationProps> = ({
  step,
  isVerified,
  approvalDate,
  title,
  onVerify,
  disabled = false
}) => {
  const [open, setOpen] = useState(false);

  const { applicationId, refetchApproval } = useAccountManagerApplication();

  const { mutate, isLoading } = useMutation({
    mutationFn: (data: StepVerificationRequest) =>
      verifyApplicationStep(applicationId, data)
  });

  const showModal = () => {
    setOpen(true);
  };

  const handleOk = () => {
    mutate(
      { step },
      {
        onSuccess() {
          onVerify?.();
          refetchApproval();
        }
      }
    );
    setOpen(false);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <div className={style.wrapper}>
      <p className={style.title}>Is this information verified?</p>
      <p>If any information is unclear, please reach out to the customer.</p>
      <NavLink
        to={`/account-manager/${applicationId}/chat`}
        className={style.messageCustomer}
      >
        <SVGIcon variant="chat" stroke="#5514E6" />
        <span className={style.messageCustomerLabel}>Message customer</span>
      </NavLink>
      {isVerified ? (
        <>
          <div className={style.verifiedTag}>
            <SVGIcon
              variant="checkmark-circle"
              className="text-success-600 stroke"
            />
            Verified
          </div>
          <div className="mt-3">
            <span className={style.approvedLabel}>Approved on: </span>
            {approvalDate}
          </div>
        </>
      ) : (
        <Button
          as="button"
          type="button"
          className={cx(style.verificationButton, 'cs-btn cs-btn--primary')}
          onClick={showModal}
          disabled={disabled}
        >
          Mark as verified
        </Button>
      )}
      <Modal
        title={
          <p className={cx('align-center', style.modalTitle)}>
            Verify {title}?
          </p>
        }
        open={open}
        footer={null}
        centered
        confirmLoading={isLoading}
        onCancel={handleCancel}
        closable={false}
        width={384}
      >
        <div className="text-align-center">
          This action can’t be undone and the customer will be notified that
          this section was approved.
        </div>
        <Row justify="center">
          <Col className="align-center mt--7">
            <Button
              as="button"
              type="button"
              className="cs-btn cs-btn--primary mb-2"
              onClick={handleOk}
            >
              Mark as verified
            </Button>
            <Button as="button" variant="edit" size="md" onClick={handleCancel}>
              Cancel
            </Button>
          </Col>
        </Row>
      </Modal>
    </div>
  );
};

export default VerificationCard;
