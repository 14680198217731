import client from 'api/client';
import {
  ApplicationApprovalResponse,
  Step,
  StepVerificationRequest
} from 'api/services/account-manager/general/interfaces';
import { StepVerificationResponse } from '../interfaces/responses/general';
import { AxiosRequestConfig } from 'axios';

export const getStepVerification = async (
  applicationId: string | null,
  step: Step
): Promise<Partial<StepVerificationResponse>> =>
  (
    await client.get(
      `account-manager/step-verification/${applicationId}/?step=${step}`
    )
  )?.data;
export const verifyApplicationStep = async (
  applicationId: string | null,
  payload: StepVerificationRequest
) =>
  (
    await client.post(
      `account-manager/step-verification/${applicationId}/`,
      payload
    )
  )?.data;

export const getDownloadFile = async (
  url: string,
  name: string,
  useAxiosClient: boolean = false,
  axiosConfig?: AxiosRequestConfig
): Promise<void> => {
  let data;
  if (useAxiosClient) {
    const response = await client.get(
      url,
      axiosConfig || {
        responseType: 'blob'
      }
    );
    data = response.data;
  } else {
    const response = await fetch(url);
    data = await response.blob();
  }
  const link = document.createElement('a');

  link.href = (window.URL || window.webkitURL).createObjectURL(data);
  link.download = name;

  document.body.appendChild(link);

  link.click();

  document.body.removeChild(link);
};

export const getApplicationApproval = async (
  applicationId: string | null
): Promise<Partial<ApplicationApprovalResponse>> =>
  (await client.get(`account-manager/approve-application/${applicationId}/`))
    ?.data;

export const postApplicationApproval = async (
  applicationId: string | null
): Promise<Partial<ApplicationApprovalResponse>> =>
  (await client.post(`account-manager/approve-application/${applicationId}/`))
    ?.data;
