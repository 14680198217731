import posthog from "posthog-js";
import { PropsWithChildren } from "react";
import { PostHogProvider } from "posthog-js/react";

const options = {
  api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
};
const client = posthog.init(
  process.env.REACT_APP_PUBLIC_POSTHOG_KEY,
  options,
  "ir_erc",
);
export default function FeatureFlagProvider({ children }: PropsWithChildren) {
  if (client) {
    return (
      <PostHogProvider
        client={client}
      >
        {children}
      </PostHogProvider>
    );
  }

  return <></>;
}
