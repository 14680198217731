import { CookieStorage } from './CookieStorage';
import { LocalStorage } from './LocalStorage';
import { SessionStorage } from './SessionStorage';

export enum ClientStorageType {
  COOKIE_STORAGE = 'COOKIE_STORAGE',
  LOCAL_STORAGE = 'LOCAL_STORAGE',
  SESSION_STORAGE = 'SESSION_STORAGE'
}

export interface ClientStorage {
  set: (key: string | symbol, value: any, ttl?: number) => void;
  has: (key: string | symbol) => boolean;
  get: (key: string | symbol) => any;
  delete: (key: string | symbol) => void;
  refresh: (key: string | symbol, ttl?: number) => boolean;
}

export function ClientStorageFactory(type: ClientStorageType): ClientStorage {
  switch (type) {
    case ClientStorageType.COOKIE_STORAGE:
      return new CookieStorage();
    case ClientStorageType.LOCAL_STORAGE:
      return new LocalStorage();
    default:
      return new SessionStorage();
  }
}

class StorageSingleton implements ClientStorage {
  public set(key: string | symbol, value: any, ttl?: number): void {
    this.instance().set(key, value, ttl);
  }

  public has(key: string | symbol): boolean {
    return this.instance().has(key);
  }

  public get(key: string | symbol): any {
    return this.instance().get(key);
  }

  public delete(key: string | symbol): void {
    this.instance().delete(key);
  }

  public refresh(key: string | symbol, ttl?: number): boolean {
    return this.instance().refresh(key, ttl);
  }

  private storage: ClientStorage | null = null;

  private instance(): ClientStorage {
    return this.storage
      ? this.storage
      : (this.storage = ClientStorageFactory(
          process.env.REACT_APP_API_CLIENT_STORAGE ??
            ClientStorageType.SESSION_STORAGE
        ));
  }
}

const session = new StorageSingleton();

export default session;
