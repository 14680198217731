import { FC } from 'react';
import { SVGIconProps } from './types';

const SVGIconCheckmark: FC<SVGIconProps> = ({
  width = 17,
  height = 16,
  stroke = '#4410B8',
  strokeWidth = '1.67'
}) => (
  <svg
    width={width}
    height={height}
    fill="none"
    viewBox="0 0 17 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.14743 7.8584L7.71581 9.42678L10.8526 6.29002M5.23364 2.13149C5.79632 2.08658 6.3305 1.86532 6.76013 1.49919C7.76271 0.644801 9.23729 0.644801 10.2399 1.49919C10.6695 1.86532 11.2037 2.08658 11.7664 2.13149C13.0794 2.23627 14.1221 3.27896 14.2269 4.59204C14.2718 5.15472 14.4931 5.6889 14.8592 6.11853C15.7136 7.12111 15.7136 8.59569 14.8592 9.59827C14.4931 10.0279 14.2718 10.5621 14.2269 11.1248C14.1221 12.4378 13.0794 13.4805 11.7664 13.5853C11.2037 13.6302 10.6695 13.8515 10.2399 14.2176C9.23729 15.072 7.76271 15.072 6.76013 14.2176C6.3305 13.8515 5.79632 13.6302 5.23364 13.5853C3.92056 13.4805 2.87787 12.4378 2.77309 11.1248C2.72818 10.5621 2.50692 10.0279 2.14079 9.59827C1.2864 8.59569 1.2864 7.12111 2.14079 6.11853C2.50692 5.6889 2.72818 5.15472 2.77309 4.59204C2.87787 3.27896 3.92056 2.23627 5.23364 2.13149Z"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SVGIconCheckmark;
