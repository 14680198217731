import { FC } from 'react';
import { SVGIconProps } from './types';

const SVGIconRotateRight: FC<SVGIconProps> = ({
  width = 15,
  height = 12,
  stroke = '#2E2E2E',
  strokeWidth = 1
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 15 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.20311 0.570312C3.89294 0.570312 1.20312 3.00457 1.20312 6.00025C1.20312 8.99593 3.89294 11.4302 7.20311 11.4302C7.28347 11.4312 7.36088 11.403 7.41793 11.3521C7.47525 11.301 7.5074 11.2314 7.5074 11.1587C7.5074 11.086 7.47525 11.0164 7.41793 10.9653C7.36088 10.9141 7.28347 10.886 7.20311 10.8872C4.21705 10.8872 1.80312 8.70237 1.80312 6.00025C1.80312 3.29813 4.21705 1.11331 7.20311 1.11331C10.1892 1.11331 12.6031 3.29813 12.6031 6.00025C12.6031 7.31241 12.0312 8.49972 11.1031 9.37699V6.95049C11.105 6.87219 11.0696 6.79729 11.0059 6.74444C10.9421 6.6916 10.8561 6.66639 10.7704 6.67487C10.6159 6.69039 10.4999 6.80989 10.5034 6.95073V9.9372C10.5034 10.0872 10.6378 10.2087 10.8034 10.2087H14.1034C14.1837 10.2097 14.2611 10.1815 14.3182 10.1304C14.3755 10.0793 14.4076 10.0097 14.4076 9.93696C14.4076 9.86423 14.3755 9.79466 14.3182 9.74352C14.2609 9.69261 14.1837 9.66425 14.1034 9.66546H11.6284C12.6055 8.69946 13.2034 7.41155 13.2034 6.00025C13.2031 3.00457 10.5133 0.570312 7.20311 0.570312Z"
      stroke={stroke}
      strokeWidth={strokeWidth}
    />
  </svg>
);

export default SVGIconRotateRight;
