import { FC } from 'react';
import { ErrorMessage, FieldHookConfig, useField } from 'formik';
import style from './yes-no-switch.module.scss';
import cx from 'classnames';

type AllowedValue = boolean | undefined;

type YesNoSwitchProps = FieldHookConfig<AllowedValue> & {
  label?: string;
  renderType?: 'radio' | 'button';
  onChange?: () => void;
};

const YesNoSwitch: FC<YesNoSwitchProps> = ({
  label,
  renderType = 'radio',
  onChange,
  ...props
}) => {
  const [field, { value }, { setValue }] = useField({
    ...props,
    type: 'radio'
  });

  return (
    <div
      className={cx(
        style.wrapper,
        props.disabled ? style.disabled : '',
        renderType === 'button' ? style.displayButton : style.displayRadio
      )}
    >
      {label && <p>{label}</p>}
      <div className={style.radioGroup}>
        <label htmlFor={`${props.name}-yes`}>
          <input
            {...field}
            id={`${props.name}-yes`}
            value="yes"
            disabled={props.disabled}
            checked={value === true}
            onChange={() => {
              setValue(true);
              onChange?.();
            }}
            type="radio"
          />
          Yes
        </label>

        <label htmlFor={`${props.name}-no`}>
          <input
            {...field}
            id={`${props.name}-no`}
            value="no"
            disabled={props.disabled}
            checked={value === false}
            onChange={() => {
              setValue(false);
              onChange?.();
            }}
            type="radio"
          />
          No
        </label>
      </div>
      <span className={style.error}>
        <ErrorMessage name={props.name} />
      </span>
    </div>
  );
};

export default YesNoSwitch;
