/* eslint @typescript-eslint/no-explicit-any: off */

// TODO: Add tests and remove istanbul ignores

import decodeJWT from 'jwt-decode';
import type { ExplicitPartial } from './types';
import * as Yup from 'yup';
import { FeeStatus } from 'api/services/super-admin/interfaces';
import dayjs from 'dayjs';
import CryptoES from 'crypto-es';

export type Callback<TReturn, TArgs extends any[]> = (
  ...args: TArgs
) => TReturn;

export type Intersection<Type extends any[]> = Type extends [
  infer First,
  ...infer Rest
]
  ? First & Intersection<Rest>
  : unknown;

/* istanbul ignore next */
export const extract = <Type extends object, Key extends keyof Type>(
  source: Type,
  ...keys: Key[]
): Pick<Type, Key> => {
  const result: Pick<Type, Key> = {} as Pick<Type, Key>;

  keys.forEach((key) => {
    result[key] = source[key];
  });

  return result;
};

/* istanbul ignore next */
export const isExpiredJWT = (token?: string): boolean => {
  try {
    const { exp = 0 } = decodeJWT<{ exp?: number }>(token ?? '');

    return exp < Date.now() / 1000;
  } catch (error) {
    return true;
  }
};

/* istanbul ignore next */
export const defineObjectProperty = <
  TObject extends object,
  TKey extends string,
  TValue
>(
  object: TObject,
  key: TKey,
  value: TValue,
  descriptor: Pick<
    PropertyDescriptor,
    'configurable' | 'enumerable' | 'writable'
  > = {}
): TObject & Record<TKey, TValue> => {
  const {
    configurable = false,
    enumerable = false,
    writable = false
  } = descriptor;

  return Object.defineProperty(object, key, {
    value: typeof value === 'function' ? value.bind(object) : value,
    configurable,
    enumerable,
    writable
  }) as never;
};

/* istanbul ignore next */
export const forEachInRange = (
  start: number,
  end: number,
  callback: Callback<void, [number]> | Callback<boolean, [number]>
): void => {
  for (let index = start; index <= end; index++) {
    const result = callback(index);

    if (typeof result !== 'undefined' && !result) {
      return;
    }
  }
};

/* istanbul ignore next */
export const formatDate = (datetime: string | Date, format = 'M/D/Y') => {
  if (!datetime) return;

  const date = new Date(datetime);
  const values = {
    D: `${date.getDate()}`.padStart(2, '0'),
    M: `${date.getMonth() + 1}`.padStart(2, '0'),
    Y: `${date.getFullYear()}`
  };

  return format.replace(
    /(D|M|Y)/g,
    (_, key: keyof typeof values) => values[key]
  );
};

/* istanbul ignore next */
export const checkDateStatus = (
  start_date: string,
  end_date?: string | null,
  date_to_check?: string
): FeeStatus => {
  const startDate = Date.parse(start_date);
  const currentDate = date_to_check
    ? Date.parse(date_to_check)
    : new Date().getTime();
  const endDate = end_date ? Date.parse(end_date) : new Date().getTime();

  if (currentDate < startDate) {
    return FeeStatus.UPCOMING;
  } else if (currentDate > endDate && end_date) {
    return FeeStatus.EXPIRED;
  } else return FeeStatus.ACTIVE;
};

/* istanbul ignore next */
export const currencySymbol = (
  currency: string = 'USD',
  locale: string = 'en-US'
) =>
  new Intl.NumberFormat(locale, { style: 'currency', currency })
    .formatToParts(1)
    .find((x) => x.type === 'currency')?.value;

/* istanbul ignore next */
export const formatCurrency = (
  amount: number | undefined,
  fraction: number = 0,
  currency: string = 'USD'
) => {
  if (amount === undefined) {
    return amount;
  }
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency,
    maximumFractionDigits: fraction
  }).format(amount);
};

/* istanbul ignore next */
export const mapRange = <TReturn>(
  start: number,
  end: number,
  callback: (index: number) => TReturn
): TReturn[] => {
  const result: TReturn[] = [];

  while (start <= end) {
    result.push(callback(start));
    start++;
  }

  return result;
};

/* istanbul ignore next */
export const forRange = (
  start: number,
  end: number,
  callback: (index: number) => void
): void => {
  while (start <= end) {
    callback(start++);
  }
};

/* istanbul ignore next */
export const arrayAddValue = <TValue>(
  array: TValue[],
  value: TValue
): TValue[] => {
  const result = [...array];

  result.push(value);

  return result;
};

/* istanbul ignore next */
export const arrayRemoveValue = <TValue>(
  array: TValue[],
  value: TValue
): TValue[] => {
  const result = [...array];
  const index = array.indexOf(value);

  index >= 0 && result.splice(index, 1);

  return result;
};

/* istanbul ignore next */
export const blobToDataURL = (target: Blob): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = ({ target }) => {
      if (!target || typeof target?.result !== 'string') {
        reject('Failed to read data URL');

        return;
      }

      resolve(target.result);
    };

    reader.onerror = (error) => {
      reject(error.target?.error);
    };

    reader.readAsDataURL(target);
  });

/* istanbul ignore next */
export const stringToFile = (data: string) => {
  const chunks = data.split(',');
  const type = chunks[0]?.match(/:(.*?);/)?.[1] ?? 'unknown';
  const decoded = window.atob(chunks[1]);
  const bytes = new Uint8Array(decoded.length);

  forRange(0, decoded.length, (index) => {
    bytes[index] = decoded.charCodeAt(index);
  });

  return new File([bytes], 'file.unk', { type });
};

interface DebounceHandler<TArgs extends any[]> {
  (...args: TArgs): void;

  callback: Callback<void, TArgs>;
  delay: number;
  timer: number;
}

/* istanbul ignore next */
export const debounce = <TArgs extends any[] = [], This extends object = {}>(
  callback: Callback<void, TArgs>,
  delay: number = 400
): DebounceHandler<TArgs> => {
  const wrapper: DebounceHandler<TArgs> = function (
    this: This,
    ...args: TArgs
  ) {
    clearTimeout(wrapper.timer);

    wrapper.timer = window.setTimeout(() => {
      callback.apply(this, args);
    }, delay);
  };

  wrapper.callback = callback;
  wrapper.delay = delay;
  wrapper.timer = 0;

  return wrapper;
};

/**
 *
 * @param obj
 * @returns boolean `predicate is T` of `ExplicitPartial<T>`
 * @note If `T` is `Partial<T>`, then predicate denotes `T = Required<TR>` in `ExplicitPartial<Required<TR>>`
 */
export const predicateIsPartialDefined = <T extends object>(
  obj: ExplicitPartial<T>
): obj is T => {
  for (const key in obj) {
    if (obj[key] === undefined) {
      return false;
    }
  }
  return true;
};

/**
 * @description Very simple email regex.
 * Matches:	joe@aol.com | a@b.c
 * Non-Matches:	asdf | david@hotmail
 */
const SIMPLE_EMAIL_REGX = /[\w-]+@([\w-]+\.)+[\w-]+/;
/**
 * @description A better yup email validator.
 * @returns {Yup.StringSchema} A yup string schema with email validation.
 */
export const YupStrongEmail = Yup.string().matches(
  SIMPLE_EMAIL_REGX,
  'Invalid email address'
);

export const slugify = (text: string) => {
  return text
    .replace(/[^-a-zA-Z0-9\s+]+/gi, '')
    .replace(/\s+/gi, '-')
    .toLowerCase();
};

type QuarterRange = {
  minDate: Date;
  maxDate: Date;
};

/* istanbul ignore next */
export function getQuarterDateRange(
  quarter: number,
  year: number
): QuarterRange {
  if (quarter < 1 || quarter > 4) {
    throw new Error('Quarter must be between 1 and 4.');
  }

  const startMonth = (quarter - 1) * 3;
  const endMonth = startMonth + 3;

  const minDate = new Date(year, startMonth, 1);

  const maxDate = new Date(year, endMonth, 0);

  return { minDate, maxDate };
}

export const formatDateToForm = (date: Date) => {
  return dayjs(date).format('YYYY-MM-DD');
};

let originalTitle: string | undefined; // ??? why is this globally instantiated? is this acting as state for the fns below??? needs to be refactored
let flashTitleIntervalId: number | undefined; // ??? same

/* istanbul ignore next */
export function flashTitleNotification(message: string): number {
  if (document.hasFocus()) return 0;
  window.onfocus = () => {
    stopFlashTitle();
  };

  if (!originalTitle) originalTitle = document.title;

  if (flashTitleIntervalId) clearInterval(flashTitleIntervalId);

  let isOriginalTitle = true;

  flashTitleIntervalId = window.setInterval(() => {
    document.title = isOriginalTitle ? message : originalTitle!;
    isOriginalTitle = !isOriginalTitle;
  }, 1000);

  return flashTitleIntervalId;
}

/* istanbul ignore next */
export function stopFlashTitle(): void {
  if (flashTitleIntervalId) clearInterval(flashTitleIntervalId);
  document.title = originalTitle || '';
}

export function sha256(string: string) {
  return CryptoES.SHA256(string).toString();
}

// functon to convert capital snake case to readable
// eg: "SOME_STRING" => "Some String"
export const snakecaseToTitleCase = (str: string) => {
  return str
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
};

export const getInitials = (name: string) => {
  const names = name.split(' ');
  let initials = names[0].substring(0, 1).toUpperCase();

  if (names.length > 1) {
    initials += names[names.length - 1].substring(0, 1).toUpperCase();
  }

  return initials;
};

export const isEmptyObject = (obj: Object) => {
  return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
};
