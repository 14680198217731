import { FC } from 'react';
import { SVGIconProps } from './types';

const SVGIconAllFiles: FC<SVGIconProps> = ({
  width = 20,
  height = 21,
  stroke = 'white'
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.4444 9.31402H4.55556M15.4444 9.31402C16.3036 9.31402 17 10.0105 17 10.8696V15.5362C17 16.3954 16.3036 17.0918 15.4444 17.0918H4.55556C3.69645 17.0918 3 16.3954 3 15.5362V10.8696C3 10.0105 3.69645 9.31402 4.55556 9.31402M15.4444 9.31402V7.75846C15.4444 6.89935 14.748 6.20291 13.8889 6.20291M4.55556 9.31402V7.75846C4.55556 6.89935 5.252 6.20291 6.11111 6.20291M6.11111 6.20291V4.64735C6.11111 3.78824 6.80756 3.0918 7.66667 3.0918H12.3333C13.1924 3.0918 13.8889 3.78824 13.8889 4.64735V6.20291M6.11111 6.20291H13.8889"
      stroke={stroke}
      strokeWidth="1.67"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SVGIconAllFiles;
