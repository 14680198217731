import { FC } from 'react';
import { SVGIconProps } from './types';

const SVGIconExclamationCircle: FC<SVGIconProps> = ({
  width = 12,
  height = 12,
  fill = 'none'
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 12 12"
    xmlns="http://www.w3.org/2000/svg"
    fill={fill}
  >
    <circle cx="6" cy="6" r="6" fill={fill}/>
    <path
        d="M6.00156 4.59961V5.99961M6.00156 7.39961H6.00506M9.15156 5.99961C9.15156 7.73931 7.74126 9.14961 6.00156 9.14961C4.26187 9.14961 2.85156 7.73931 2.85156 5.99961C2.85156 4.25991 4.26187 2.84961 6.00156 2.84961C7.74126 2.84961 9.15156 4.25991 9.15156 5.99961Z"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"/>
  </svg>
);

export default SVGIconExclamationCircle;
