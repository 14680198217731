import { FC, HTMLAttributes, useState } from 'react';
import { ErrorMessage, FieldHookConfig, useField } from 'formik';
import style from './password-field.module.scss';
import { SVGIcon } from 'components/icons';

type AllowedValue = string | number | string[] | undefined;

type TextFieldProps = FieldHookConfig<AllowedValue> &
  HTMLAttributes<HTMLInputElement> & {
    label?: string;
  };

const PasswordField: FC<TextFieldProps> = ({ label, ...props }) => {
  const [field, { error, touched }] = useField({ ...props });

  const [hidePassword, setHidePassword] = useState<boolean>(true);

  const generic = props as HTMLAttributes<HTMLInputElement>;

  return (
    <div className={style.wrapper}>
      {label && <label htmlFor={props.id ?? props.name}>{label}</label>}
      <div className={style.password}>
        <input
          className={touched && error ? style.error : ''}
          type={hidePassword ? 'password' : 'text'}
          {...generic}
          {...field}
        />
        {hidePassword ? (
          <button type="button" onClick={() => setHidePassword(false)}>
            <SVGIcon variant="eye" />
          </button>
        ) : (
          <button type="button" onClick={() => setHidePassword(true)}>
            <SVGIcon variant="eye-slash" />
          </button>
        )}
      </div>
      <span className={style.error}>
        <ErrorMessage name={props.name} />
      </span>
    </div>
  );
};

export default PasswordField;
