import { ChangeEvent, FC } from 'react';
import { ErrorMessage, FieldHookConfig, useField } from 'formik';
import { Checkbox } from '../';
import cx from 'classnames';
import style from './checkbox-group.module.scss';

type AllowedValue = string | number | undefined;

export interface CheckboxGroupOption {
  label: string;
  value: AllowedValue;
}

type CheckboxGroupProps = FieldHookConfig<AllowedValue[]> & {
  label?: string;
  options?: CheckboxGroupOption[];
  onOptionSelect?: (option: CheckboxGroupOption) => void;
  onOptionDeselect?: (option: CheckboxGroupOption) => void;
  variant?: 'grid' | 'list';
};

const CheckboxGroup: FC<CheckboxGroupProps> = ({
  label,
  options,
  onOptionSelect,
  onOptionDeselect,
  variant = 'grid',
  ...props
}) => {
  const [field] = useField({ ...props });
  return (
    <div className={cx(style.wrapper, style[variant])}>
      {label && <p className="group-label">{label}</p>}
      <div className={style.checkboxContainer}>
        {options?.map(({ label, value }) => (
          <Checkbox
            key={`${props.name}-${value}`}
            hideError
            {...field}
            id={`${props.name}-${value}`}
            value={value}
            label={label}
            checked={field.value?.includes(value)}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              field.value?.includes(value)
                ? onOptionDeselect?.({ label, value })
                : onOptionSelect?.({ label, value });

              field.onChange(event);
            }}
          />
        ))}
      </div>
      <span className={style.error}>
        <ErrorMessage name={props.name} />
      </span>
    </div>
  );
};

export default CheckboxGroup;
