import { FC } from 'react';
import { SVGIconProps } from './types';

const SVGIconPayrollTaxReturns: FC<SVGIconProps> = ({
  width = 20,
  height = 21,
  stroke = 'white'
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.89236 6.20291V12.4251C6.89236 13.2842 7.58881 13.9807 8.44792 13.9807H13.1146M6.89236 6.20291V4.64735C6.89236 3.78824 7.58881 3.0918 8.44792 3.0918H12.0146C12.2209 3.0918 12.4187 3.17374 12.5646 3.3196L15.9979 6.75288C16.1438 6.89874 16.2257 7.09657 16.2257 7.30285V12.4251C16.2257 13.2842 15.5292 13.9807 14.6701 13.9807H13.1146M6.89236 6.20291H5.78125C4.67668 6.20291 3.78125 7.09834 3.78125 8.20291V15.5362C3.78125 16.3954 4.4777 17.0918 5.33681 17.0918H11.1146C12.2192 17.0918 13.1146 16.1964 13.1146 15.0918V13.9807"
      stroke={stroke}
      strokeWidth="1.67"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SVGIconPayrollTaxReturns;
