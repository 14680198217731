import { FC } from 'react';
import { SVGIconProps } from './types';

const SVGIconSun: FC<SVGIconProps> = ({
  width = 16,
  height = 16,
  stroke = 'black',
  strokeWidth = 1.67
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      stroke={stroke}
      strokeWidth={strokeWidth}
      d="M8 1.0918V1.86957M8 14.314V15.0918M15 8.0918H14.2222M1.77778 8.0918H1M12.9497 13.0415L12.3998 12.4916M3.60022 3.69202L3.05025 3.14205M12.9498 3.14209L12.3998 3.69206M3.60027 12.4916L3.05029 13.0416M11.1111 8.0918C11.1111 9.81002 9.71822 11.2029 8 11.2029C6.28178 11.2029 4.88889 9.81002 4.88889 8.0918C4.88889 6.37358 6.28178 4.98069 8 4.98069C9.71822 4.98069 11.1111 6.37358 11.1111 8.0918Z"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SVGIconSun;
