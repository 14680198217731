import { Modal, Switch } from 'antd';
import {
  AffiliateContract,
  AffiliatesFeeTypes
} from 'api/services/super-admin/interfaces';
import {
  Row,
  Col,
  TextField,
  RadioGroup,
  DateField,
  Button
} from 'components/atomic';
import { Form, Formik } from 'formik';
import { Dispatch, FC, SetStateAction, useMemo } from 'react';
import * as Yup from 'yup';

interface EditFeeModalValues {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  onAction: (contract: AffiliateContract) => void;
  data?: AffiliateContract;
}

const AddFeeSchema = Yup.object().shape({
  fee: Yup.object().shape({
    type: Yup.string().required('You must select type'),
    percentage: Yup.number().when('type', {
      is: (value: AffiliatesFeeTypes) =>
        value === AffiliatesFeeTypes.PERCENTAGE ||
        value === AffiliatesFeeTypes.HYBRID
          ? true
          : false,
      then: (schema) =>
        schema
          .min(0, 'Cannot go bellow 0')
          .max(100, 'Cannot go above 100')
          .required('This field is required')
    }),
    flat_fee: Yup.number().when('type', {
      is: (value: AffiliatesFeeTypes) =>
        value === AffiliatesFeeTypes.FLAT_FEE ||
        value === AffiliatesFeeTypes.HYBRID
          ? true
          : false,
      then: (schema) =>
        schema.min(0, 'Cant go bellow 0').required('This field is required')
    })
  }),
  perpetuity: Yup.boolean(),
  start_date: Yup.date().required('Must enter date'),
  end_date: Yup.date()
    .nullable()
    .when('perpetuity', {
      is: false,
      then: (schema) => schema.required('Must enter date')
    }).test(
      'minDate',
      'End date cannot be before the start date',
      function(item) {
        if (!item || !this.parent.start_date) return true
        return this.parent.start_date < item
      }
    )
});

const EditFeeModal: FC<EditFeeModalValues> = ({
  open,
  setOpen,
  onAction,
  data
}) => {
  const initialValues = useMemo(
    () => ({
      fee: {
        type: data?.fee.type ?? AffiliatesFeeTypes.PERCENTAGE,
        percentage: data?.fee.percentage ? data?.fee.percentage * 100 : 0,
        flat_fee: data?.fee.flat_fee ?? 0,
        offset: data?.fee.offset ?? false
      },
      start_date: data?.start_date ?? '',
      end_date: data?.end_date ?? null,
      perpetuity: data?.perpetuity ?? false
    }),
    [data]
  );
  return (
    <Modal
      open={open}
      footer={null}
      afterClose={() => {}}
      centered
      width={800}
      onCancel={() => setOpen(false)}
    >
      <h2 className="fs--3 fw--600">Edit fee structure</h2>
      <Formik
        initialValues={initialValues}
        validationSchema={AddFeeSchema}
        enableReinitialize
        validateOnMount
        onSubmit={(values, { setSubmitting, resetForm }) => {
          setSubmitting(true);
          const endDate = values.perpetuity ? null : values.end_date;
          const percentage =
            values.fee.type === AffiliatesFeeTypes.FLAT_FEE
              ? 0
              : values.fee.percentage / 100;
          const flat =
            values.fee.type === AffiliatesFeeTypes.PERCENTAGE
              ? 0
              : values.fee.flat_fee;
          onAction({
            ...values,
            end_date: endDate,
            fee: { ...values.fee, percentage: percentage, flat_fee: flat }
          });
          setOpen(false);
          resetForm();
        }}
      >
        {({ values, isSubmitting, isValid, setFieldValue }) => (
          <Form>
            <Row className="my--6">
              <Col md={12}>
                <RadioGroup
                  name={`fee.type`}
                  label="Fee type*"
                  options={[
                    {
                      value: AffiliatesFeeTypes.PERCENTAGE,
                      label: 'Percentage'
                    },
                    {
                      value: AffiliatesFeeTypes.FLAT_FEE,
                      label: 'Flat fee'
                    },
                    {
                      value: AffiliatesFeeTypes.HYBRID,
                      label: 'Hybrid'
                    }
                  ]}
                  variant="inline"
                />
              </Col>
            </Row>
            {values.fee.type !== AffiliatesFeeTypes.HYBRID ? (
              <Row className="my--6">
                <Col md={12}>
                  {values.fee.type === AffiliatesFeeTypes.PERCENTAGE && (
                    <TextField
                      name={`fee.percentage`}
                      type="number"
                      label="Fee percentage*"
                    />
                  )}
                  {values.fee.type === AffiliatesFeeTypes.FLAT_FEE && (
                    <TextField
                      name={`fee.flat_fee`}
                      label="Fee amount*"
                      type="number"
                    />
                  )}
                </Col>
              </Row>
            ) : (
              <Row className="my--6">
                <Col md={6}>
                  <TextField
                    name={`fee.flat_fee`}
                    label="Upfront flat fee*"
                    type="number"
                  />
                </Col>
                <Col md={6}>
                  <TextField
                    name={`fee.percentage`}
                    label="Fee percentage*"
                    type="number"
                  />
                </Col>
              </Row>
            )}
            {values.fee.type === AffiliatesFeeTypes.HYBRID && (
              <Row className="mb--4">
                <Col md={6} className="d--f f--ac">
                  <Switch
                    id="fee.offset"
                    checked={values.fee.offset}
                    onChange={(value) => setFieldValue(`fee.offset`, value)}
                    className="cs-switch mr--2"
                  />
                  <p className="ml--4">Offset fees</p>
                </Col>
              </Row>
            )}
            <Row className="mb--4">
              <Col md={12}>
                <DateField name="start_date" label="Start date*" />
              </Col>
            </Row>
            <Row className="mb--4">
              <Col md={6} className="d--f f--ac">
                <Switch
                  id="perpetuity"
                  checked={values.perpetuity}
                  onChange={(value) => setFieldValue('perpetuity', value)}
                  className="cs-switch mr--2"
                />
                <p className="ml--4">In perpetuity </p>
              </Col>
            </Row>
            {!values.perpetuity && (
              <Row className="mb--4">
                <Col md={12}>
                  <DateField name="end_date" label="End date*" />
                </Col>
              </Row>
            )}
            <Button
              name="add-fee"
              className="w--100 mt--8"
              type="submit"
              disabled={!isValid || isSubmitting}
            >
              Update fee structure
            </Button>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default EditFeeModal;
