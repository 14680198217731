import { FC, PropsWithChildren } from 'react';
import { Outlet, Navigate, OutletProps } from 'react-router-dom';
import { useAuthContext } from 'context/AuthContext';
import Loader from '../Loader/Loader';

interface ProtectedRouteProps extends OutletProps {
  redirectPath?: string;
  redirectPathForbidden?: string;
  allowedRoles?: string[];
}

const ProtectedRoute: FC<PropsWithChildren<ProtectedRouteProps>> = ({
  redirectPath = '/',
  redirectPathForbidden = '/',
  allowedRoles = [],
  ...props
}) => {
  const { user, isAuthenticating } = useAuthContext();
  const isAuthenticated = !!user;
  const isAllowed = isAuthenticated && user.hasAnyRole(allowedRoles);

  if (isAuthenticating) {
    return <Loader />;
  }

  if (!isAllowed) {
    return <Navigate to={redirectPathForbidden}></Navigate>;
  }

  return isAuthenticated ? (
    <Outlet {...props}></Outlet>
  ) : (
    <Navigate to={redirectPath}></Navigate>
  );
};

export default ProtectedRoute;
