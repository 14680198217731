import { FC } from 'react';
import { SVGIconProps } from './types';

const SVGIconCog: FC<SVGIconProps> = ({
  width = 20,
  height = 21,
  stroke = '#2E2E2E'
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.69693 4.22477C9.02858 2.85867 10.9714 2.85867 11.3031 4.22477C11.5173 5.10725 12.5284 5.52604 13.3039 5.05352C14.5043 4.32205 15.8781 5.69585 15.1467 6.89634C14.6742 7.67184 15.0929 8.68289 15.9754 8.89713C17.3415 9.22877 17.3415 11.1716 15.9754 11.5033C15.0929 11.7175 14.6742 12.7286 15.1467 13.5041C15.8781 14.7045 14.5043 16.0783 13.3039 15.3469C12.5284 14.8743 11.5173 15.2931 11.3031 16.1756C10.9714 17.5417 9.02858 17.5417 8.69693 16.1756C8.48269 15.2931 7.47164 14.8743 6.69614 15.3469C5.49566 16.0783 4.12186 14.7045 4.85333 13.5041C5.32585 12.7286 4.90706 11.7175 4.02457 11.5033C2.65848 11.1716 2.65848 9.22877 4.02457 8.89713C4.90706 8.68289 5.32585 7.67184 4.85333 6.89634C4.12186 5.69585 5.49566 4.32205 6.69614 5.05352C7.47164 5.52604 8.48269 5.10725 8.69693 4.22477Z"
      stroke={stroke}
      strokeWidth="1.67"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.3333 10.2002C12.3333 11.4889 11.2887 12.5335 10 12.5335C8.71134 12.5335 7.66667 11.4889 7.66667 10.2002C7.66667 8.91153 8.71134 7.86686 10 7.86686C11.2887 7.86686 12.3333 8.91153 12.3333 10.2002Z"
      stroke={stroke}
      strokeWidth="1.67"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SVGIconCog;
