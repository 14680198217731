import { FC } from 'react';
import { Row as VendorRow, RowProps as VendorRowProps } from 'antd';

interface RowProps extends VendorRowProps {
  gutter?: number;
}

const Row: FC<RowProps> = ({ className, gutter = 16, ...props }) => {
  return <VendorRow gutter={gutter} className={className} {...props} />;
};

export default Row;
