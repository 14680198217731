import { NavLink } from 'react-router-dom';
import style from './sidebar.module.scss';
import cx from 'classnames';
import SVGIcon from 'components/icons/SVGIcon';
import { Variants } from 'components/icons/SVGIcon';
import { FC } from 'react';
import React from 'react';

const items = [
  {
    url: '/super-admin/applications',
    label: 'Applications',
    icon: 'all-files',
    fill: false
  },

  {
    url: '/super-admin/members',
    label: 'Members',
    icon: 'add-user',
    fill: false
  },

  {
    url: '/super-admin/groups',
    label: 'Groups',
    icon: 'benefits',
    fill: false
  },

  {
    url: '/super-admin/managers',
    label: 'AM leads',
    icon: 'manager',
    fill: false
  },
  {
    url: '/super-admin/erca-managers',
    label: 'ERCA Managers',
    icon: 'case-notes',
    fill: false
  },
  {
    url: '/super-admin/affiliates',
    label: 'Affiliates',
    icon: 'company-profile',
    fill: false
  }
];

interface SidebarProps {
  collapsed?: boolean;
  setCollapsed?: React.Dispatch<React.SetStateAction<boolean>>;
}

const Sidebar: FC<SidebarProps> = ({ collapsed = false, setCollapsed }) => {
  return (
    <div className={cx(style.wrapper, collapsed ? style.collapsed : '')}>
      <button
        className={style.sidebarControll}
        onClick={() => {
          setCollapsed?.(!collapsed);
        }}
      >
        <SVGIcon variant="chevron-left" className={style.iconBack} />
      </button>
      <nav className={style.list}>
        {items.map(({ url, icon, label, fill }, key) => {
          return (
            <React.Fragment key={`sidebar-item-${key}`}>
              <NavLink
                to={url}
                className={({ isActive }) =>
                  cx(style.item, isActive ? style.active : '')
                }
              >
                <div className={style.link}>
                  <SVGIcon
                    variant={icon as keyof typeof Variants}
                    className={fill ? style.fill : ''}
                  />
                  <span className={style.label}>{label}</span>
                </div>
              </NavLink>
            </React.Fragment>
          );
        })}
      </nav>
    </div>
  );
};

export default Sidebar;
