import { Collection } from './Collection';
import { getObjectValue } from './helpers';
import { Callback, Collectable, Key } from './types';

export class RecordCollection<Type extends Collectable>
  implements Collection<Type>
{
  protected items: Record<Key, Type>;

  public constructor(items: Record<Key, Type>) {
    this.items = items;
  }

  public groupBy(
    groupBy: Key | Callback<Key, [Type, Key]>
  ): Collection<Type[]> {
    const collection: Record<Key, Type[]> = {};

    for (const key in this.items) {
      const resolvedKey = String(
        typeof groupBy === 'function'
          ? groupBy(this.items[key], key)
          : getObjectValue(this.items[key], groupBy)
      );

      if (!collection[resolvedKey]) {
        collection[resolvedKey] = [];
      }

      collection[resolvedKey].push(this.items[key]);
    }

    return new RecordCollection(collection);
  }

  public keyBy(keyBy: Key | Callback<Key, [Type, Key]>): Collection<Type> {
    const collection: Record<Key, Type> = {};

    for (const key in this.items) {
      const resolvedKey = String(
        typeof keyBy === 'function'
          ? keyBy(this.items[key], key)
          : getObjectValue(this.items[key], keyBy)
      );

      collection[resolvedKey] = this.items[key];
    }

    return new RecordCollection(collection);
  }

  public map<TValue>(
    callback: Callback<TValue, [Type, Key]>
  ): Collection<TValue> {
    const collection: Record<Key, TValue> = {};

    for (const key in this.items) {
      collection[key] = callback(this.items[key], key);
    }

    return new RecordCollection(collection);
  }

  forEach<TValue>(callback: Callback<TValue, [Type, Key]>): void {
    for (const key in this.items) {
      callback(this.items[key], key);
    }
  }

  public pluck<TValue>(pluckKey: Key): Collection<TValue> {
    const collection: Record<Key, TValue> = {};

    for (const key in this.items) {
      collection[key] = getObjectValue(this.items[key], pluckKey) as TValue;
    }

    return new RecordCollection(collection);
  }

  public toArray(): Type[] {
    return Object.values(this.items);
  }

  public toRecord(): Record<Key, Type> {
    return { ...this.items };
  }
}
