import { FC } from 'react';
import { SVGIconProps } from './types';

const SVGIconArrowLeft: FC<SVGIconProps> = ({
  width = 17,
  height = 14,
  stroke = '#2E2E2E',
  strokeWidth = 1.67,
  onClick
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 17 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    onClick={onClick}
  >
    <path
      d="M6.94444 12.4444L1.5 6.99999M1.5 6.99999L6.94444 1.55554M1.5 6.99999L15.5 6.99999"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SVGIconArrowLeft;
