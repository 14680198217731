import { FC } from 'react';
import { SVGIconProps } from './types';

const SVGIconManager: FC<SVGIconProps> = ({
  width = 16,
  height = 16,
  stroke = 'white',
  strokeWidth = 1.67
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 2.14504C8.57007 1.49916 9.40413 1.0918 10.3333 1.0918C12.0516 1.0918 13.4444 2.48469 13.4444 4.20291C13.4444 5.92113 12.0516 7.31402 10.3333 7.31402C9.40413 7.31402 8.57007 6.90665 8 6.26077M10.3333 15.0918H1V14.314C1 11.7367 3.08934 9.64735 5.66667 9.64735C8.244 9.64735 10.3333 11.7367 10.3333 14.314V15.0918ZM10.3333 15.0918H15V14.314C15 11.7367 12.9107 9.64735 10.3333 9.64735C9.48333 9.64735 8.68641 9.87461 8 10.2717M8.77778 4.20291C8.77778 5.92113 7.38489 7.31402 5.66667 7.31402C3.94845 7.31402 2.55556 5.92113 2.55556 4.20291C2.55556 2.48469 3.94845 1.0918 5.66667 1.0918C7.38489 1.0918 8.77778 2.48469 8.77778 4.20291Z"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SVGIconManager;
