import { FC } from 'react';
import { SVGIconProps } from './types';

const SVGIconEdit: FC<SVGIconProps> = ({
  width = 12,
  height = 12,
  fill = 'black',
  strokeWidth = 1.67
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 12 12"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.1884 0.810075C10.6417 0.263341 9.75522 0.263341 9.20849 0.810075L3.89844 6.12013V8.10003H5.87833L11.1884 2.78997C11.7351 2.24324 11.7351 1.35681 11.1884 0.810075Z"
      fill="#5514E6"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.398438 3.20002C0.398438 2.42683 1.02524 1.80002 1.79844 1.80002H4.59844C4.98504 1.80002 5.29844 2.11343 5.29844 2.50002C5.29844 2.88662 4.98504 3.20002 4.59844 3.20002H1.79844V10.2H8.79844V7.40002C8.79844 7.01343 9.11184 6.70002 9.49844 6.70002C9.88504 6.70002 10.1984 7.01343 10.1984 7.40002V10.2C10.1984 10.9732 9.57164 11.6 8.79844 11.6H1.79844C1.02524 11.6 0.398438 10.9732 0.398438 10.2V3.20002Z"
      fill={fill}
    />
  </svg>
);

export default SVGIconEdit;
