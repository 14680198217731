import { FC } from 'react';
import { SVGIconProps } from './types';

const SVGIconTimes: FC<SVGIconProps> = ({
  width = 20,
  height = 20,
  stroke = 'black',
  strokeWidth = 1.67
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      stroke={stroke}
      strokeWidth={strokeWidth}
      d="M5 15L15 5M5 5L15 15"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SVGIconTimes;
