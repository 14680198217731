import client from 'api/client';
import {
  UserProfileResponse,
  SaveUserProfileRequest,
  SaveCompanyInfoGeneralRequest,
  SaveCompanyInfoOwnershipRequest,
  SaveCompanyInfoAffiliatedRequest,
  SaveCompanyInfoPeoRequest,
  SaveCompanyInfoBankingRequest,
  SaveCompanyInfoEmployeeGeneralRequest,
  SaveCompanyInfoEmployeeOwnersRequest,
  SaveCompanyInfoEmployeeFamilyRequest,
  SaveCovidDisruptionsRequest,
  SaveCovidQuartersImpactRequest,
  SaveCovidDisruptionsAdditionalRequest,
  SaveCovidDisruptionsDescriptionRequest,
  SavePPPGeneralRequest,
  SaveBenefitsGeneralRequest,
  SavePayrollTaxReturnsGeneralRequest,
  ApplicationDataResponse,
  ErrataFormRequest,
  BulkUploaderRequest,
  SaveFinchProviderRequest,
  ForgotPasswordRequest,
  SetPasswordRequest,
  PasswordChangeRequest,
  HSDealRequest
} from './interfaces';
import { ExplicitPartial } from 'helpers';

// GET

export {
  getCompanyInfoGeneral,
  getCompanyInfoOwnership,
  getCompanyInfoAffiliated,
  getCompanyInfoPeo,
  getCompanyInfoBanking,
  getCompanyInfoEmployeeGeneral,
  getCompanyInfoEmployeeOwners,
  getCompanyInfoEmployeeFamily,
  getCovidDisruptions,
  getCovidQuartersImpact,
  getCovidDisruptionsAdditional,
  getCovidDisruptionsDescription,
  getPPPGeneral,
  getPPPUpload,
  getPayrollWages,
  getBenefitsGeneral,
  getPayrollTaxReturnsGeneral,
  getPayrollTaxReturns941,
  getPayrollTaxReturns943,
  getProfitAndLoss
} from 'api/services/app/common/requests/form-steps';

export const getApplicationData = async (
  session: string
): Promise<Partial<ApplicationDataResponse>> =>
  (await client.get(`application/data/${session}/`))?.data;

export const getUserProfile = async (
  session: string
): Promise<Partial<UserProfileResponse>> =>
  (await client.get(`application/user-profile/${session}/`))?.data;

// POST

export const saveUserProfile = async (
  session: string,
  {
    payload,
    next_step
  }: { payload: SaveUserProfileRequest; next_step?: string }
) =>
  (
    await client.post(`application/user-profile/${session}/`, payload, {
      params: { next_step }
    })
  )?.data;

export const saveCompanyInfoGeneral = async (
  session: string,
  {
    payload,
    next_step
  }: { payload: SaveCompanyInfoGeneralRequest; next_step?: string }
) =>
  (
    await client.post(`application/company-info-general/${session}/`, payload, {
      params: { next_step }
    })
  )?.data;

export const saveCompanyInfoOwnership = async (
  session: string,
  {
    payload,
    next_step
  }: { payload: SaveCompanyInfoOwnershipRequest; next_step?: string }
) =>
  (
    await client.post(
      `application/company-info-ownership/${session}/`,
      payload,
      {
        params: { next_step }
      }
    )
  )?.data;

export const saveCompanyInfoAffiliated = async (
  session: string,
  {
    payload,
    next_step
  }: { payload: SaveCompanyInfoAffiliatedRequest; next_step?: string }
) =>
  (
    await client.post(
      `application/company-info-affiliated/${session}/`,
      payload,
      { params: { next_step } }
    )
  )?.data;

export const saveCompanyInfoPeo = async (
  session: string,
  {
    payload,
    next_step
  }: { payload: ExplicitPartial<SaveCompanyInfoPeoRequest>; next_step?: string }
) =>
  (
    await client.post(`application/company-info-peo/${session}/`, payload, {
      params: { next_step }
    })
  )?.data;

export const saveCompanyInfoBanking = async (
  session: string,
  {
    payload,
    next_step
  }: { payload: SaveCompanyInfoBankingRequest; next_step?: string }
) =>
  (
    await client.post(`application/company-info-banking/${session}/`, payload, {
      params: { next_step }
    })
  )?.data;

export const saveCompanyInfoEmployeeGeneral = async (
  session: string,
  {
    payload,
    next_step
  }: { payload: SaveCompanyInfoEmployeeGeneralRequest; next_step?: string }
) =>
  (
    await client.post(
      `application/company-info-employee-general/${session}/`,
      payload,
      { params: { next_step } }
    )
  )?.data;

export const saveCompanyInfoEmployeeOwners = async (
  session: string,
  {
    payload,
    next_step
  }: { payload: SaveCompanyInfoEmployeeOwnersRequest; next_step?: string }
) =>
  (
    await client.post(
      `application/company-info-employee-owners/${session}/`,
      payload,
      { params: { next_step } }
    )
  )?.data;

export const saveCompanyInfoEmployeeFamily = async (
  session: string,
  {
    payload,
    next_step
  }: { payload: SaveCompanyInfoEmployeeFamilyRequest; next_step?: string }
) =>
  (
    await client.post(
      `application/company-info-employee-family/${session}/`,
      payload,
      { params: { next_step } }
    )
  )?.data;

export const saveCovidDisruptions = async (
  session: string,
  {
    payload,
    next_step
  }: { payload: SaveCovidDisruptionsRequest; next_step?: string }
) =>
  (
    await client.post(`application/covid-disruptions/${session}/`, payload, {
      params: { next_step }
    })
  )?.data;

export const saveCovidQuartersImpact = async (
  session: string,
  {
    payload,
    next_step
  }: { payload: SaveCovidQuartersImpactRequest; next_step?: string }
) =>
  (
    await client.post(
      `application/covid-quarters-impact/${session}/`,
      payload,
      { params: { next_step } }
    )
  )?.data;

export const saveCovidDisruptionsAdditional = async (
  session: string,
  {
    payload,
    next_step
  }: { payload: SaveCovidDisruptionsAdditionalRequest; next_step?: string }
) =>
  (
    await client.post(
      `application/covid-disruptions-additional/${session}/`,
      payload,
      { params: { next_step } }
    )
  )?.data;

export const saveCovidDisruptionsDescription = async (
  session: string,
  {
    payload,
    next_step
  }: { payload: SaveCovidDisruptionsDescriptionRequest; next_step?: string }
) =>
  (
    await client.post(
      `application/covid-disruptions-description/${session}/`,
      payload,
      { params: { next_step } }
    )
  )?.data;

export const savePPPGeneral = async (
  session: string,
  { payload, next_step }: { payload: SavePPPGeneralRequest; next_step?: string }
) =>
  (
    await client.post(`application/ppp-general/${session}/`, payload, {
      params: { next_step }
    })
  )?.data;

export const saveFinchProviderSelect = async (
  session: string,
  payload: SaveFinchProviderRequest
) =>
  (await client.post(`application/finch-provider-select/${session}/`, payload))
    ?.data;

export const saveBenefitsGeneral = async (
  session: string,
  {
    payload,
    next_step
  }: { payload: SaveBenefitsGeneralRequest; next_step?: string }
) =>
  (
    await client.post(`application/benefits-general/${session}/`, payload, {
      params: { next_step }
    })
  )?.data;

export const savePayrollTaxReturnsGeneral = async (
  session: string,
  {
    payload,
    next_step
  }: { payload: SavePayrollTaxReturnsGeneralRequest; next_step?: string }
) =>
  (
    await client.post(
      `application/payroll-tax-returns-general/${session}/`,
      payload,
      { params: { next_step } }
    )
  )?.data;

export const saveApplicationComplete = async (session: string) =>
  await client.post(`/application/${session}/complete/`);

export const saveErrataPage = async (
  session: string,
  payload: ErrataFormRequest
) => (await client.post(`errata-page/${session}/`, payload))?.data;

export const saveBulkUploaderPage = async (
  session: string,
  payload: BulkUploaderRequest
) => (await client.post(`bulk-upload/${session}/`, payload))?.data;

export const sendPasswordResetEmail = async (payload: ForgotPasswordRequest) =>
  (await client.post('auth/forgot-password/', payload))?.data;

export const saveResetPassword = async (
  token: string,
  payload: SetPasswordRequest
) => (await client.post(`auth/password-reset/${token}/`, payload))?.data;

export const saveChangePassword = async (payload: PasswordChangeRequest) =>
  (await client.post('auth/password/change/', payload))?.data;

export const getHSDeal = async (payload: HSDealRequest) =>
  (
    await client.post(
      `auth/honeyshake-internal?hs_deal_id=${payload.hs_deal_id}`
    )
  )?.data;
