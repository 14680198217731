import { FC } from 'react';
import { SVGIconProps } from './types';

const SVGIconChevronLeft: FC<SVGIconProps> = ({
  width = 9,
  height = 15,
  fill = 'none',
  stroke = '#2E2E2E',
  strokeWidth = 1.67
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 9 15"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.65565 1.85828L1.99879 7.51514L7.65565 13.172"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
    />
  </svg>
);

export default SVGIconChevronLeft;
