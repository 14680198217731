import { FC } from 'react';
import { SVGIconProps } from './types';
const SVGIconPlusFiles: FC<SVGIconProps> = ({
  width = 20,
  height = 21,
  fill = 'black',
  stroke = 'black'
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 21"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill={fill}
      stroke={stroke}
      d="M7.66677 10.8696H12.3334M10.0001 8.53624L10.0001 13.2029M13.889 17.0918H6.11122C5.25211 17.0918 4.55566 16.3954 4.55566 15.5362V4.64735C4.55566 3.78824 5.25211 3.0918 6.11122 3.0918H10.4557C10.662 3.0918 10.8598 3.17374 11.0057 3.3196L15.2167 7.53066C15.3626 7.67652 15.4446 7.87435 15.4446 8.08063V15.5362C15.4446 16.3954 14.7481 17.0918 13.889 17.0918Z" strokeWidth="1.67" strokeLinecap="round" strokeLinejoin="round"
    />
  </svg>
);

export default SVGIconPlusFiles;
