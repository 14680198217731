import { ClientStorage } from './';

export class CookieStorage implements ClientStorage {
  public set(key: string | symbol, value: any, ttl: number = 0): void {
    const chunks: string[] = [];
    try {
      chunks.push(`${String(key)}=${JSON.stringify(value)}`);
    } catch (error) {
      throw new TypeError('Failed to stringify storage value.');
    }
    if (ttl > 0) {
      const date = new Date();
      date.setTime(date.getTime() + ttl * 1000);
      chunks.push('expires=' + date.toUTCString());
    } else {
      chunks.push('expires=Fri, 31 Dec 9999 23:59:59 GMT');
    }
    chunks.push('path=/');
    document.cookie = chunks.join('; ');
  }

  public has(key: string | symbol): any {
    return document.cookie.split('; ').some((cookie: string) => {
      return cookie.startsWith(`${String(key)}=`);
    });
  }

  public get(key: string | symbol | RegExp): any {
    const cookies = document.cookie.split('; ');
    if (typeof key === 'string' || typeof key === 'symbol') {
      const cookie = cookies.find((cookie: string) => {
        return cookie.startsWith(`${String(key)}=`);
      });
      if (!cookie) {
        return null;
      }
      try {
        return JSON.parse(cookie.split('=')[1] ?? null);
      } catch (error) {
        return null;
      }
    }
    return cookies
      .filter((cookie: string) => {
        return key.test(cookie.split('=')[0]);
      })
      .map((cookie: string) => {
        const [key, value] = cookie.split('=');
        try {
          return { key, value: JSON.parse(value ?? null) };
        } catch (error) {
          return { key, value: null };
        }
      });
  }

  public delete(key: string | symbol): void {
    document.cookie = [
      `${String(key)}=`,
      'expires=Thu, 01 Jan 1970 00:00:01 GMT',
      'path=/'
    ].join('; ');
  }

  public refresh(key: string | symbol, ttl: number = 0): boolean {
    const value = this.get(key);
    if (!value) {
      return false;
    }
    this.set(key, value, ttl);
    return true;
  }
}
