import { FC } from 'react';
import { SVGIconProps } from './types';

const SVGIconSearch: FC<SVGIconProps> = ({
  width = 20,
  height = 19,
  stroke = '#A1A1A1'
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 19"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.4016 17.7104L12.8016 12.1104M14.6682 7.44373C14.6682 11.052 11.7432 13.9771 8.1349 13.9771C4.52664 13.9771 1.60156 11.052 1.60156 7.44373C1.60156 3.83547 4.52664 0.9104 8.1349 0.9104C11.7432 0.9104 14.6682 3.83547 14.6682 7.44373Z"
      stroke={stroke}
      strokeWidth="1.67"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SVGIconSearch;
