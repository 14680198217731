import { FC } from 'react';
import { SVGIconProps } from './types';

const SVGIconShield: FC<SVGIconProps> = ({ width = 48, height = 48 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 48 48"
    >
      <rect width="48" height="48" fill="#EEE8FD" rx="24"></rect>
      <path
        fill="#171717"
        stroke="#171717"
        d="M16.29 20.787c0-.531.034-1.054.102-1.566a12.758 12.758 0 007.606-2.96 12.759 12.759 0 007.606 2.96c.068.512.103 1.035.103 1.566 0 5.08-3.208 9.413-7.71 11.08-4.5-1.667-7.708-6-7.708-11.08zm7.708 8.709a1.526 1.526 0 100-3.052 1.526 1.526 0 000 3.052zm1.526-8.709a1.526 1.526 0 10-3.052 0v3.078a1.526 1.526 0 003.052 0v-3.078z"
      ></path>
    </svg>
  );
};

export default SVGIconShield;
