import { FC } from 'react';
import { SVGIconProps } from './types';

const SVGIconReturn: FC<SVGIconProps> = ({
  width = 16,
  height = 16,
  fill = 'white',
  stroke = 'white'
}) => (
  <svg width={width} height={height} viewBox="0 0 20 20" fill={fill} xmlns="http://www.w3.org/2000/svg">
    <path d="M1 6.44401H8.77778C12.2142 6.44401 15 9.22979 15 12.6662V14.2218M1 6.44401L5.66667 11.1107M1 6.44401L5.66667 1.77734" stroke={stroke} strokeWidth="1.67" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

export default SVGIconReturn;
