import { Modal } from 'antd';
import { Button, Col, PasswordField, Row } from 'components/atomic';
import { SVGIcon } from 'components/icons';
import { Formik, Form } from 'formik';
import { Dispatch, FC, SetStateAction } from 'react';
import * as Yup from 'yup';

const ResetPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .required('Password is required')
    .min(8, 'Password must be at least 8 characters')
    .max(50, 'Password must be at most 50 characters')
    .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
    .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
    .matches(/[0-9]/, 'Password must contain at least one digit')
    .matches(
      /[!@#$%^&*(),.?":{}|<>`~]/,
      'Password must contain at least one special character'
    )
});

interface ResetPasswordValues {
  password: string;
}

interface ResetPasswordModalProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  onAction: (password: string) => void;
}

const ResetPasswordModal: FC<ResetPasswordModalProps> = ({
  open = false,
  setOpen,
  onAction
}) => {
  const initialValues: ResetPasswordValues = {
    password: ''
  };

  return (
    <Modal
      open={open}
      footer={null}
      afterClose={() => {}}
      centered
      width={600}
      onCancel={() => {
        setOpen(false);
      }}
      closeIcon={<SVGIcon variant="close" stroke="black" />}
    >
      <h2 className="fs--3 fw--600 mb--8">Reset password</h2>

      <Formik
        initialValues={initialValues}
        validationSchema={ResetPasswordSchema}
        enableReinitialize
        validateOnMount
        onSubmit={(values, { setSubmitting, resetForm }) => {
          setSubmitting(true);
          onAction(values.password);
          resetForm();
        }}
      >
        {({ isSubmitting, isValid, setFieldValue }) => (
          <Form>
            <Row>
              <Col md={12} className="mb--6">
                <PasswordField name="password" label="Enter new password" />
                <p className="mt--2 text-neutral-500">
                  Password should be at least 8 characters and include at
                  least one number, symbol, and uppercase letter.
                </p>
              </Col>
            </Row>

            <Row>
              <Col md={6} className="mt--6">
                <Button
                  variant="secondary"
                  type="button"
                  className="w--100"
                  onClick={() => {
                    setOpen(false);
                    setFieldValue('password', '');
                  }}
                >
                  Cancel
                </Button>
              </Col>
              <Col md={6} className="mt--6">
                <Button
                  variant="primary"
                  className="w--100"
                  disabled={!isSubmitting && isValid ? false : true}
                  type="submit"
                >
                  Reset
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default ResetPasswordModal;
