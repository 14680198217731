import axios, {
  Axios,
  AxiosError,
  AxiosRequestConfig,
  toFormData
} from 'axios';
import { withObservable } from 'helpers/Observable';
import { defineObjectProperty } from 'helpers/utils';
import { ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY } from 'config/constants';
import session from 'helpers/storage';

const client = defineObjectProperty(
  withObservable(
    axios.create({
      baseURL: process.env.REACT_APP_API_BASE_URL,
      responseType: 'json',
      responseEncoding: 'utf8',
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json'
      }
    }),
    '$unauthorized'
  ),
  'upload',
  function (
    this: Axios,
    url: string,
    files: Record<string | number, File | File[]>,
    additionalData: Record<string | number, any> = {}
  ) {
    return this.post(url, toFormData({ ...files, ...additionalData }), {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  }
);

const tryRefreshTokens = async () => {
  const refreshToken = session.get(REFRESH_TOKEN_KEY);

  if (!refreshToken) {
    return await Promise.reject(new Error('No refresh token.'));
  }

  try {
    const { data } = await client.post('auth/token/refresh/', {
      refresh: refreshToken
    });

    session.set(ACCESS_TOKEN_KEY, data.access);
    session.set(REFRESH_TOKEN_KEY, data.refresh);

    return data;
  } catch (error) {
    client.$unauthorized.dispatch();
    throw error;
  }
};

client.interceptors.request.use(
  async (config) => {
    config.headers.set(
      'Authorization',
      `Bearer ${String(session.get(ACCESS_TOKEN_KEY))}`
    );

    return config;
  },
  async (error) => await Promise.reject(error)
);

// global variable to hold the promise that will resolve when the tokens are refreshed
let refreshingPromise: ReturnType<typeof tryRefreshTokens> | null = null;

const refreshTokenInterceptor = async <T, D>(error: AxiosError<T, D>) => {
  const refreshToken = session.get(REFRESH_TOKEN_KEY);
  const originalRequest:
    | (AxiosRequestConfig & { retry?: boolean })
    | undefined = error.response?.config;
  // if there is no token just reject the request
  if (!refreshToken) {
    return await Promise.reject(error);
  }
  if (
    error.response?.status === 401 &&
    error.config?.url !== 'auth/token/refresh/' &&
    originalRequest &&
    !originalRequest?.retry
  ) {
    // set retry flag, this will be present on this request and the retry requests and prevents cycling
    originalRequest.retry = true;
    if (refreshingPromise === null) {
      // set the promise once to block following requests
      refreshingPromise = tryRefreshTokens();
    }
    // all promises that are intercepted will wait for this promise to be resolved
    try {
      await refreshingPromise;
      // remove the lock stalling the promises
      refreshingPromise = null;
      // retry the original request with the new headers
    } catch (_) {
      // if the refresh fails, remove the lock and throw an error
      refreshingPromise = null;
      throw new Error('Refresh token failed');
    }
    return await client(originalRequest);
  }
  return await Promise.reject(error);
};

client.interceptors.response.use(
  (response) => response,
  refreshTokenInterceptor
);

export default client;
