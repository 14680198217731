import { FC } from 'react';
import { SVGIconProps } from './types';

const SVGIconRejected: FC<SVGIconProps> = ({
  width = 20,
  height = 20,
  stroke = '#CC0000'
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.9393 15.9398C19.2197 12.6594 19.2197 7.3408 15.9393 4.0604C12.6589 0.779997 7.34032 0.779997 4.05991 4.0604M15.9393 15.9398C12.6589 19.2202 7.34032 19.2202 4.05991 15.9398C0.779508 12.6594 0.779508 7.3408 4.05991 4.0604M15.9393 15.9398L4.05991 4.0604"
      stroke={stroke}
      strokeWidth="1.67"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SVGIconRejected;
