import { Key } from './types';

export const getObjectValue = (object: any, key: Key) => {
  try {
    return String(key)
      .split('.')
      .reduce((obj, property) => obj[property] ?? null, object);
  } catch (err) {
    return null;
  }
};

export const arrayToRecord = <Type>(array: Type[]): Record<Key, Type> => {
  const record: Record<Key, Type> = {};

  array.forEach((item, index) => {
    record[String(index)] = item;
  });

  return record;
};
