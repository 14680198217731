import { useTrackingCode } from 'react-hubspot-tracking-code-hook';
import client from 'api/client';
import { User } from 'models/User';
import {
  createContext,
  Dispatch,
  PropsWithChildren,
  useContext,
  useEffect,
  useReducer
} from 'react';
import { AuthAction, AuthActionType } from './actions';
import AuthReducer, { AuthState } from './reducer';

const initialState: AuthState & {
  dispatch: Dispatch<AuthAction>;
  refetchUser: () => void;
} = {
  user: null,
  isAuthenticating: true,
  error: false,
  dispatch: () => {},
  refetchUser: () => {}
};

export const AuthContext = createContext(initialState);

export const useAuthContext = () => useContext(AuthContext);

export default function AuthContextProvider({ children }: PropsWithChildren) {
  const [state, dispatch] = useReducer(AuthReducer, initialState);
  const { setIdentity } = useTrackingCode();

  const refetchUser = () => {
    User.session().then((payload) => {
      dispatch({ type: AuthActionType.RESUME_SESSION, payload });
    });
  };

  useEffect(() => {
    const subscription = client.$unauthorized.subscribe(() => {
      dispatch({ type: AuthActionType.LOGOUT });
    });

    dispatch({ type: AuthActionType.REQUEST_LOGIN });

    User.session()
      .then((payload) => {
        dispatch({ type: AuthActionType.RESUME_SESSION, payload });
      })
      .catch(() => {
        dispatch({ type: AuthActionType.LOGIN_ERROR });
      });

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  useEffect(() => {
    // TODO: Check this
    if (!state.user?.email) {
      return;
    }

    setIdentity(state.user.email);
  }, [state.user, setIdentity]);

  return (
    <AuthContext.Provider
      value={{
        ...state,
        dispatch,
        refetchUser
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
