import { FC } from 'react';
import { SVGIconProps } from './types';

const SVGIconDashboard: FC<SVGIconProps> = ({
  width = 20,
  height = 18,
  fill = 'white'
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.4718 17.3218H14.5639C14.1607 17.3218 13.8307 16.9919 13.8307 16.5886V0.824988C13.8307 0.421733 14.1607 0.0917969 14.5639 0.0917969H18.4718C18.8751 0.0917969 19.205 0.421733 19.205 0.824988V16.5886C19.205 16.9919 18.8751 17.3218 18.4718 17.3218ZM15.2971 15.8554H17.7386V1.55818H15.2971V15.8554Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.0565 17.3218H8.14855C7.74529 17.3218 7.41536 16.9919 7.41536 16.5886V9.86524C7.41536 9.46198 7.74529 9.13205 8.14855 9.13205H12.0565C12.4597 9.13205 12.7897 9.46198 12.7897 9.86524V16.5886C12.7897 16.9919 12.4597 17.3218 12.0565 17.3218ZM8.88174 15.8554H11.3233V10.5984H8.88174V15.8554Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.73319 17.3218H5.6411C6.04436 17.3218 6.37429 16.9919 6.37429 16.5886V6.56588C6.37429 6.16262 6.04436 5.83268 5.6411 5.83268H1.73319C1.32994 5.83268 1 6.16262 1 6.56588V16.5886C1 16.9919 1.32994 17.3218 1.73319 17.3218ZM4.90791 15.8554H2.46638V7.29907H4.90791V15.8554Z"
      fill={fill}
    />
  </svg>
);

export default SVGIconDashboard;
