import { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf/dist/esm/entry.webpack5';
import style from './pdf-viewer.module.scss';

import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import SVGIcon from 'components/icons/SVGIcon';
import { Pagination } from 'antd';

// TODO: Is there a better solution for this? Using this in time crunch.
const url = `//cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;
pdfjs.GlobalWorkerOptions.workerSrc = url;

interface PDFViewerProps {
  url: string;
  label?: string;
  scaleMin?: number;
  scaleMax?: number;
  scaleStep?: number;
}

function PDFViewer({
  url,
  label,
  scaleMin = 0.2,
  scaleMax = 2,
  scaleStep = 0.1
}: PDFViewerProps) {
  const [pages, setPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [scale, setScale] = useState(1.2);
  const [rotation, setRotation] = useState(0);

  const handleScale = (direction: -1 | 1) => {
    setScale(
      Math.max(scaleMin, Math.min(scaleMax, scale + scaleStep * direction))
    );
  };

  const handleRotation = (direction: -1 | 1) => {
    setRotation((rotation + 90 * direction + 360) % 360);
  };

  return (
    <div className={style.wrapper}>
      <div className={style.info}>
        <p className={style.name}>{label}</p>

        <div className={style.controlls}>
          <button
            onClick={() => {
              handleScale(1);
            }}
          >
            <SVGIcon variant="zoom-in" />
          </button>
          <button
            onClick={() => {
              handleScale(-1);
            }}
          >
            <SVGIcon variant="zoom-out" />
          </button>
          <button
            onClick={() => {
              handleRotation(-1);
            }}
          >
            <SVGIcon variant="rotate-left" />
          </button>
          <button
            onClick={() => {
              handleRotation(1);
            }}
          >
            <SVGIcon variant="rotate-right" />
          </button>
        </div>
      </div>
      <div className={style.previewContainer}>
        <Document
          file={url}
          rotate={rotation}
          loading={<></>}
          className={style.document}
          error={<h1>Failed to load document</h1>}
          noData={<h1>Failed to load document</h1>}
          onLoadSuccess={({ numPages }) => {
            setPages(numPages);
          }}
        >
          <Page
            pageNumber={currentPage}
            width={500}
            height={700}
            scale={scale}
          />
        </Document>
      </div>

      <div className={style.paginationHolder}>
        <Pagination
          current={currentPage}
          defaultPageSize={1}
          total={pages}
          onChange={(page) => setCurrentPage(page)}
          responsive
        />
      </div>
    </div>
  );
}

export default PDFViewer;
