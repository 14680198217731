import { FC } from 'react';
import { SVGIconProps } from './types';

const SVGIconSignOut: FC<SVGIconProps> = ({
  width = 16,
  height = 15,
  stroke = 'white',
  strokeWidth = "1.67",
  className = '',
}) => (
  <svg width={width} height={height} viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.22222 10.2181L4.11111 7.10699M4.11111 7.10699L7.22222 3.99588M4.11111 7.10699L15 7.10699M11.1111 10.2181V10.9959C11.1111 12.2845 10.0664 13.3292 8.77778 13.3292H3.33333C2.04467 13.3292 1 12.2845 1 10.9959V3.2181C1 1.92943 2.04467 0.884766 3.33333 0.884766H8.77778C10.0664 0.884766 11.1111 1.92943 11.1111 3.2181V3.99588" stroke={stroke} strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round" className={className}/>
  </svg>
);

export default SVGIconSignOut;
