import { FC } from 'react';
import { Col as VendorCol, ColProps as VendorColProps } from 'antd';

interface ColProps extends VendorColProps {
  xs?: number | undefined;
  sm?: number | undefined;
  md?: number | undefined;
  lg?: number | undefined;
  xl?: number | undefined;
  xxl?: number | undefined;
}

const Col: FC<ColProps> = ({ xs, sm, md, lg, xl, xxl, ...props }) => {
  return (
    <VendorCol
      xs={xs ? xs * 2 : {}}
      sm={sm ? sm * 2 : {}}
      md={md ? md * 2 : {}}
      lg={lg ? lg * 2 : {}}
      xl={xl ? xl * 2 : {}}
      xxl={xxl ? xxl * 2 : {}}
      {...props}
    />
  );
};

export default Col;
