import { useMutation, useQuery } from '@tanstack/react-query';
import { Modal } from 'antd';
import { AxiosError } from 'axios';

import { Button, Col, Row, Select } from 'components/atomic';
import { Formik, Form, FieldArray } from 'formik';
import { Dispatch, FC, SetStateAction, useMemo } from 'react';
import { toast } from 'react-toastify';
import {
  assignApplication,
  getAllMembers,
  getAssignments
} from 'api/services/super-admin/requests';
import { collect } from 'helpers/Collection';
import { AssignApplicationRequest } from 'api/services/super-admin/interfaces';
import { SVGIcon } from 'components/icons';
import { SendbirdUpdateChannelRequest } from 'api/services/app/common/interfaces/sendbird-responses';
import { patchSendbirdChannel } from 'api/services/app/common/requests/sendbird';
import { MemberGroupRole } from 'api/services/app/common/interfaces/common';

interface AssigneValues {
  company: string;
  assignees: Array<{ id: number | ''; name: string; role: MemberGroupRole | '' }>;
  session: string;
}

interface AssigneModalProps {
  open: boolean;
  company: { id: number; name: string };
  setOpen: Dispatch<SetStateAction<boolean>>;
  onAction: () => void;
}
const AssigneModal: FC<AssigneModalProps> = ({
  open = false,
  setOpen,
  onAction,
  company
}) => {
  const { data, refetch } = useQuery(['super-admin/assigments', company.id], () =>
    getAssignments(company.id)
  );
  const { data: allMembers } = useQuery(['super-admin/all-members'], () =>
    getAllMembers()
  );
  const { mutate } = useMutation({
    mutationFn: (payload: AssignApplicationRequest) =>
      assignApplication(payload)
  });

  const initialValues: AssigneValues = useMemo(
    () => ({
      company: data?.company ?? '',
      assignees: data?.assignees?.length
        ? data?.assignees
        : [{ id: '', name: '', role: '' }],
      session: data?.session ?? ''
    }),
    [data]
  );

  //Finds CSC User in the Assignees 
  const getAssigneeId = (assignees: AssigneValues['assignees'], role: MemberGroupRole) => {
    let assignee = assignees.length === 1 && assignees[0].role === role ? assignees[0] : assignees.find(a => a.role === role);
    return assignee?.id;
  }

  return (
    <Modal
      open={open}
      footer={null}
      afterClose={() => { }}
      centered
      width={600}
      onCancel={() => setOpen(false)}
      closeIcon={<SVGIcon variant='close' stroke='black' />}
    >
      <h2 className="fs--3 fw--600 mb--5">Assignments</h2>

      <Formik
        initialValues={initialValues}
        enableReinitialize
        validateOnMount
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);
          const payload: AssignApplicationRequest = {
            deal_id: company.id,
            assignees: values.assignees.map(({ id }) => id)
          };
          mutate(payload, {
            onSuccess() {
              toast.success('Deal assigned successfully');
              setOpen(false);
              onAction();
              refetch();
              if (values.assignees && initialValues.assignees && data?.session) {
                const previous_user_id = getAssigneeId(initialValues.assignees, MemberGroupRole.CSC_USER);
                const updated_user_id = getAssigneeId(values.assignees, MemberGroupRole.CSC_USER);

                if (previous_user_id && updated_user_id) {
                  const sendbirdPayload: SendbirdUpdateChannelRequest = {
                    previous_user_id,
                    updated_user_id,
                    session: data.session
                  };

                  patchSendbirdChannel(sendbirdPayload);
                }
              }
            },
            onError(error) {
              const { response } = error as AxiosError<any>;
              if (response?.data?.type === 'validation-error') {
                collect(response?.data?.errors).forEach((errors, key) => {
                  toast.error(errors[0]);
                });
              } else {
                toast.error('An error occurred while trying assign this case.')
              }
            },
            onSettled() {
              setSubmitting(false);
            }
          });
        }}
      >
        {({ values, isSubmitting, isValid }) => (
          <Form>
            <Row className="mb--2">
              <Col md={3}>
                <p className="cs-label">Company Name:</p>
              </Col>
              <Col md={9}>
                <p>{values.company}</p>
              </Col>
            </Row>
            <FieldArray name="assignees">
              {({ push, remove }) => (
                <>
                  {values.assignees?.map((value, index) => {
                    return (
                      <Row className="mb--4 d--f f--ac" key={index}>
                        <Col md={3}>
                          <p className="cs-label">Assignee:</p>
                        </Col>
                        <Col md={8}>
                          <Select
                            name={`assignees.${index}.id`}
                            placeholder="Select member"
                            options={allMembers?.map(({ id, name }) => ({
                              value: id,
                              label: name
                            }))}
                          />
                        </Col>
                        <Col md={1}>
                          <button type="button" onClick={() => remove(index)}>
                            <SVGIcon
                              variant="trash"
                              className="color-neutral-1000"
                            />
                          </button>
                        </Col>
                      </Row>
                    );
                  })}
                  <Row className="mt--7">
                    <Col md={12}>
                      <Button
                        variant="secondary"
                        size="sm"
                        iconType="fill"
                        onClick={() => push({ id: 0, name: '' })}
                        className="fw--500 py--2 px--3 lh--sm"
                        type="button"
                      >
                        <SVGIcon variant="plus" height="20" width="20" className="w--unset" />
                        Add assignee
                      </Button>
                    </Col>
                  </Row>
                </>
              )}
            </FieldArray>

            <Row>
              <Col md={12} className="mt--6">
                <Button
                  variant="primary"
                  size="md"
                  className="w--100"
                  disabled={!isSubmitting && isValid ? false : true}
                  type="submit"
                >
                  Save
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default AssigneModal;