import { Nullable } from 'api/services/app/common/interfaces/common';
import { PPPRoundChoices } from 'api/services/register/interfaces';
import { User } from 'models';

export interface UserResponse {
  id: number;
  username: string;
  email: string;
  avatar?: string;
  first_name?: string;
  last_name?: string;
  title?: string;
  roles?: string[];
  permissions?: string[];
  firm_id?: Nullable<number>;
  uid?: string;
  test_user?: boolean;
  da_deal_assign: string[];
}

export enum AffiliateSourceTypes {
  POUNDCAKE = 'poundcake',
  SUPER_ADMIN = 'super_admin'
}

export const AffiliateSourceMap: Record<string, AffiliateSourceTypes> = {
  poundcake: AffiliateSourceTypes.POUNDCAKE,
  super_admin: AffiliateSourceTypes.SUPER_ADMIN
};

export interface UserRegisterPayload {
  employee_2020: number | string;
  employee_2021: number | string;
  hcaptcha: string;
  legal_name: string;
  first_name: string;
  last_name: string;
  job_title: string;
  email: string;
  phone_number: string;
  discovery: number | string;
  password: string;
  business_relation: number | string;
  accept_terms: boolean;
  gclid: string;
  utm_campaign: string;
  utm_content: string;
  utm_medium: string;
  utm_source: string;
  utm_term: string;
  affiliate_id?: number;
  affiliate_source?: AffiliateSourceTypes;
}

export interface RegisterPayload {
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
  legal_name: string;
  job_title: string;
  website_url: string;
  has_no_website: boolean;
  industry: number;
  full_time_employee_2021: number | string;
  part_time_employee_2021: number | string;
  covid_disruptions: string[];
  discovery: number | string;
  business_relation: number | string;
  accept_terms: boolean;
  utm_source: string;
  utm_medium: string;
  utm_term: string;
  utm_campaign: string;
  utm_content: string;
  gclid: string;
  hcaptcha: string;
  impacted_quarters: string[];
  individual_own_51_percents_or_more: boolean;
  number_of_family_members_on_payroll: Nullable<number>;
  has_benefits: boolean;
  ppp_participated_rounds: Nullable<PPPRoundChoices>;
  utilize_peo: boolean;
  username: string;
  password: string;
}

export interface NewUserSetPasswordRequest {
  password1: string;
  password2: string;
  token?: string;
}

export interface UserLoginPayload {
  username: string;
  password: string;
}

export interface RegisterUserResponse {
  session?: string;
  user?: User;
  accessToken?: string;
  refreshToken?: string;
  redirect_link?: string;
  deal_id?: number;
  deal_owner?: string;
}

export interface RegisterInternalUserResponse {
  user: User;
  accessToken: string;
  refreshToken: string;
}
