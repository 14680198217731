import { FC } from 'react';
import { SVGIconProps } from './types';

const SVGHamburgerMenu: FC<SVGIconProps> = ({
  width = 18,
  height = 14,
  stroke = 'white',
  strokeWidth = 2,
  className = '',
  onClick
}) => (
  <svg width={width} height={height} viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={onClick} className={className}>
    <path d="M1.29688 1.27832H16.6969M1.29688 7.05332H16.6969M1.29688 12.8283H8.03438" stroke={stroke} strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

export default SVGHamburgerMenu;
