import { FC } from 'react';
import { SVGIconProps } from './types';

const SVGIconBenefits: FC<SVGIconProps> = ({
  width = 20,
  height = 21,
  stroke = 'white'
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.5 15.6917H17V14.2917C17 13.1319 16.0598 12.1917 14.9 12.1917C14.2311 12.1917 13.6352 12.5045 13.2506 12.9917M13.5 15.6917H6.5M13.5 15.6917V14.2917C13.5 13.8323 13.4115 13.3936 13.2506 12.9917M6.5 15.6917H3V14.2917C3 13.1319 3.9402 12.1917 5.1 12.1917C5.76892 12.1917 6.3648 12.5045 6.74937 12.9917M6.5 15.6917V14.2917C6.5 13.8323 6.5885 13.3936 6.74937 12.9917M6.74937 12.9917C7.26545 11.7024 8.52637 10.7917 10 10.7917C11.4736 10.7917 12.7345 11.7024 13.2506 12.9917M12.1 6.5917C12.1 7.7515 11.1598 8.6917 10 8.6917C8.8402 8.6917 7.9 7.7515 7.9 6.5917C7.9 5.4319 8.8402 4.4917 10 4.4917C11.1598 4.4917 12.1 5.4319 12.1 6.5917ZM16.3 8.6917C16.3 9.4649 15.6732 10.0917 14.9 10.0917C14.1268 10.0917 13.5 9.4649 13.5 8.6917C13.5 7.9185 14.1268 7.2917 14.9 7.2917C15.6732 7.2917 16.3 7.9185 16.3 8.6917ZM6.5 8.6917C6.5 9.4649 5.8732 10.0917 5.1 10.0917C4.3268 10.0917 3.7 9.4649 3.7 8.6917C3.7 7.9185 4.3268 7.2917 5.1 7.2917C5.8732 7.2917 6.5 7.9185 6.5 8.6917Z"
      stroke={stroke}
      strokeWidth="1.67"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SVGIconBenefits;
