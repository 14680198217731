import { FC } from 'react';
import { SVGIconProps } from './types';

const SVGIconFileList: FC<SVGIconProps> = ({
  width = 16,
  height = 16,
  stroke = 'black',
  strokeWidth = 1.67
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M12.2666 22.7222V20.2333M15.9999 22.7222V17.7444M19.7333 22.7222V15.2555M22.2222 27.7H9.77771C8.40313 27.7 7.28882 26.5857 7.28882 25.2111V7.78888C7.28882 6.4143 8.40313 5.29999 9.77771 5.29999H16.7289C17.059 5.29999 17.3755 5.4311 17.6089 5.66448L24.3466 12.4022C24.5799 12.6355 24.711 12.9521 24.711 13.2821V25.2111C24.711 26.5857 23.5967 27.7 22.2222 27.7Z" stroke={stroke} strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

export default SVGIconFileList;

