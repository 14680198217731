import { FC } from 'react';
import { SVGIconProps } from './types';

const SVGIconLockCircle: FC<SVGIconProps> = ({
  width = 48,
  height = 48,
  stroke = '#2E2E2E'
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
        width="48"
        height="48"
        rx="24"
        fill="#EEE8FD"
      />
    <path 
        d="M24.0018 26.8001V28.6668M18.4018 32.4001H29.6018C30.6328 32.4001 31.4685 31.5644 31.4685 30.5334V24.9334C31.4685 23.9025 30.6328 23.0668 29.6018 23.0668H18.4018C17.3709 23.0668 16.5352 23.9025 16.5352 24.9334V30.5334C16.5352 31.5644 17.3709 32.4001 18.4018 32.4001ZM27.7352 23.0668V19.3334C27.7352 17.2716 26.0637 15.6001 24.0018 15.6001C21.94 15.6001 20.2685 17.2716 20.2685 19.3334V23.0668H27.7352Z"
        stroke={stroke}
        strokeWidth="1.67"
        strokeLinecap="round"
      />
  </svg>
);

export default SVGIconLockCircle;
