import { FC } from 'react';
import { SVGIconProps } from './types';

const SVGIconFab: FC<SVGIconProps> = ({
  width = 33,
  height = 31,
  stroke = '#FDFDFD',
  strokeWidth = 4
}) => (
  <svg width={width} height={height} viewBox="0 0 33 31" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.1207 15.4997H10.1366M16.4984 15.4997H16.5144M22.8762 15.4997H22.8922M30.8484 15.4997C30.8484 22.5444 24.4237 28.2553 16.4984 28.2553C14.0442 28.2553 11.7338 27.7076 9.71355 26.7422L2.14844 28.2553L4.37267 22.324C2.9641 20.3505 2.14844 18.0098 2.14844 15.4997C2.14844 8.455 8.57315 2.74414 16.4984 2.74414C24.4237 2.74414 30.8484 8.455 30.8484 15.4997Z" stroke={stroke} strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

export default SVGIconFab;
