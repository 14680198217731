import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { StringMatcher } from 'helpers/Collection/StringMatcher';

const useDocumentTitle = (
  config: Array<{ matcher: StringMatcher; title: string }>,
  defaultTitle: string
) => {
  const { pathname } = useLocation();

  return useMemo(
    () =>
      config.find(({ matcher }) => matcher.matches(pathname))?.title ??
      defaultTitle,
    [config, defaultTitle, pathname]
  );
};

export default useDocumentTitle;
