import { FC } from 'react';
import { SVGIconProps } from './types';

const SVGIconCheckmarkCircle: FC<SVGIconProps> = ({
  width = 17,
  height = 17,
  fill = 'none',
  stroke = 'black',
  strokeWidth = 1.67
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill={fill}
      stroke={stroke}
      strokeWidth={strokeWidth}
      d="M7.19961 9.99961L9.06628 11.8663L12.7996 8.13294M18.3996 9.99961C18.3996 14.6388 14.6388 18.3996 9.99961 18.3996C5.36042 18.3996 1.59961 14.6388 1.59961 9.99961C1.59961 5.36042 5.36042 1.59961 9.99961 1.59961C14.6388 1.59961 18.3996 5.36042 18.3996 9.99961Z"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SVGIconCheckmarkCircle;
