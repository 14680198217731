import { FC, HTMLAttributes } from 'react';
import { ErrorMessage, FieldHookConfig, useField } from 'formik';
import type { FieldColAlignment } from '../types';
import style from './text-field.module.scss';
import cx from 'classnames';

type AllowedValue = string | number | string[] | undefined;

type TextFieldProps = FieldHookConfig<AllowedValue> &
  HTMLAttributes<HTMLInputElement> &
  FieldColAlignment & {
    type?: 'text' | 'email' | 'number' | 'password';
    label?: string;
  } & {
    showError?: boolean;
  };

const TextField: FC<TextFieldProps> = ({
  showError = true,
  label,
  left,
  right,
  middle,
  onChange,
  onBlur,
  ...props
}) => {
  const [field, { error, touched }] = useField({ ...props });

  const generic = props as HTMLAttributes<HTMLInputElement>;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    field.onChange(event);
    if (onChange) {
      onChange(event);
    }
  };

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    field.onBlur(event);
    if (onBlur) {
      onBlur(event);
    }
  };

  return (
    <div
      className={cx(
        style.wrapper,
        left ? 'field-left' : '',
        right ? 'field-right' : '',
        middle ? 'field-middle' : ''
      )}
    >
      {label && <label htmlFor={props.id ?? props.name}>{label}</label>}
      <input
        className={touched && error && showError ? style.error : ''}
        {...generic}
        {...field}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      {showError && (
        <span className={style.error}>
          <ErrorMessage name={props.name} />
        </span>
      )}
    </div>
  );
};

export default TextField;
