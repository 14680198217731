import { FC } from 'react';
import { SVGIconProps } from './types';

const SVGIconLogout: FC<SVGIconProps> = ({
  width = 16,
  height = 15,
  stroke = '#2E2E2E'
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
        d="M7.22222 10.7103L4.11111 7.59918M4.11111 7.59918L7.22222 4.48806M4.11111 7.59918L15 7.59917M11.1111 10.7103V11.4881C11.1111 12.7767 10.0664 13.8214 8.77778 13.8214H3.33333C2.04467 13.8214 1 12.7767 1 11.4881V3.71029C1 2.42162 2.04467 1.37695 3.33333 1.37695H8.77778C10.0664 1.37695 11.1111 2.42162 11.1111 3.71029V4.48806"
        stroke={stroke}
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
    />
  </svg>
);

export default SVGIconLogout;
