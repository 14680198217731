import React, { useState } from 'react';
import { SVGIcon } from 'components/icons';
import style from './image-viewer.module.scss';
import Loader from 'components/atomic/Loader/Loader';

type ImageViewerProps = {
  url: string;
  label?: string;
  scaleMin?: number;
  scaleMax?: number;
  scaleStep?: number;
  rotationStep?: number;
};

const ImageViewer: React.FC<ImageViewerProps> = ({
  url,
  label,
  scaleMin = 0.2,
  scaleMax = 2,
  scaleStep = 0.1,
  rotationStep = 30
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [scale, setScale] = useState(1);
  const [rotation, setRotation] = useState(0);

  const handleScale = (direction: -1 | 1) => {
    setScale(
      Math.max(scaleMin, Math.min(scaleMax, scale + scaleStep * direction))
    );
  };

  const handleRotation = (direction: -1 | 1) => {
    setRotation((rotation + rotationStep * direction + 360) % 360);
  };

  return (
    <div className={style.wrapper}>
      <div className={style.info}>
        <p className={style.name}>{label}</p>
        <div className={style.controlls}>
          <button
            onClick={() => {
              handleScale(1);
            }}
          >
            <SVGIcon variant="zoom-in" />
          </button>
          <button
            onClick={() => {
              handleScale(-1);
            }}
          >
            <SVGIcon variant="zoom-out" />
          </button>
          <button
            onClick={() => {
              handleRotation(-1);
            }}
          >
            <SVGIcon variant="rotate-left" />
          </button>
          <button
            onClick={() => {
              handleRotation(1);
            }}
          >
            <SVGIcon variant="rotate-right" />
          </button>
        </div>
      </div>
      <div className={style.previewContainer}>
        {isLoading ? (
          <Loader inContext />
        ) : (
          <img
            src={url}
            alt=""
            style={{
              transform: `scale(${scale}) rotate(${rotation}deg)`,
              transformOrigin: 'center'
            }}
            onLoad={() => {
              setIsLoading(false);
            }}
          />
        )}
      </div>
    </div>
  );
};

export default ImageViewer;
