import { FC } from 'react';
import { SVGIconProps } from './types';

const SVGIconCarretDown: FC<SVGIconProps> = ({
  width = 10,
  height = 7,
  fill = 'black',
  stroke = 'black',
  strokeWidth = 2
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 10 7"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill={fill}
      stroke={stroke}
      strokeWidth={strokeWidth}
      d="M1.12072 1.53553L4.65625 5.07107L8.19178 1.53553"
      strokeLinecap="round"
    />
  </svg>
);

export default SVGIconCarretDown;
