



import { FC } from 'react';
import { SVGIconProps } from './types';

const SVGIconSquare: FC<SVGIconProps> = ({
                                         width = 14,
                                         height = 14,
                                         stroke = '#2E2E2E',
                                         strokeWidth = 1.67,
                                         fill = '#1F57FF',
                                         opacity = "0.2",
                                       }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect opacity={opacity} width={width} height={height} rx="3" fill={fill}/>
  </svg>
);

export default SVGIconSquare;