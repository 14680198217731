import { useMutation, useQuery } from '@tanstack/react-query';
import { Modal } from 'antd';
import { AssignErcaApplicationRequest } from 'api/services/super-admin/interfaces';
import {
  assignErcaManagers,
  getAllMembers
} from 'api/services/super-admin/requests';
import { useApplicationId } from 'apps/common/hook/useApplicationId';
import { Button, Col, Row, Select } from 'components/atomic';
import { SVGIcon } from 'components/icons';
import { Formik, Form } from 'formik';
import { Dispatch, FC, SetStateAction } from 'react';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

const Assignmanagerschema = Yup.object().shape({
  assignee_id: Yup.number().required('You must select manager')
});

interface AssignErcaManagerModalProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  deal: number;
  onAction?: () => void;
}

const AssignErcaManagerModal: FC<AssignErcaManagerModalProps> = ({
  open = false,
  setOpen,
  deal,
  onAction
}) => {
  const { applicationId } = useApplicationId();

  const { data: managers, isLoading } = useQuery(
    ['erca-managers-list', applicationId],
    () => getAllMembers(11)
  );

  const { mutate } = useMutation({
    mutationFn: (payload: AssignErcaApplicationRequest) =>
      assignErcaManagers(payload)
  });

  return (
    <Modal
      open={open}
      footer={null}
      afterClose={() => {}}
      centered
      width={600}
      onCancel={() => setOpen(false)}
      closeIcon={<SVGIcon variant="close" stroke="#000000" />}
    >
      <h2 className="fs--3 fw--600">Assign selected cases</h2>
      <p className="mt--6 mb--4">
        Choose who should be assigned to these cases.
      </p>
      <Formik
        initialValues={{
          assignee_id: 0
        }}
        validationSchema={Assignmanagerschema}
        enableReinitialize
        validateOnMount
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);
          console.log(values);
          mutate(
            { ...values, deal_id: deal },
            {
              onSuccess() {
                toast.success('Case assigned successfully');
                onAction?.();
                setOpen(false);
              },
              onError() {
                toast.error('An error occurred while closing the application');
              },
              onSettled() {
                setSubmitting(false);
              }
            }
          );
        }}
      >
        {({ values, isSubmitting, isValid }) => (
          <Form>
            <Row>
              <Col md={12} className="mb--6">
                <Select
                  name="assignee_id"
                  label="Erca manager"
                  disabled={isLoading}
                  placeholder="Select manager"
                  options={managers?.map(({ id, name }) => ({
                    value: id,
                    label: `${name}`
                  }))}
                />
              </Col>
            </Row>

            <Row>
              <Col md={12} className="mt--6">
                <Button
                  name="assign-deal-btn"
                  variant="primary"
                  size="md"
                  className="w--100"
                  disabled={!isSubmitting && isValid ? false : true}
                  type="submit"
                >
                  Assign
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default AssignErcaManagerModal;
