import { ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY } from 'config/constants';
import session from 'helpers/storage';
import { User } from 'models/User';
import { AuthState } from './reducer';

export enum AuthActionType {
  REQUEST_LOGIN = 'REQUEST_LOGIN',
  LOGIN_SUCCESS = 'LOGIN_SUCCESS',
  LOGIN_ERROR = 'LOGIN_ERROR',
  LOGOUT = 'LOGOUT',
  RESUME_SESSION = 'RESUME_SESSION'
}

export type AuthAction =
  | { type: AuthActionType.REQUEST_LOGIN }
  | {
      type: AuthActionType.LOGIN_SUCCESS;
      payload: { user: User; accessToken: string; refreshToken: string };
    }
  | { type: AuthActionType.LOGIN_ERROR }
  | { type: AuthActionType.LOGOUT }
  | { type: AuthActionType.RESUME_SESSION; payload: { user: User } };

const actions = {
  [AuthActionType.REQUEST_LOGIN]: (state: AuthState) => {
    return {
      ...state,
      user: null,
      isAuthenticating: true,
      error: false
    };
  },

  [AuthActionType.LOGIN_SUCCESS]: (
    state: AuthState,
    {
      user,
      accessToken,
      refreshToken
    }: { user: User; accessToken: string; refreshToken: string }
  ) => {
    session.set(ACCESS_TOKEN_KEY, accessToken);
    session.set(REFRESH_TOKEN_KEY, refreshToken);

    return {
      ...state,
      user: User.clone(user),
      isAuthenticating: false,
      error: false,
      redirect_link: null
    };
  },

  [AuthActionType.LOGIN_ERROR]: (state: AuthState) => {
    return {
      ...state,
      user: null,
      isAuthenticating: false,
      error: true
    };
  },

  [AuthActionType.LOGOUT]: (state: AuthState) => {
    session.delete(ACCESS_TOKEN_KEY);
    session.delete(REFRESH_TOKEN_KEY);

    return {
      ...state,
      isAuthenticating: false,
      user: null,
      error: false
    };
  },

  [AuthActionType.RESUME_SESSION]: (
    state: AuthState,
    { user }: { user: User }
  ) => {
    return {
      ...state,
      user: User.clone(user),
      isAuthenticating: false,
      error: false
    };
  }
};

export default actions;
