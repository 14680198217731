import client from 'api/client';
import { SendbirdAppIdResponse, SendbirdChannelResponse, SendbirdCreateUserRequest, SendbirdCreateUserResponse, SendbirdSessionTokenResponse, SendbirdUpdateChannelRequest } from '../interfaces/sendbird-responses';

/**  Get the accessToken for Customer & AM */
export const postSendbirdSessionToken = async (
  session: string
): Promise<SendbirdSessionTokenResponse> =>
  (await client.post(`/chat/token/`, { user_id: session }))?.data;

/** Get the Customer channel url */
export const getSendbirdChannel = async (
  session: string,
): Promise<SendbirdChannelResponse> => {
  return (await client.get(`/chat/channel/${session}`))?.data;
};

/** Get the Sendbird AppId for Customer & AM */
export const getSendbirdAppId = async (): Promise<SendbirdAppIdResponse> => (await client.get(`/chat`))?.data;

/** Post request to create new User  */
export const postCreateSendBirdUser = async (payload: SendbirdCreateUserRequest): Promise<SendbirdCreateUserResponse> => (await client.post(`/chat/users/`, payload, {
  headers: {
    'Content-Type': 'multipart/form-data'
  }
}))?.data;

export const patchSendbirdChannel = async (payload: SendbirdUpdateChannelRequest): Promise<SendbirdChannelResponse> => (await client.patch(`/chat/channel/${payload.session}/`, payload))?.data;
