import { FC, HTMLAttributes } from 'react';
import { ErrorMessage, FieldHookConfig, useField } from 'formik';
import cx from 'classnames';
import style from './checkbox.module.scss';

type AllowedValue = string | number | readonly string[] | undefined;

type CheckboxProps = FieldHookConfig<AllowedValue> &
  HTMLAttributes<HTMLInputElement> & {
    label?: string;
    hideError?: boolean;
  };

const Checkbox: FC<CheckboxProps> = ({
  label,
  hideError = false,
  onChange,
  className,
  ...props
}) => {
  const [field] = useField({ ...props, type: 'checkbox' });

  const generic = props as HTMLAttributes<HTMLInputElement>;

  return (
    <div className={cx(style.wrapper, className)}>
      <div className="inner-wrap">
        <input
          {...generic}
          {...field}
          onChange={(event) => {
            field.onChange(event);
            onChange?.(event);
          }}
          type="checkbox"
        />
        {label && <label htmlFor={props.id ?? props.name}>{label}</label>}
      </div>
      {!hideError && (
        <span className={style.error}>
          <ErrorMessage name={props.name} />
        </span>
      )}
    </div>
  );
};

export default Checkbox;
