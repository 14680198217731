import { FC } from 'react';
import { SVGIconProps } from './types';

const SVGIconTriangleAlertCircle: FC<SVGIconProps> = ({
  width = 48,
  height = 48
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="48" height="48" rx="24" fill="#EEE8FD" />
    <path
      d="M24 22.119V24M24 27.7621H24.0094M17.4838 31.5242H30.5161C31.9642 31.5242 32.8692 29.9567 32.1452 28.7026L25.629 17.4164C24.905 16.1623 23.095 16.1623 22.3709 17.4164L15.8548 28.7026C15.1308 29.9567 16.0358 31.5242 17.4838 31.5242Z"
      stroke="#171717"
      strokeWidth="1.67"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SVGIconTriangleAlertCircle;
