import { useMutation, useQueries } from '@tanstack/react-query';
import { Modal } from 'antd';
import {
  Button,
  Col,
  Dropbox,
  Loader,
  PhoneNumber,
  Row,
  Select,
  TextField,
  UploadField
} from 'components/atomic';
import { Formik, Form } from 'formik';
import { Dispatch, FC, SetStateAction, useMemo } from 'react';
import { toast } from 'react-toastify';
import {
  parsePhoneNumber,
  parsePhoneNumberFromString
} from 'libphonenumber-js';
import * as Yup from 'yup';
import { Nullable } from 'api/services/app/common/interfaces/common';
import {
  getAllMembersGroups,
  getAllMembersRoles,
  getSingleMember,
  updateMember
} from 'api/services/super-admin/requests';
import {
  MemberUser,
  MembersRequest
} from 'api/services/super-admin/interfaces';
import { SVGIcon } from 'components/icons';
import { getAllTaxFirms } from 'api/services/tax-attorney-admin/requests';

const EditMemberSchema = Yup.object().shape({
  user: Yup.object().shape({
    first_name: Yup.string().required('You must enter attorney first name'),
    last_name: Yup.string().required('You must enter attorney last name'),
    email: Yup.string()
      .email('Invalid email address')
      .required('Email address is required')
  }),
  phone: Yup.string()
    .test(
      'phone',
      'Invalid phone number',
      (value) => parsePhoneNumberFromString(value!, 'US')?.isValid() ?? false
    )
    .required('Please provide your mobile number'),
  title: Yup.string().required('You must enter title'),
  role: Yup.number().required('You must select member role'),
  hubspot_id: Yup.string().optional(),
  role_group: Yup.number().required('You must select member member group')
});

interface EditMemberValues {
  avatar: Nullable<File>;
  phone: string;
  title: string;
  hubspot_id: string;
  role: number;
  role_group: number;
  user: MemberUser;
  firm?: number;
}

interface EditMemberModalProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  onAction: () => void;
  member_id: number;
}

const EditMemberModal: FC<EditMemberModalProps> = ({
  open = false,
  setOpen,
  onAction,
  member_id
}) => {
  const [
    { data: member, isLoading: memberLoading },
    { data: roles, isLoading: rolesLoading },
    { data: groups, isLoading: groupsLoading },
    { data: firms, isLoading: firmsLoading }
  ] = useQueries({
    queries: [
      {
        queryKey: ['super-admin/member'],
        queryFn: () => getSingleMember(member_id)
      },
      {
        queryKey: ['super-admin/member-roles'],
        queryFn: () => getAllMembersRoles()
      },
      {
        queryKey: ['super-admin/member-groups'],
        queryFn: () => getAllMembersGroups()
      },
      {
        queryKey: ['super-admin/firms'],
        queryFn: () => getAllTaxFirms()
      }
    ]
  });

  const { mutate } = useMutation({
    mutationFn: (payload: MembersRequest) => updateMember(member_id, payload)
  });

  const initialValues: EditMemberValues = useMemo(
    () => ({
      phone: member?.phone
        ? parsePhoneNumber(member.phone.replace(/\s+/g, '')).formatNational()
        : '',
      title: member?.title ?? '',
      hubspot_id: member?.hubspot_id ?? '',
      role: member?.role ?? 0,
      role_group: member?.role_group ?? 0,
      avatar: null,
      firm: member?.firm,
      user: {
        first_name: member?.user.first_name ?? '',
        last_name: member?.user.last_name ?? '',
        email: member?.user.email ?? '',
        username: member?.user.username ?? ''
      }
    }),
    [member]
  );

  if (rolesLoading || groupsLoading || memberLoading || firmsLoading) {
    return <Loader inContext={true} />;
  }

  return (
    <Modal
      open={open}
      footer={null}
      afterClose={() => {}}
      centered
      width={600}
      onCancel={() => setOpen(false)}
      closeIcon={<SVGIcon variant="close" stroke="black" />}
    >
      <h2 className="fs--3 fw--600">Edit member</h2>

      <Formik
        initialValues={initialValues}
        validationSchema={EditMemberSchema}
        enableReinitialize
        validateOnMount
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);
          values.user.username = values.user.email;
          mutate(values, {
            onSuccess() {
              toast.success('Member updated successfully.');
              setOpen(false);
              onAction();
            },
            onError() {
              toast.error('An error occurred while saving the member');
            },
            onSettled() {
              setSubmitting(false);
            }
          });
        }}
      >
        {({ values, isSubmitting, isValid, setFieldValue }) => (
          <Form>
            <Row>
              <Col md={6} sm={12} className="mb--6">
                <TextField name="user.first_name" label="First name*" />
              </Col>
              <Col md={6} sm={12} className="mb--6">
                <TextField name="user.last_name" label="Last name*" />
              </Col>
            </Row>
            <Row>
              <Col md={12} sm={12} className="mb--6">
                <TextField name="user.email" label="Email*" />
              </Col>
            </Row>
            <Row>
              <Col md={12} sm={12} className="mb--6">
                <PhoneNumber name="phone" label="Phone*" />
              </Col>
            </Row>
            <Row>
              <Col md={12} sm={12} className="mb--6">
                <TextField name="title" label="Title*" />
              </Col>
            </Row>
            <Row>
              <Col md={12} sm={12} className="mb--6">
                <Select
                  name="role"
                  label="Role"
                  placeholder="select member role"
                  options={roles?.map(({ id, name }) => ({
                    value: id,
                    label: name
                  }))}
                />
              </Col>
            </Row>
            {(values.role === 7 || values.role === 10) && (
              <Row>
                <Col md={12} sm={12} className="mb--6">
                  <Select
                    name="firm"
                    label="Choose tax firm*"
                    options={firms?.map(({ id, name }) => ({
                      value: id,
                      label: name
                    }))}
                  />
                </Col>
              </Row>
            )}
            <Row>
              <Col md={12} className="mb--6">
                <TextField name="hubspot_id" label="Hubspot ID" />
              </Col>
            </Row>
            <Row>
              <Col md={12} className="mb--6">
                <Select
                  name="role_group"
                  label="Member group"
                  placeholder="select member group"
                  options={groups?.map(({ id, name }) => ({
                    value: id,
                    label: name
                  }))}
                />
              </Col>
            </Row>

            <Row>
              <Col md={12}>
                <label className="cs-label">Avatar</label>
                <UploadField
                  key="avatar"
                  name="avatar"
                  onError={() => {
                    toast.error('Failed to upload file.');
                  }}
                  onDrop={(files) => {
                    setFieldValue('avatar', files[0]);
                  }}
                  isDropbox={true}
                  className="cs-dropbox-landscape"
                >
                  {(props) => {
                    const file = values.avatar;
                    const url =
                      file instanceof File
                        ? URL.createObjectURL(file)
                        : member?.avatar ?? '';

                    const hideDropbox = !!member?.avatar || !!values.avatar;

                    return (
                      <>
                        {!hideDropbox && <Dropbox state={props} />}

                        {url && (
                          <div className="cs-dropbox-image">
                            <img src={url} alt="" />
                            <button
                              className="text-purple-500 fw--500 mt--2"
                              onClick={() => props.open()}
                              type="button"
                            >
                              Change logo
                            </button>
                          </div>
                        )}
                      </>
                    );
                  }}
                </UploadField>
              </Col>
            </Row>

            <Row>
              <Col md={12} className="mt--6 d--f f--ac f--jb">
                <Button
                  variant="quaternary"
                  className="w--100 mr--2"
                  type="button"
                  onClick={() => setOpen(false)}
                >
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  className="w--100 ml--2"
                  disabled={!isSubmitting && isValid ? false : true}
                  type="submit"
                >
                  Update
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default EditMemberModal;
