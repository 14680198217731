import { FC, HTMLAttributes } from 'react';
import { ErrorMessage, FieldHookConfig, useField } from 'formik';
import { DatePicker } from 'antd';
import type { FieldColAlignment } from '../types';
import style from './date-field.module.scss';
import dayjs from 'dayjs';
import cx from 'classnames';
import './date-field.scss';

type AllowedValue = string | number | string[] | undefined;

type DateFieldProps = FieldHookConfig<AllowedValue> &
  Omit<HTMLAttributes<HTMLInputElement>, 'type'> &
  FieldColAlignment & {
    label?: string;
    minDate?: Date;
    maxDate?: Date;
  };

const DateField: FC<DateFieldProps> = ({
  label,
  left,
  right,
  middle,
  minDate,
  maxDate,
  ...props
}) => {
  const [{ value, onChange, name }, { error, touched }, { setTouched }] =
    useField({
      ...props
    });

  const date = value ? dayjs(value as string) : null;

  const disabledDate = (current: dayjs.Dayjs) => {
    let isLessThanMin = false;
    let isMoreThanMax = false;
    if (minDate) {
      isLessThanMin = current.isBefore(dayjs(minDate));
    }
    if (maxDate) {
      isMoreThanMax = current.isAfter(dayjs(maxDate));
    }
    return isLessThanMin || isMoreThanMax;
  };

  return (
    <div
      className={cx(
        style.wrapper,
        props.disabled ? style.disabled : '',
        'cs-antd-datepicker',
        left ? 'field-left' : '',
        right ? 'field-right' : '',
        middle ? 'field-middle' : ''
      )}
    >
      {label && <label htmlFor={props.id ?? props.name}>{label}</label>}
      <DatePicker
        disabledDate={disabledDate}
        status={touched && error ? 'error' : ''}
        value={date}
        format="YYYY-MM-DD"
        placeholder="YYYY-MM-DD"
        disabled={props.disabled}
        defaultPickerValue={dayjs(minDate)}
        onChange={(_, dateString) => {
          const event = {
            target: {
              name: name,
              value: dateString
            }
          };
          onChange(event);
        }}
        onBlur={() => {
          setTouched(true);
        }}
      />
      <span className={style.error}>
        <ErrorMessage name={props.name} />
      </span>
    </div>
  );
};

export default DateField;
