import { FC } from 'react';
import { SVGIconProps } from './types';

const SVGIconAllPPP: FC<SVGIconProps> = ({
  width = 20,
  height = 21,
  stroke = 'white'
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.8889 7.75845V6.20289C13.8889 5.34378 13.1924 4.64734 12.3333 4.64734H4.55556C3.69645 4.64734 3 5.34378 3 6.20289V10.8696C3 11.7287 3.69645 12.4251 4.55556 12.4251H6.11111M7.66667 15.5362H15.4444C16.3036 15.5362 17 14.8398 17 13.9807V9.31401C17 8.4549 16.3036 7.75845 15.4444 7.75845H7.66667C6.80756 7.75845 6.11111 8.4549 6.11111 9.31401V13.9807C6.11111 14.8398 6.80756 15.5362 7.66667 15.5362ZM13.1111 11.6473C13.1111 12.5064 12.4147 13.2029 11.5556 13.2029C10.6964 13.2029 10 12.5064 10 11.6473C10 10.7882 10.6964 10.0918 11.5556 10.0918C12.4147 10.0918 13.1111 10.7882 13.1111 11.6473Z"
      stroke={stroke}
      strokeWidth="1.67"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SVGIconAllPPP;
