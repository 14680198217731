import { FC } from 'react';
import { SVGIconProps } from './types';

const SVGIconZoomIn: FC<SVGIconProps> = ({
    width = 12,
    height = 12,
    fill = "#2E2E2E",
    onClick
}) => (
    <svg
      viewBox="0 0 12 12"
      width={width}
      height={height}
      onClick={onClick}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.99844 0.400391C6.617 0.400391 7.11844 0.901832 7.11844 1.52039V4.88039L10.4784 4.88039C11.097 4.88039 11.5984 5.38183 11.5984 6.00039C11.5984 6.61895 11.097 7.12039 10.4784 7.12039H7.11844V10.4804C7.11844 11.099 6.617 11.6004 5.99844 11.6004C5.37988 11.6004 4.87844 11.099 4.87844 10.4804V7.12039H1.51844C0.899878 7.12039 0.398438 6.61895 0.398438 6.00039C0.398438 5.38183 0.899879 4.88039 1.51844 4.88039L4.87844 4.88039V1.52039C4.87844 0.901832 5.37988 0.400391 5.99844 0.400391Z"
            fill={fill}
        />
    </svg>
);

export default SVGIconZoomIn;
