import { FC } from 'react';
import style from './loader.module.scss';
import cx from 'classnames';

type LoaderProps = {
  inContext?: boolean;
  size?: 'lg' | 'md' | 'sm';
};

const Loader: FC<LoaderProps> = ({ inContext = false, size = 'lg' }) => {
  return (
    <div
      className={cx(style.wrapper, style[size], inContext ? style.contain : '')}
    >
      <div className={style.border}>
        <div className={style.core}></div>
      </div>
    </div>
  );
};

export default Loader;
