import { FC } from 'react';
import { SVGIconProps } from './types';

const SVGIconPhone: FC<SVGIconProps> = ({
  width = 16,
  height = 16,
  stroke = '#5514E6',
  strokeWidth = 1.67
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.40039 2.64447C1.40039 1.95718 1.95755 1.40002 2.64484 1.40002H4.68525C4.95307 1.40002 5.19085 1.5714 5.27554 1.82548L6.20752 4.6214C6.30544 4.91516 6.17245 5.23622 5.89549 5.3747L4.49097 6.07696C5.17681 7.59812 6.4023 8.8236 7.92346 9.50944L8.62572 8.10492C8.7642 7.82797 9.08526 7.69498 9.37901 7.7929L12.1749 8.72487C12.429 8.80956 12.6004 9.04734 12.6004 9.31516V11.3556C12.6004 12.0429 12.0432 12.6 11.3559 12.6H10.7337C5.57907 12.6 1.40039 8.42135 1.40039 3.26669V2.64447Z"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SVGIconPhone;
