import { FC } from 'react';
import { SVGIconProps } from './types';

const SVGIconUploadCloud: FC<SVGIconProps> = ({
  width = 22,
  height = 25,
  stroke = '#2E2E2E',
  strokeWidth = '1.67'
}) => (
  <svg
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.39992 16.9808C2.92569 16.9808 0.919922 14.975 0.919922 12.5008C0.919922 10.3649 2.41463 8.5781 4.41511 8.12937C4.32661 7.734 4.27992 7.32284 4.27992 6.90078C4.27992 3.80799 6.78713 1.30078 9.87992 1.30078C12.5895 1.30078 14.8497 3.22522 15.3681 5.78188C15.4053 5.78115 15.4426 5.78078 15.4799 5.78078C18.5727 5.78078 21.0799 8.28799 21.0799 11.3808C21.0799 14.09 19.156 16.3499 16.5999 16.8688M14.3599 13.6208L10.9999 10.2608M10.9999 10.2608L7.63992 13.6208M10.9999 10.2608L10.9999 23.7008"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SVGIconUploadCloud;
