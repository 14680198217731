import { FC } from 'react';
import { SVGIconProps } from './types';

const SVGIconFileController: FC<SVGIconProps> = ({
  width = 20,
  height = 25,
  stroke = 'black',
  strokeWidth = 1.67
}) => (
  <svg width={width} height={height} viewBox="0 0 20 25" fill="none">
    <path
      d="M6.2666 18.7222V16.2333M9.99993 18.7222V13.7444M13.7333 18.7222V11.2555M16.2222 23.7H3.77771C2.40313 23.7 1.28882 22.5857 1.28882 21.2111V3.78888C1.28882 2.4143 2.40313 1.29999 3.77771 1.29999H10.7289C11.059 1.29999 11.3755 1.4311 11.6089 1.66448L18.3466 8.40217C18.5799 8.63554 18.711 8.95207 18.711 9.28212V21.2111C18.711 22.5857 17.5967 23.7 16.2222 23.7Z"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SVGIconFileController;
