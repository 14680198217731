import { FC } from 'react';
import { SVGIconProps } from './types';

const SVGIconIR: FC<SVGIconProps> = ({
  width = 18,
  height = 20,
  fill = '#11042E',
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 18 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
        d="M6.72222 0.0287047L1.23849 3.51633C0.401504 3.99713 0.769491 4.03301 1.26736 4.47076C2.78981 5.45389 4.52152 6.53032 5.51003 7.08288C6.97476 7.91532 7.00362 8.0732 7.00362 7.08288L6.98198 5.8127C6.98198 5.51848 7.40047 5.29602 7.76124 5.45389L13.0285 7.85074C13.7428 8.23107 13.8222 8.86975 12.8409 9.51561L10.9216 10.7858C9.86094 11.4675 8.74254 12.0488 7.57364 12.5224C7.38604 12.587 7.18401 12.4937 7.11907 12.3071C7.10464 12.2641 7.09742 12.2139 7.09742 12.1636L7.19844 10.9939C7.19844 10.9939 3.56186 10.5203 1.05089 8.95587V20H6.76551V15.7374C6.76551 15.7374 7.96328 15.8809 8.74254 15.4503C9.66612 17.108 11.3762 19.9928 11.3762 19.9928H17.9999L14.0459 14.7327C14.0459 14.7327 17.3361 12.6229 17.4732 8.66882C17.5959 5.0592 15.4818 3.0714 12.2853 2.36814C10.4021 1.95192 6.99641 1.94474 6.99641 1.94474V0.179404C6.99641 0.0789379 6.91704 0 6.82324 0C6.78716 0 6.7583 0.0143524 6.72222 0.0287047Z"
        fill={fill}
    />
  </svg>
);

export default SVGIconIR;
