import { FC } from 'react';
import { SVGIconProps } from './types';

const SVGIconNotes: FC<SVGIconProps> = ({
  width = 14,
  height = 16,
  stroke = 'black',
  strokeWidth = 1.67
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 14 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      stroke={stroke}
      strokeWidth={strokeWidth}
      d="M4.66678 8.0918H9.33344M4.66678 11.2029H9.33344M10.889 15.0918H3.11122C2.25211 15.0918 1.55566 14.3954 1.55566 13.5362V2.64735C1.55566 1.78824 2.25211 1.0918 3.11122 1.0918H7.45572C7.662 1.0918 7.85983 1.17374 8.00569 1.3196L12.2168 5.53066C12.3626 5.67652 12.4446 5.87435 12.4446 6.08063V13.5362C12.4446 14.3954 11.7481 15.0918 10.889 15.0918Z"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SVGIconNotes;
