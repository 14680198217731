import {
  AffiliateContract,
  AffiliatesFeeTypes
} from 'api/services/super-admin/interfaces';
import style from './fee-card.module.scss';
import { FC } from 'react';
import { SVGIcon } from 'components/icons';
import cx from 'classnames';
import { checkDateStatus } from 'helpers/utils';
import { formatCurrency } from 'helpers/utils';

interface FeeCardProps {
  data: AffiliateContract;
  onEdit: () => void;
  onDelete: (index: number) => void;
  index: number;
}

const FeeCard: FC<FeeCardProps> = ({ data, onEdit, onDelete, index }) => {
  const status = data.start_date
    ? data.perpetuity
      ? checkDateStatus(data.start_date)
      : checkDateStatus(data.start_date, data.end_date)
    : '';

  const feeLabel = (fee: string) => {
    switch(fee) {
      case 'percentage':
        return 'Percentage fee';
      case 'flat':
        return 'Upfront flat fee';
      case 'hybrid':
        return 'Hybrid fee';
    }
  }

  return (
    <div className={style.wrapper}>
      <div>
        <p className="fw--500">{feeLabel(data.fee.type)}</p>
        <p className="fs--1 text-neutral-500">
          {data.fee.type === AffiliatesFeeTypes.PERCENTAGE &&
          data.fee.percentage
            ? data.fee.percentage * 100 + '%'
            : ''}
          {data.fee.type === AffiliatesFeeTypes.FLAT_FEE && formatCurrency(data.fee.flat_fee)}
          {data.fee.type === AffiliatesFeeTypes.HYBRID && (
            <>
              {`${formatCurrency(data.fee.flat_fee)} / ${
                data.fee.percentage ? data.fee.percentage * 100 + '%' : ''
              }`}
              {data.fee.offset ? ' (offsetting fees)' : ''}
            </>
          )}
        </p>
      </div>
      <div className={style.action}>
        <div className={cx(`cs-tab--${status}`, 'mr--5 ttc')}>{status}</div>
        <button
          className={cx(style.edit, 'mr--2')}
          onClick={() => onEdit()}
          type="button"
        >
          <SVGIcon variant="edit" />
        </button>
        <button
          className={style.delete}
          onClick={() => onDelete(index)}
          type="button"
        >
          <SVGIcon variant="trash" />
        </button>
      </div>
    </div>
  );
};

export default FeeCard;
