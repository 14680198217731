import client from 'api/client';
import {
  AffiliateRequest,
  AffiliatesResponse,
  AllAplicationsResponse,
  AssigmentsResponse,
  AssignApplicationRequest,
  AssignErcaApplicationRequest,
  ErcaAplicationTabResponse,
  ManagerMembersRequest,
  Member,
  MemberGroupRequest,
  MemberGroupResponse,
  MemberManagersResponse,
  MembersRequest,
  MembersResponse,
  SingleAffiliateResponse,
  UpdateAffiliateRequest
} from './interfaces';
import { toFormData } from 'axios';
import qs from 'qs';

//** Applications */

export const getAllApplications = async (
  page: number,
  pageSize: number,
  search: string
): Promise<AllAplicationsResponse> => {
  const params = qs.stringify({
    page,
    page_size: pageSize,
    search: search
  });
  return (await client.get(`super-admin/applications/?${params}`)).data;
};
export const getAssignments = async (id: number): Promise<AssigmentsResponse> =>
  (await client.get(`super-admin/applications/assignments?deal_id=${id}`))
    ?.data;

export const assignApplication = async (payload: AssignApplicationRequest) =>
  await client.post(`super-admin/applications/`, payload);

//** Members **/
export const getMembers = async (role?: number): Promise<MembersResponse[]> => {
  const search = role ? `?search=${role}` : '';
  return (await client.get(`super-admin/members/${search}`))?.data;
};
export const getSingleMember = async (id: number): Promise<MembersResponse> => {
  return (await client.get(`super-admin/members/${id}/`))?.data;
};
export const addMember = async (payload: MembersRequest) => {
  return await client.post(
    `super-admin/members/`,
    toFormData({
      ...payload,
      user: JSON.stringify(payload.user)
    }),
    {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
  );
};
export const updateMember = async (id: number, payload: MembersRequest) => {
  return await client.patch(
    `super-admin/members/${id}/`,
    toFormData({
      ...payload,
      user: JSON.stringify(payload.user)
    }),
    {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
  );
};

export const resetPassword = async (id: number, password: string) => {
  return await client.patch(`super-admin/members/${id}/reset_password/`, {
    password: password
  });
};

//** Managers **//

export const getManagers = async (): Promise<MemberManagersResponse[]> =>
  (await client.get(`super-admin/managers/`))?.data;
export const getManagerMembers = async (
  id: number
): Promise<MemberManagersResponse> =>
  (await client.get(`super-admin/managers/${id}/`))?.data;
export const createManagerMembers = async (payload: ManagerMembersRequest) => {
  return client.post(`super-admin/managers/`, payload);
};
export const removeManagerMember = async (
  manager_id: number,
  member_id: number
) => {
  return client.delete(
    `super-admin/managers/${manager_id}/?member_id=${member_id}`
  );
};
//** ERCA Managers **//

export const getErcaManagers = async (
  slug: string
): Promise<ErcaAplicationTabResponse> =>
  (await client.get(`super-admin/erca-applications/?tab=${slug}`))?.data;

export const assignErcaManagers = async (
  payload: AssignErcaApplicationRequest
): Promise<ErcaAplicationTabResponse> =>
  await client.post(`super-admin/erca-applications/`, payload);

//** Groups **/

export const getAllGroups = async (): Promise<MemberGroupResponse[]> =>
  (await client.get(`super-admin/groups/`))?.data;

export const getAllMembers = async (role?: number): Promise<Member[]> => {
  const query = role ? `?role=${role}` : '';
  return (await client.get(`super-admin/all-members/${query}`))?.data;
}

export const getAllMembersGroups = async (): Promise<Member[]> =>
  (await client.get(`super-admin/members/groups/`))?.data;

export const getAllMembersRoles = async (): Promise<Member[]> =>
  (await client.get(`super-admin/members/roles/`))?.data;

export const getAllAffiliates = async (): Promise<AffiliatesResponse[]> =>
  (await client.get(`super-admin/affiliates/`))?.data;

export const getSingleAffiliate = async (
  id: string
): Promise<SingleAffiliateResponse> =>
  (await client.get(`super-admin/affiliates/${id}/`))?.data;

export const addNewAffiliate = async (payload: AffiliateRequest) => {
  const newContracts = payload.contracts.map(({ perpetuity, ...rest }) => rest);
  const newPayload = {
    ...payload,
    contracts: JSON.stringify(newContracts)
  };
  return client.post(`super-admin/affiliates/`, toFormData(newPayload), {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};

export const addMemberGroup = async (payload: MemberGroupRequest) => {
  return client.post(`super-admin/groups/`, payload);
};

export const updateAffiliate = async (
  id: string,
  payload: UpdateAffiliateRequest
) => {
  const newContracts = payload.contracts.map(({ perpetuity, ...rest }) => rest);
  const newPayload = {
    ...payload,
    contracts: JSON.stringify(newContracts)
  };
  return client.patch(`super-admin/affiliates/${id}/`, toFormData(newPayload), {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};

export const deleteAffiliate = async (id: string) =>
  (await client.delete(`super-admin/affiliates/${id}/`))?.data;
