import { FC } from 'react';
import { SVGIconProps } from './types';

const SVGIconDropdown: FC<SVGIconProps> = ({ width = 28, height = 28 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.0004 2.3999C7.59389 2.3999 2.40039 7.5934 2.40039 13.9999C2.40039 16.5608 3.23024 18.9279 4.6357 20.8469C7.4462 19.4101 10.63 18.5999 14.0004 18.5999C17.3708 18.5999 20.5546 19.4101 23.3651 20.8469C24.7705 18.9279 25.6004 16.5608 25.6004 13.9999C25.6004 7.5934 20.4069 2.3999 14.0004 2.3999ZM24.4061 22.7572C26.3994 20.3911 27.6004 17.3358 27.6004 13.9999C27.6004 6.48883 21.5115 0.399902 14.0004 0.399902C6.48932 0.399902 0.400391 6.48883 0.400391 13.9999C0.400391 17.3358 1.60144 20.3912 3.59471 22.7572C3.60251 22.7668 3.61048 22.7762 3.61859 22.7855C6.11327 25.7304 9.83851 27.5999 14.0004 27.5999C18.1623 27.5999 21.8875 25.7305 24.3822 22.7855C24.3903 22.7762 24.3983 22.7668 24.4061 22.7572ZM21.9981 22.4021C19.5763 21.2468 16.865 20.5999 14.0004 20.5999C11.1358 20.5999 8.4245 21.2468 6.00267 22.4021C8.08372 24.3836 10.9 25.5999 14.0004 25.5999C17.1008 25.5999 19.9171 24.3836 21.9981 22.4021ZM14.0004 7.9999C12.2331 7.9999 10.8004 9.43259 10.8004 11.1999C10.8004 12.9672 12.2331 14.3999 14.0004 14.3999C15.7677 14.3999 17.2004 12.9672 17.2004 11.1999C17.2004 9.43259 15.7677 7.9999 14.0004 7.9999ZM8.80039 11.1999C8.80039 8.32802 11.1285 5.9999 14.0004 5.9999C16.8723 5.9999 19.2004 8.32802 19.2004 11.1999C19.2004 14.0718 16.8723 16.3999 14.0004 16.3999C11.1285 16.3999 8.80039 14.0718 8.80039 11.1999Z"
      fill="url(#paint0_linear_381_21320)"
    ></path>
    <defs>
      <linearGradient
        id="paint0_linear_381_21320"
        x1="0.400391"
        y1="27.5999"
        x2="27.6004"
        y2="0.399902"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#285BF2"></stop>
        <stop offset="1" stopColor="#CD29FF"></stop>
      </linearGradient>
    </defs>
  </svg>
);

export default SVGIconDropdown;
