import { FC, useState } from 'react';
import { AxiosError, AxiosResponse } from 'axios';
import {
  useDropzone,
  DropzoneProps,
  FileRejection,
  DropEvent,
  DropzoneState
} from 'react-dropzone';
import client from 'api/client';
import style from './upload-field.module.scss';
import cx from 'classnames';

export type UploadFieldState = Omit<
  DropzoneState,
  'getInputProps' | 'getRootProps'
> & {
  isLoading: boolean;
};

type UploadFieldProps = Omit<DropzoneProps, 'children'> & {
  name: string;
  url?: string;
  isDropbox?: boolean;
  onSuccess?: (response: AxiosResponse, files: File[]) => void;
  onFail?: (error: AxiosError) => void;
  children?: (state: UploadFieldState) => JSX.Element;
  data?: Record<string, any>;
  dropboxSize?: number;

  className?: string;
};

const allowedFiles = {
  'application/msword': ['.doc'],
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [
    '.docx'
  ],
  'application/vnd.ms-excel': ['.xls'],
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
    '.xlsx'
  ],
  'application/vnd.ms-excel.sheet.macroEnabled.12': ['.xlsm'],
  'text/csv': ['.csv'],
  'application/pdf': ['.pdf'],
  'image/png': ['.png'],
  'image/jpeg': ['.jpg'],
  'image/gif': ['.gif']
};

const UploadField: FC<UploadFieldProps> = ({
  name,
  url,
  onSuccess,
  onFail,
  onDrop,
  children,
  multiple = false,
  isDropbox = false,
  dropboxSize,
  data = {},
  ...props
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const onChange = (
    files: File[],
    rejections: FileRejection[],
    event: DropEvent
  ) => {
    onDrop?.(files, rejections, event);

    if (!url) {
      return;
    }

    setIsLoading(true);

    client
      .upload(
        url,
        {
          [name]: multiple ? files : files[0]
        },
        data
      )
      .then((response) => {
        onSuccess?.(response, files);
      })
      .catch((error) => {
        onFail?.(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const { getInputProps, getRootProps, ...state } = useDropzone({
    accept: allowedFiles,
    ...props,
    multiple,
    onDrop: onChange
  });
  return (
    <div
      className={cx(
        isDropbox ? style.wrapper : '',
        dropboxSize ? '' : style.dropbox,
        props.className
      )}
      {...(isDropbox && getRootProps())}
      style={dropboxSize ? { width: dropboxSize } : {}}
    >
      <input {...getInputProps()} />
      <>{children?.({ ...state, isLoading })}</>
    </div>
  );
};

export default UploadField;
