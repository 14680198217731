import React from 'react';

import style from './initials-avatar.module.scss';
import cx from 'classnames';

function getInitials(name: string): string {
  const [firstName, lastName] = name.split(' ');

  if (firstName && lastName) {
    return `${firstName.charAt(0)}${lastName.charAt(0)}`.toUpperCase();
  }

  return firstName.charAt(0).toUpperCase();
}

export interface InitialsAvatarProps {
  name: string;
  size?: string;
  no_border?: boolean;
}

const InitialsAvatar: React.FC<InitialsAvatarProps> = ({
  name,
  size,
  no_border
}) => {
  return (
    <>
      <div
        aria-label={name}
        role="img"
        className={cx(
          style.initialsAvatar,
          size === 'sm' ? style.small : '',
          size === 'mid' ? style.mid : '',
          size === 'md' ? style.medium : '',
          no_border === true ? style.noborder : ''
        )}
      >
        <div>{getInitials(name)}</div>
      </div>
    </>
  );
};

export default InitialsAvatar;
