import { FC } from 'react';
import { SVGIconProps } from './types';

const SVGIconBuilding: FC<SVGIconProps> = ({
  width = 16,
  height = 16,
  fill = 'none',
  stroke = 'black',
  strokeWidth = 1.87578
}) => (
  <svg
    width={width}
    height={height}
    fill={fill}
    viewBox="0 0 38 38"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M32.6111 36.5V5.38889C32.6111 3.24111 30.87 1.5 28.7222 1.5H9.27778C7.13 1.5 5.38889 3.24111 5.38889 5.38889V36.5M32.6111 36.5L36.5 36.4999M32.6111 36.5H22.8889M5.38889 36.5L1.5 36.4999M5.38889 36.5H15.1111M13.1667 9.27773H15.1111M13.1667 17.0555H15.1111M22.8889 9.27773H24.8333M22.8889 17.0555H24.8333M15.1111 36.5V26.7777C15.1111 25.7038 15.9817 24.8333 17.0556 24.8333H20.9444C22.0183 24.8333 22.8889 25.7038 22.8889 26.7777V36.5M15.1111 36.5H22.8889"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SVGIconBuilding;
