import { FC } from 'react';
import { SVGIconProps } from './types';

const SVGIconOffice: FC<SVGIconProps> = ({
  width = 20,
  height = 18,
  stroke = '#2E2E2E'
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
        d="M15.4444 14.6485V3.92535C15.4444 3.18507 14.748 2.58496 13.8889 2.58496H6.11111C5.252 2.58496 4.55556 3.18507 4.55556 3.92535V14.6485M15.4444 14.6485L17 14.6485M15.4444 14.6485H11.5556M4.55556 14.6485L3 14.6485M4.55556 14.6485H8.44444M7.66667 5.26573H8.44444M7.66667 7.94651H8.44444M11.5556 5.26573H12.3333M11.5556 7.94651H12.3333M8.44444 14.6485V11.2975C8.44444 10.9274 8.79267 10.6273 9.22222 10.6273H10.7778C11.2073 10.6273 11.5556 10.9274 11.5556 11.2975V14.6485M8.44444 14.6485H11.5556"
        stroke={stroke}
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
    />
  </svg>
);

export default SVGIconOffice;

