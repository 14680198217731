
import { useQuery } from '@tanstack/react-query';
import { getTaxDocumentZip } from 'api/services/common-dashboards/documents/requests';
import { toast } from 'react-toastify';
import { useDownloadFile } from './useDownloadFile';



export const useDownloadAllDocuments = (applicationId: string) => {

  const { mutate: fileDownload } = useDownloadFile();

  const downloadQuery = useQuery(['get-documents-zip', applicationId], () => getTaxDocumentZip(applicationId), {
    enabled: false,
    onSuccess: (zipData) => {
      toast.dismiss();
      toast.success('Zip file created.', { delay: 500 });

      fileDownload({
        filename: 'all_tax_documents.zip' ?? '',
        url: zipData.file_url ?? ''
      });
    },
    onError: () => {
      toast.dismiss();
      toast.error('Unable to create zip file. Please try again');
    },
  });

  const handleDownloadClick = () => {
    downloadQuery.refetch();
    toast.loading('Creating zip file...');
  };

  return {
    handleDownloadClick,
    downloadQuery,
  };
};

export default useDownloadAllDocuments;