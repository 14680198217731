import { FC } from 'react';
import { SVGIconProps } from './types';

const SVGIconCustomerProfile: FC<SVGIconProps> = ({
  width = 20,
  height = 21,
  stroke = 'white'
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.6497 14.6058C6.22985 13.7127 8.0554 13.2029 10 13.2029C11.9446 13.2029 13.7702 13.7127 15.3503 14.6058M12.3333 8.53624C12.3333 9.82491 11.2887 10.8696 10 10.8696C8.71134 10.8696 7.66667 9.82491 7.66667 8.53624C7.66667 7.24758 8.71134 6.20291 10 6.20291C11.2887 6.20291 12.3333 7.24758 12.3333 8.53624ZM17 10.0918C17 13.9578 13.866 17.0918 10 17.0918C6.13401 17.0918 3 13.9578 3 10.0918C3 6.2258 6.13401 3.0918 10 3.0918C13.866 3.0918 17 6.2258 17 10.0918Z"
      stroke={stroke}
      strokeWidth="1.67"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SVGIconCustomerProfile;
