import { FC } from 'react';
import { SVGIconProps } from './types';

const SVGIconConsentForm: FC<SVGIconProps> = ({
  width = 17,
  height = 16,
  stroke = 'white'
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.75 2.75H3.25C2.2835 2.75 1.5 3.5335 1.5 4.5V13.25C1.5 14.2165 2.2835 15 3.25 15H12C12.9665 15 13.75 14.2165 13.75 13.25V9.75M10.25 1H15.5M15.5 1V6.25M15.5 1L6.75 9.75"
      stroke={stroke}
      strokeWidth="1.67"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SVGIconConsentForm;
