import { FC, HTMLAttributes } from 'react';
import { ErrorMessage, FieldHookConfig, useField } from 'formik';
import style from './radio.module.scss';
import cx from 'classnames';

type AllowedValue = string | number | string[] | undefined;

type RadioProps = FieldHookConfig<AllowedValue> &
  HTMLAttributes<HTMLInputElement> & {
    label?: string;
    description?: string;
    variant?: string;
    useGroupError?: boolean;
    customOnChange?: (value: any, callback: (value: any) => void) => void;
  };

const Radio: FC<RadioProps> = ({
  label,
  description,
  variant,
  useGroupError = false,
  disabled,
  customOnChange,
  ...props
}) => {
  // eslint-disable-next-line no-empty-pattern
  const [field, {}, { setValue }] = useField({ ...props, type: 'radio' });

  const handleClickContainer = (newValue: AllowedValue) => {
    if (variant !== 'block') return;
    setValue(newValue);
  };

  const generic = props as HTMLAttributes<HTMLInputElement>;

  const overrideProps = customOnChange ? { onChange: async (value: any) => {
    customOnChange(value, field.onChange);
  } } : {};

  return (
    <div
      className={cx(style.wrapper, disabled ? style.disabled : '')}
      onClick={() => handleClickContainer(field.value)}
    >
      <input {...generic} {...field} {...overrideProps} type="radio" disabled={disabled} />
      {description ? (
        <div>
          {label && (
            <label htmlFor={props.id ?? props.name} className="mb--2 fw--500">
              {label}
            </label>
          )}
          {description && <p>{description}</p>}
        </div>
      ) : (
        <>{label && <label htmlFor={props.id ?? props.name}>{label}</label>}</>
      )}

      {!useGroupError && (
        <span className={style.error}>
          <ErrorMessage name={props.name} />
        </span>
      )}
    </div>
  );
};

export default Radio;
