import { FC } from 'react';
import { ErrorMessage, FieldHookConfig, useField } from 'formik';
import { Radio } from '../';
import style from './radio-group.module.scss';
import cx from 'classnames';

type AllowedValue = string | number | string[] | undefined;

interface Option {
  label: string;
  value: AllowedValue;
  description?: string;
}

type RadioGroupProps = FieldHookConfig<AllowedValue[]> & {
  label?: string;
  options?: Option[];
  variant?: 'inline' | 'list' | 'block';
  useGroupError?: boolean;
  customOnChange?: (value: any, callback: (value: any) => void) => Promise<void>;
};

const RadioGroup: FC<RadioGroupProps> = ({
  label,
  options,
  variant = 'list',
  customOnChange,
  useGroupError = true,
  disabled,
  ...props
}) => {
  // eslint-disable-next-line no-empty-pattern
  const [field] = useField({ ...props });

  return (
    <div className={cx(style.wrapper, props.className)}>
      {label && <label>{label}</label>}
      <div className={cx(style.container, style[variant])}>
        {options?.map(({ label, value, description }) => (
          <Radio
            key={`${props.name}-${value}`}
            {...field}
            id={`${props.name}-${value}`}
            customOnChange={customOnChange}
            value={value}
            label={label}
            checked={field.value === value}
            useGroupError
            disabled={disabled}
            description={description}
            variant={variant}
          />
        ))}
      </div>
      <span className={style.error}>
        <ErrorMessage name={props.name} />
      </span>
    </div>
  );
};

export default RadioGroup;
