import { FC } from 'react';
import { SVGIconProps } from './types';

const SVGIconUpload: FC<SVGIconProps> = ({
  width = 10,
  height = 12,
  fill = '#5514E6',
  strokeWidth = 1.67
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 10 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.101562 10.8999C0.101562 10.5133 0.414963 10.1999 0.801563 10.1999H9.20156C9.58816 10.1999 9.90156 10.5133 9.90156 10.8999C9.90156 11.2865 9.58816 11.5999 9.20156 11.5999H0.801563C0.414963 11.5999 0.101563 11.2865 0.101562 10.8999ZM2.40659 3.69488C2.13322 3.42151 2.13322 2.97829 2.40659 2.70493L4.50659 0.604928C4.63786 0.473652 4.81591 0.399902 5.00156 0.399902C5.18721 0.399902 5.36526 0.473652 5.49654 0.604928L7.59654 2.70493C7.8699 2.97829 7.86991 3.42151 7.59654 3.69488C7.32317 3.96824 6.87996 3.96824 6.60659 3.69488L5.70156 2.78985L5.70156 8.0999C5.70156 8.4865 5.38816 8.7999 5.00156 8.7999C4.61496 8.7999 4.30156 8.4865 4.30156 8.0999V2.78985L3.39654 3.69488C3.12317 3.96824 2.67996 3.96824 2.40659 3.69488Z"
      fill={fill}
    />
  </svg>
);

export default SVGIconUpload;
