import { FC } from 'react';
import { SVGIconProps } from './types';

const SVGIconBars: FC<SVGIconProps> = ({
  width = 19,
  height = 18,
  fill = 'black'
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 19 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill={fill}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.4718 17.3218H13.5639C13.1607 17.3218 12.8307 16.9919 12.8307 16.5886V0.824988C12.8307 0.421733 13.1607 0.0917969 13.5639 0.0917969H17.4718C17.8751 0.0917969 18.205 0.421733 18.205 0.824988V16.5886C18.205 16.9919 17.8751 17.3218 17.4718 17.3218ZM14.2971 15.8554H16.7386V1.55818H14.2971V15.8554Z"
    />
    <path
      fill={fill}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.0565 17.3218H7.14855C6.74529 17.3218 6.41536 16.9919 6.41536 16.5886V9.86524C6.41536 9.46198 6.74529 9.13205 7.14855 9.13205H11.0565C11.4597 9.13205 11.7897 9.46198 11.7897 9.86524V16.5886C11.7897 16.9919 11.4597 17.3218 11.0565 17.3218ZM7.88174 15.8554H10.3233V10.5984H7.88174V15.8554Z"
    />
    <path
      fill={fill}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.733191 17.3218H4.6411C5.04436 17.3218 5.37429 16.9919 5.37429 16.5886V6.56588C5.37429 6.16262 5.04436 5.83268 4.6411 5.83268H0.733191C0.329936 5.83268 0 6.16262 0 6.56588V16.5886C0 16.9919 0.329936 17.3218 0.733191 17.3218ZM3.90791 15.8554H1.46638V7.29907H3.90791V15.8554Z"
    />
  </svg>
);

export default SVGIconBars;
