import React, { FC } from 'react';
import { Divider } from 'antd';
import style from './separator.module.scss';

const Separator: FC = () => {
  return (
    <Divider className={style.separator} />
  )
}

export default Separator;
