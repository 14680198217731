import { FC } from 'react';
import { SVGIconProps } from './types';

const SVGIconXFiles: FC<SVGIconProps> = ({
  width = '16',
  height = '12',
  stroke = '#2E2E2E',
  strokeWidth = 1.67,
  fill='none',
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 12"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 4.67947H15M1 7.79058H15M8 4.67947V10.9017M2.55556 10.9017H13.4444C14.3036 10.9017 15 10.2052 15 9.34614V3.12391C15 2.26481 14.3036 1.56836 13.4444 1.56836H2.55556C1.69645 1.56836 1 2.26481 1 3.12391V9.34614C1 10.2052 1.69645 10.9017 2.55556 10.9017Z"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SVGIconXFiles;
