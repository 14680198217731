import Loader from 'components/atomic/Loader/Loader';
import { FC, useState, useRef, useEffect } from 'react';
import style from './spreadsheet-viewer.module.scss';

interface SpreadsheetViewerProps {
  url: string;
  label?: string;
}

const SpreadsheetViewer: FC<SpreadsheetViewerProps> = ({ url, label }) => {
  const [isLoading, setIsLoading] = useState(true);
  const container = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!container.current) {
      return;
    }

    const $container = $(container.current);
    const spreadsheet = $container
      .kendoSpreadsheet({
        readOnly: true,
        toolbar: false,
        resizable: false,
        excel: {
          proxyURL: 'https://demos.telerik.com/kendo-ui/service/export'
        }
      })
      .data('kendoSpreadsheet') as kendo.spreadsheet.Spreadsheet;

    const request = new XMLHttpRequest();

    request.responseType = 'blob';
    request.onload = () => {
      const workbook = new kendo.spreadsheet.Workbook({});

      workbook.fromFile(request.response).then(function () {
        setIsLoading(false);
        spreadsheet.fromJSON(workbook.toJSON());
      });

      spreadsheet.one('render', ({ sender }) => {
        sender.sheets().forEach((sheet) => {
          sheet
            .range(0, 0, sheet._rows._count, sheet._columns._count)
            .enable(false);
        });
      });
    };

    request.open('GET', url, true);
    request.send();

    return () => {
      setIsLoading(false);
      request.abort();
      spreadsheet.destroy();
      $container.empty();
    };
  }, [url]);

  return (
    <div className={style.wrapper}>
      <div className={style.info}>
        <p className={style.name}>{label}</p>
        <div className={style.controlls}></div>
      </div>
      {isLoading && <Loader />}
      <div className={style.previewContainer}>
        <div ref={container}></div>
      </div>
    </div>
  );
};

export default SpreadsheetViewer;
