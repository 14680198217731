import { Collection } from './Collection';
import { ArrayCollection } from './ArrayCollection';
import { RecordCollection } from './RecordCollection';
import { Collectable, Key } from './types';

export const collect = <Type>(
  collectable: Collectable<Type>
): Collection<Type> => {
  if (!collectable) {
    return new ArrayCollection([]);
  }

  if (collectable instanceof Array) {
    return new ArrayCollection(collectable);
  }

  if (collectable instanceof Set) {
    return new ArrayCollection(Array.from(collectable));
  }

  if (collectable instanceof Map) {
    return new RecordCollection(Object.fromEntries(collectable));
  }

  if (collectable instanceof Object) {
    return new RecordCollection(collectable as Record<Key, Type>);
  }

  return new ArrayCollection([collectable]);
};
