import { FC } from 'react';
import { SVGIconProps } from './types';

const SVGIconCloseCircle: FC<SVGIconProps> = ({
  width = 14,
  height = 14,
  stroke = '#CC0000',
  strokeWidth = '1.67'
}) => (
  <svg
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.75595 8.24435L7.00039 6.9999M7.00039 6.9999L8.24484 5.75546M7.00039 6.9999L5.75595 5.75546M7.00039 6.9999L8.24484 8.24435M12.6004 6.9999C12.6004 10.0927 10.0932 12.5999 7.00039 12.5999C3.9076 12.5999 1.40039 10.0927 1.40039 6.9999C1.40039 3.90711 3.9076 1.3999 7.00039 1.3999C10.0932 1.3999 12.6004 3.90711 12.6004 6.9999Z"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SVGIconCloseCircle;
