import { FC } from 'react';
import { SVGIconProps } from './types';

const SVGIconEnvelope: FC<SVGIconProps> = ({
  width = 14,
  height = 12,
  stroke = '#5514E6',
  strokeWidth = 1.67
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 14 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.39844 3.5112L6.30814 6.78434C6.72615 7.06301 7.27072 7.06301 7.68873 6.78434L12.5984 3.5112M2.64288 10.3556H11.354C12.0413 10.3556 12.5984 9.79849 12.5984 9.1112V2.88898C12.5984 2.20169 12.0413 1.64453 11.354 1.64453H2.64288C1.95559 1.64453 1.39844 2.20169 1.39844 2.88898V9.1112C1.39844 9.79849 1.95559 10.3556 2.64288 10.3556Z"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SVGIconEnvelope;
