import { Collection } from './Collection';
import { arrayToRecord, getObjectValue } from './helpers';
import { RecordCollection } from './RecordCollection';
import { Callback, Collectable, Key } from './types';

export class ArrayCollection<Type extends Collectable>
  implements Collection<Type>
{
  protected items: Type[];

  public constructor(items: Type[]) {
    this.items = items;
  }

  public groupBy(
    groupBy: Key | Callback<Key, [Type, Key]>
  ): Collection<Type[]> {
    return new RecordCollection(arrayToRecord(this.items)).groupBy(groupBy);
  }

  public keyBy(keyBy: Key | Callback<Key, [Type, Key]>): Collection<Type> {
    return new RecordCollection(arrayToRecord<Type>(this.items)).keyBy(keyBy);
  }

  public map<TValue>(
    callback: Callback<TValue, [Type, Key]>
  ): Collection<TValue> {
    return new ArrayCollection(
      this.items.map((item, index) => callback(item, index))
    );
  }

  forEach<TValue>(callback: Callback<TValue, [Type, Key]>): void {
    this.items.forEach(callback);
  }

  public pluck<TValue>(pluckKey: Key): Collection<TValue> {
    return new ArrayCollection(
      this.items.map((item) => getObjectValue(item, pluckKey) as TValue)
    );
  }

  public toArray(): Type[] {
    return this.items.slice();
  }

  public toRecord(): Record<Key, Type> {
    return arrayToRecord<Type>(this.items);
  }
}
