import { FC } from 'react';
import { SVGIconProps } from './types';

const SVGIconQuestion: FC<SVGIconProps> = ({
  width = 17,
  height = 18,
  stroke = '#2E2E2E'
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 17 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path 
        fillRule="evenodd" 
        clipRule="evenodd" 
        fill="#171717"
        stroke={stroke}
        d="M16.9016 9.0001C16.9016 13.6393 13.1408 17.4001 8.50156 17.4001C3.86237 17.4001 0.101562 13.6393 0.101562 9.0001C0.101562 4.36091 3.86237 0.600098 8.50156 0.600098C13.1408 0.600098 16.9016 4.36091 16.9016 9.0001ZM8.50158 5.8501C8.11425 5.8501 7.77454 6.05927 7.59141 6.37586C7.30104 6.87782 6.65872 7.04935 6.15676 6.75898C5.65479 6.46861 5.48326 5.8263 5.77363 5.32434C6.3167 4.38554 7.33431 3.7501 8.50158 3.7501C10.2413 3.7501 11.6516 5.1604 11.6516 6.9001C11.6516 8.27163 10.775 9.43843 9.55158 9.87086V10.0501C9.55158 10.63 9.08148 11.1001 8.50158 11.1001C7.92168 11.1001 7.45158 10.63 7.45158 10.0501V9.0001C7.45158 8.4202 7.92168 7.9501 8.50158 7.9501C9.08148 7.9501 9.55158 7.48 9.55158 6.9001C9.55158 6.3202 9.08148 5.8501 8.50158 5.8501ZM8.50156 14.2501C9.08146 14.2501 9.55156 13.78 9.55156 13.2001C9.55156 12.6202 9.08146 12.1501 8.50156 12.1501C7.92166 12.1501 7.45156 12.6202 7.45156 13.2001C7.45156 13.78 7.92166 14.2501 8.50156 14.2501Z" 
    />
  </svg>
);

export default SVGIconQuestion;
