import { FC } from 'react';
import { SVGIconProps } from './types';

const SVGIconArrowUp: FC<SVGIconProps> = ({
  width = 24,
  height = 24,
  stroke = 'black',
  fill = 'none',
  strokeWidth = 1.67
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.0001 18.5329L20.4001 20.3996L12.0001 3.59961L3.6001 20.3996L12.0001 18.5329ZM12.0001 18.5329V11.0663"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SVGIconArrowUp;
