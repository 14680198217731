import { useQuery } from '@tanstack/react-query';
import { getUploadDates } from 'api/services/common-dashboards/form-steps/requests';

const useUploadQuarters = (session: string) => {
  const { data, isLoading } = useQuery(['upload-dates', session], () =>
    getUploadDates(session)
  );

  return {
    isQuartersLoading: isLoading,
    quarters: data?.upload_quarters ?? []
  };
};

export const useFinchUploadQuarters = (session: string) => {
  const { data, isLoading } = useQuery(['upload-dates', session], () =>
    getUploadDates(session)
  );

  return {
    isQuartersLoading: isLoading,
    quarters: data?.upload_quarters.filter(q => q.year !== 2019) ?? []
  };
};

export default useUploadQuarters;
