import {
  UseMutateFunction,
  useMutation,
  useQuery
} from '@tanstack/react-query';
import { ApplicationApprovalResponse } from 'api/services/account-manager/general/interfaces';
import {
  getApplicationApproval,
  postApplicationApproval
} from 'api/services/account-manager/general/requests';
import { useCallback, useMemo } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

export function useAccountManagerApplication(): {
  previousPage: () => void;
  nextPage: () => void;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
  approveApplication: UseMutateFunction<
    Partial<ApplicationApprovalResponse>,
    unknown,
    void,
    unknown
  >;
  refetchApproval: () => void;
  applicationId: string;
  approved: boolean;
  approvable: boolean;
} {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { applicationId = '' } = useParams();
  const { data: approvalData, refetch: refetchApproval } = useQuery(
    ['application-approval', applicationId],
    () => getApplicationApproval(applicationId)
  );

  const { mutate } = useMutation({
    mutationFn: () => postApplicationApproval(applicationId)
  });

  const approveApplication = async () => {
    mutate(undefined, {
      onSuccess: () => {
        toast.success('The application has been approved.');
        refetchApproval();
      },
      onError: (response) => {
        toast.error('There was an error approving the application!');
      }
    });
  };
  const pages = useMemo(
    () => [
      `/account-manager/${applicationId}/dashboard`,
      `/account-manager/${applicationId}/customer-profile`,
      `/account-manager/${applicationId}/company-profile/contract-signer`,
      `/account-manager/${applicationId}/company-profile/company-info`,
      `/account-manager/${applicationId}/company-profile/additional-info`,
      `/account-manager/${applicationId}/company-profile/peo`,
      `/account-manager/${applicationId}/company-profile/banking-info`,
      `/account-manager/${applicationId}/company-profile/employee-info`,
      `/account-manager/${applicationId}/covid-info`,
      `/account-manager/${applicationId}/ppp`,
      `/account-manager/${applicationId}/payroll-tax-returns`,
      `/account-manager/${applicationId}/payroll-wages`,
      `/account-manager/${applicationId}/benefits`,
      `/account-manager/${applicationId}/profit-and-loss`,
      `/account-manager/${applicationId}/bulk-uploads`,
      `/account-manager/${applicationId}/all-files`
    ],
    [applicationId]
  );

  const pageIndex = pages.indexOf(pathname);

  const updatePage = useCallback(
    (direction: -1 | 1) => {
      if (pageIndex === -1) {
        console.warn(
          `Failed to resolve ${
            direction > 0 ? 'next' : 'previous'
          } page for current path "${pathname}"`
        );

        return;
      }

      const page = pages[pageIndex + direction];

      page && navigate(page);
    },
    [pageIndex, navigate, pathname, pages]
  );

  return {
    applicationId,
    approved: approvalData?.approved ?? false,
    approvable: approvalData?.approvable ?? false,
    approveApplication,
    refetchApproval,

    previousPage: () => {
      updatePage(-1);
    },
    nextPage: () => {
      updatePage(1);
    },
    hasPreviousPage: pageIndex > 0,
    hasNextPage: pageIndex >= 0 && pageIndex < pages.length - 1
  };
}
