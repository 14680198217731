export interface StringMatcher {
  matches(subject: string): boolean;
}

class StartsWithMatcher implements StringMatcher {
  protected pattern: string;

  public constructor(pattern: string) {
    this.pattern = pattern;
  }

  public matches(subject: string): boolean {
    return subject.startsWith(this.pattern);
  }
}

class RegExpMatcher implements StringMatcher {
  protected regexp: RegExp;

  public constructor(pattern: string | RegExp) {
    this.regexp = new RegExp(pattern);
  }

  public matches(subject: string): boolean {
    this.regexp.lastIndex = 0;

    return this.regexp.test(subject);
  }
}

class ExactMatcher implements StringMatcher {
  protected pattern: string;

  public constructor(pattern: string) {
    this.pattern = pattern;
  }

  public matches(subject: string): boolean {
    return this.pattern === subject;
  }
}

export enum StringMatcherType {
  STARTS_WITH,
  REGEXP,
  EXACT
}

type StringMatcherFactoryArgs =
  | [StringMatcherType.STARTS_WITH, string]
  | [StringMatcherType.REGEXP, string | RegExp]
  | [StringMatcherType.EXACT, string];

export abstract class StringMatcherFactory {
  public static make(
    ...[type, pattern]: StringMatcherFactoryArgs
  ): StringMatcher {
    switch (type) {
      case StringMatcherType.STARTS_WITH:
        return new StartsWithMatcher(pattern);

      case StringMatcherType.REGEXP:
        return new RegExpMatcher(pattern);

      case StringMatcherType.EXACT:
        return new ExactMatcher(pattern);
    }
  }
}
