import { FC } from 'react';
import { SVGIconProps } from './types';

const SVGIconMessage: FC<SVGIconProps> = ({
  width = 16,
  height = 16,
  fill = 'black',
  stroke = '#5514E6',
  strokeWidth = 1.67
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.5115 5.44439H4.51772M7.00039 5.44439H7.00661M9.48928 5.44439H9.4955M5.13372 9.17773H2.64484C1.95755 9.17773 1.40039 8.62057 1.40039 7.93328L1.40039 2.9555C1.40039 2.26822 1.95755 1.71106 2.64484 1.71106H11.3559C12.0432 1.71106 12.6004 2.26822 12.6004 2.9555V7.93328C12.6004 8.62057 12.0432 9.17773 11.3559 9.17773H8.24484L5.13372 12.2888V9.17773Z"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SVGIconMessage;
