import React from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { ProtectedRoute } from 'components/atomic';
import { AuthContextProvider } from 'context/AuthContext';
import { SendbirdContextProvider } from 'apps/sendbird/hooks/sendbirdContext';
import { Navigate, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { AxiosError } from 'axios';
import { useDocumentTitle } from 'hooks';
import {
  StringMatcherFactory,
  StringMatcherType
} from 'helpers/Collection/StringMatcher';

import 'react-toastify/dist/ReactToastify.css';
import '@sendbird/uikit-react/dist/index.css';
import CustomRoutes from 'context/AuthContext/routes';
import SuperAdmin from 'apps/super-admin/SuperAdmin';

const Register = React.lazy(() => import('./pages/Register'));
const RegisterNewUser = React.lazy(() => import('./pages/RegisterNewUser'));
const Apply = React.lazy(() => import('./pages/Apply'));
const AccountCreationInterface = React.lazy(
  () => import('./pages/AccountCreationInterface')
);
const Login = React.lazy(() => import('./pages/Login'));
const ForgotPassword = React.lazy(() => import('./pages/ForgotPassword'));
const ResetPassword = React.lazy(() => import('./pages/ResetPassword'));
const ClosedLost = React.lazy(() => import('./pages/ClosedLost'));
const Settings = React.lazy(() => import('./pages/Settings'));
const Support = React.lazy(() => import('./pages/Support'));
const NotFound = React.lazy(() => import('./pages/NotFound'));
const Application = React.lazy(() => import('./apps/application/Application'));
const AccountManager = React.lazy(
  () => import('./apps/account-manager/AccountManager')
);
const TaxAttorney = React.lazy(() => import('./apps/tax-attorney/TaxAttorney'));
const TaxAttorneyAdmin = React.lazy(
  () => import('./apps/tax-attorney-admin/TaxAttorneyAdmin')
);
const Customer = React.lazy(() => import('./apps/customer/Customer'));
const DataAggregator = React.lazy(
  () => import('./apps/data-aggregator/DataAggregator')
);
const ERCA = React.lazy(() => import('./apps/ERCA/ERCA'));
const ErcaManager = React.lazy(() => import('./apps/erca-manger/ErcaManager'));
const Welcome = React.lazy(() => import('./pages/Welcome'));

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // staleTime: Infinity,
      staleTime: 0,
      cacheTime: 0,
      refetchOnWindowFocus: false,
      retry: false,
      onError(error) {
        const { response } = error as AxiosError;

        if (response?.status === 403) {
          window.location.href = '/login';
        }
      }
    },
    mutations: {
      retry: false
    }
  }
});

const useDocumentTitleConfig = [
  {
    matcher: StringMatcherFactory.make(
      StringMatcherType.STARTS_WITH,
      '/application/getting-started'
    ),
    title: 'Get Started | Innovation Refunds'
  },
  {
    matcher: StringMatcherFactory.make(
      StringMatcherType.STARTS_WITH,
      '/support'
    ),
    title: 'Support | Innovation Refunds'
  },
  {
    matcher: StringMatcherFactory.make(
      StringMatcherType.STARTS_WITH,
      '/settings'
    ),
    title: 'Settings | Innovation Refunds'
  },
  {
    matcher: StringMatcherFactory.make(
      StringMatcherType.EXACT,
      '/application/additional-info'
    ),
    title: 'Additional Info | Innovation Refunds'
  },
  {
    matcher: StringMatcherFactory.make(
      StringMatcherType.REGEXP,
      /\/application\/[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}/i
    ),
    title: 'Apply | Innovation Refunds'
  },
  {
    matcher: StringMatcherFactory.make(
      StringMatcherType.EXACT,
      '/tax-attorney'
    ),
    title: 'Tax Attorney | Innovation Refunds'
  }
];

const App = () => {
  document.title = useDocumentTitle(
    useDocumentTitleConfig,
    'Innovation Refunds'
  );

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} />
        <AuthContextProvider>
          <SendbirdContextProvider>
            <React.Suspense>
              <CustomRoutes>
                <Route path="/" element={<Navigate to="/login" replace />} />
                <Route path="/login" element={<Login />} />
                <Route path="/apply" element={<Apply />} />
                <Route
                  path="/account-creation-interface"
                  element={<AccountCreationInterface />}
                />
                <Route path="/register" element={<Register />} />
                <Route
                  path="/password-set/:token"
                  element={<RegisterNewUser />}
                />
                <Route path="/qualify" element={<Register />} />
                <Route path="/aff/:text_id" element={<Register />} />
                <Route path="/affiliate" element={<Register />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route
                  path="/password-reset/:token"
                  element={<ResetPassword />}
                />
                <Route path="/support/:session" element={<Support />} />
                <Route path="/closed-lost/:session" element={<ClosedLost />} />
                <Route
                  element={
                    <ProtectedRoute
                      allowedRoles={[
                        'csc-user',
                        'csc-team-lead',
                        'csc-manager',
                        'data-aggregator',
                        'law-admin',
                        'cpa'
                      ]}
                      redirectPath="/login"
                      redirectPathForbidden="/login"
                    />
                  }
                >
                  <Route path="/welcome" element={<Welcome />} />
                </Route>
                <Route
                  element={
                    <ProtectedRoute
                      redirectPath="/login"
                      redirectPathForbidden="/login"
                    />
                  }
                >
                  <Route path="/settings" element={<Settings />} />
                </Route>

                {/* "Application" app entry point */}
                <Route
                  element={
                    <ProtectedRoute
                      // TODO: Add "customer" role to array.
                      allowedRoles={[]}
                      redirectPath="/login"
                      redirectPathForbidden="/login"
                    />
                  }
                >
                  <Route path="/application/*" element={<Application />} />
                </Route>

                {/* "Account Manager" app entry point */}
                <Route
                  element={
                    <ProtectedRoute
                      allowedRoles={[
                        'csc-user',
                        'csc-team-lead',
                        'super-admin',
                        'csc-manager'
                      ]}
                      redirectPath="/login"
                      redirectPathForbidden="/login"
                    />
                  }
                >
                  <Route
                    path="/account-manager/*"
                    element={<AccountManager />}
                  />
                </Route>
                {/* "Customer" app entry point */}
                <Route
                  element={
                    <ProtectedRoute
                      // TODO: Add "customer" role to array.
                      allowedRoles={[]}
                      redirectPath="/login"
                      redirectPathForbidden="/login"
                    />
                  }
                >
                  <Route path="/customer/*" element={<Customer />} />
                </Route>
                {/* "Super Admin" app entry point */}
                <Route
                  element={
                    <ProtectedRoute
                      // TODO: Add "customer" role to array.
                      allowedRoles={['super-admin']}
                      redirectPath="/login"
                      redirectPathForbidden="/login"
                    />
                  }
                >
                  <Route path="/super-admin/*" element={<SuperAdmin />} />
                </Route>
                {/* "Tax Attorney Admin" app entry point */}
                <Route
                  element={
                    <ProtectedRoute
                      allowedRoles={['super-admin', 'law-admin']}
                      redirectPath="/login"
                      redirectPathForbidden="/login"
                    />
                  }
                >
                  <Route
                    path="/tax-attorney/admin/*"
                    element={<TaxAttorneyAdmin />}
                  />
                </Route>
                {/* "Tax Attorney" app entry point */}
                <Route
                  element={
                    <ProtectedRoute
                      allowedRoles={['super-admin', 'law-admin', 'cpa']}
                      redirectPath="/login"
                      redirectPathForbidden="/login"
                    />
                  }
                >
                  <Route path="/tax-attorney/*" element={<TaxAttorney />} />
                  <Route path="/404" element={<NotFound />} />
                  <Route path="*" element={<Navigate to="/404" replace />} />
                </Route>
                {/* "Data Aggregator" app entry point */}
                <Route
                  element={
                    <ProtectedRoute
                      allowedRoles={[
                        'super-admin',
                        'data-aggregator',
                        'data-aggregator-admin'
                      ]}
                      redirectPath="/login"
                      redirectPathForbidden="/login"
                    />
                  }
                >
                  <Route
                    path="/data-aggregator/*"
                    element={<DataAggregator />}
                  />
                  <Route path="/404" element={<NotFound />} />
                  <Route path="*" element={<Navigate to="/404" replace />} />
                </Route>
                {/* "ERCA" app entry point */}
                <Route
                  element={
                    <ProtectedRoute
                      allowedRoles={[]}
                      redirectPath="/login"
                      redirectPathForbidden="/login"
                    />
                  }
                >
                  <Route path="/advance/*" element={<ERCA />} />
                </Route>
                {/* "ERCA manager" app entry point */}
                <Route
                  element={
                    <ProtectedRoute
                      allowedRoles={['erca-manager']}
                      redirectPath="/login"
                      redirectPathForbidden="/login"
                    />
                  }
                >
                  <Route path="/erca-manager/*" element={<ErcaManager />} />
                </Route>

                <Route path="/404" element={<NotFound />} />
                <Route path="*" element={<Navigate to="/404" replace />} />
              </CustomRoutes>
              <ToastContainer />
            </React.Suspense>
          </SendbirdContextProvider>
        </AuthContextProvider>
      </QueryClientProvider>
    </>
  );
};

export default App;
