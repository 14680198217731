import { useQuery } from '@tanstack/react-query';
import { Modal } from 'antd';
import Table, { ColumnsType } from 'antd/es/table';

import { Button, Loader } from 'components/atomic';
import { Dispatch, FC, SetStateAction, useMemo, useState } from 'react';
import {
  getMembers,
  getAllMembersRoles
} from 'api/services/super-admin/requests';

interface TableDataType {
  key: number;
  id: number;
  name: string;
  title: string;
  role: string;
}

interface AddManagerUsersModalProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  onAction: (val: number[]) => void;
  checkedMembers: Array<number>;
}

const AddManagerUsersModal: FC<AddManagerUsersModalProps> = ({
  open = false,
  setOpen,
  onAction,
  checkedMembers
}) => {
  const { data, isLoading } = useQuery(['super-admin/members-managers'], () =>
    getMembers(2)
  );

  const [selectedRows, setSelectedRows] = useState<number[]>([]);

  const { data: roles } = useQuery(['super-admin/member-roles'], () =>
    getAllMembersRoles()
  );

  const handleCheckboxChange = (value: number) => {
    setSelectedRows((prevSelectedRows) => {
      if (prevSelectedRows.includes(value)) {
        return prevSelectedRows.filter((row) => row !== value);
      } else {
        return [...prevSelectedRows, value];
      }
    });
  };

  const members: TableDataType[] = useMemo(() => {
    const filteredMembers = data?.filter(
      (member) => !checkedMembers.includes(member.id)
    );
    const getRoleNameById = (id: number) => {
      const role = roles?.find((role) => role.id === id);
      return role ? role.name : '';
    };

    return (
      filteredMembers?.map((member, index) => ({
        key: index,
        id: member.id,
        name: `${member.user.first_name} ${member.user.last_name}`,
        role: getRoleNameById(member.role),
        title: member.title
      })) ?? []
    );
  }, [data, checkedMembers, roles]);

  const columns: ColumnsType<TableDataType> = useMemo(() => {
    return [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        render: (_, row) => {
          const checked = selectedRows.includes(row.id);
          console.log(selectedRows);
          return (
            <div className="d--f f--ac">
              <input
                type="checkbox"
                checked={checked}
                onChange={() => handleCheckboxChange(row.id)}
                className="cs-checkbox mr--3"
              />
              <span>{row.name}</span>
            </div>
          );
        }
      },
      {
        title: 'Title',
        dataIndex: 'title',
        key: 'title'
      },
      {
        title: 'Role',
        dataIndex: 'role',
        key: 'role'
      }
    ];
  }, [selectedRows]);

  if (isLoading) {
    return <Loader inContext={true} />;
  }

  return (
    <Modal
      open={open}
      footer={null}
      afterClose={() => {}}
      centered
      width={600}
      onCancel={() => setOpen(false)}
    >
      <h2 className="fs--3 fw--600">Add users</h2>
      <Table columns={columns} dataSource={members} sticky pagination={false} />

      <Button
        size="md"
        className="w--100 mt--6"
        onClick={() => {
          onAction(selectedRows);
          setOpen(false);
        }}
      >
        Add users
      </Button>
    </Modal>
  );
};

export default AddManagerUsersModal;
