import { FC } from 'react';
import { SVGIconProps } from './types';

const SVGIconRotateLeft: FC<SVGIconProps> = ({
  width = 15,
  height = 12,
  stroke = '#2E2E2E',
  strokeWidth = 1
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 15 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.20426 0.570312C4.89409 0.570312 2.20428 3.00457 2.20428 6.00024C2.20428 7.41154 2.80214 8.69945 3.77928 9.66545H1.30428C1.22393 9.66448 1.14652 9.6926 1.08946 9.7435C1.03214 9.79465 1 9.86447 1 9.93694C1 10.0094 1.03214 10.0792 1.08946 10.1304C1.14679 10.1815 1.22393 10.2097 1.30428 10.2087H4.60427C4.76981 10.2087 4.90427 10.087 4.90427 9.93719V6.95072C4.90775 6.81013 4.79177 6.69038 4.63722 6.67487C4.55124 6.66638 4.46552 6.69183 4.40177 6.74444C4.33802 6.79728 4.30267 6.87243 4.30454 6.95048V9.37698C3.37642 8.49971 2.80455 7.3124 2.80455 6.00024C2.80455 3.29813 5.21874 1.11331 8.20453 1.11331C11.1906 1.11331 13.6045 3.29813 13.6045 6.00024C13.6045 8.70236 11.1906 10.8872 8.20453 10.8872C8.12417 10.8862 8.04676 10.9143 7.98971 10.9652C7.93239 11.0164 7.90024 11.086 7.90024 11.1587C7.90024 11.2314 7.93239 11.301 7.98971 11.3521C8.04676 11.4033 8.12417 11.4314 8.20453 11.4302C11.5147 11.4302 14.2045 8.99592 14.2045 6.00024C14.2042 3.00457 11.5144 0.570312 8.20426 0.570312Z"
      stroke={stroke}
      strokeWidth={strokeWidth}
    />
  </svg>
);

export default SVGIconRotateLeft;
