import { FC } from 'react';
import { SVGIconProps } from './types';

const SVGIconFiles: FC<SVGIconProps> = ({
  width = 16,
  height = 16,
  stroke = 'black',
  strokeWidth = 1.67
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.88894 4.20291V10.4251C4.88894 11.2842 5.58539 11.9807 6.4445 11.9807H11.1112M4.88894 4.20291V2.64735C4.88894 1.78824 5.58539 1.0918 6.4445 1.0918H10.0112C10.2175 1.0918 10.4153 1.17374 10.5612 1.3196L13.9945 4.75288C14.1403 4.89874 14.2223 5.09657 14.2223 5.30285V10.4251C14.2223 11.2842 13.5258 11.9807 12.6667 11.9807H11.1112M4.88894 4.20291H3.77783C2.67326 4.20291 1.77783 5.09834 1.77783 6.20291V13.5362C1.77783 14.3954 2.47428 15.0918 3.33339 15.0918H9.11116C10.2157 15.0918 11.1112 14.1964 11.1112 13.0918V11.9807"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SVGIconFiles;
