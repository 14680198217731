import { Header, Sidebar } from '../components';
import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import style from './layout-sidebar.module.scss';
import cx from 'classnames';

const LayoutSidebar = () => {
  const [collapsed, setCollapsed] = useState(false);
  return (
    <div className={style.container}>
      <Header hasSidebar />
      <div className={style.contentWrap}>
        <Sidebar collapsed={collapsed} setCollapsed={setCollapsed} />
        <div className={cx(style.content, collapsed ? style.collapsed : '')}>
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default LayoutSidebar;
