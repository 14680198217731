import client from 'api/client';
import {
  CompanyInfoGeneralResponse,
  CompanyInfoOwnershipResponse,
  CompanyInfoAffiliatedResponse,
  CompanyInfoPeoResponse,
  CompanyInfoBankingResponse,
  CompanyInfoEmployeeGeneralResponse,
  CompanyInfoEmployeeOwnersResponse,
  CompanyInfoEmployeeFamilyResponse,
  CovidDisruptionsResponse,
  CovidQuartersImpactResponse,
  CovidDisruptionsAdditionalResponse,
  CovidDisruptionsDescriptionResponse,
  PPPGeneralResponse,
  PayrollTaxReturnsGeneralResponse,
  PayrollTaxReturns941Response,
  PayrollTaxReturns943Response,
  PPPUploadResponse,
  PayrollWagesResponse,
  BenefitsGeneralResponse,
  ProfitAndLossResponse,
  ErrataPageResponse,
  BulkUploaderResponse
} from 'api/services/app/common/interfaces/responses';

export const getCompanyInfoGeneral = async (
  session: string
): Promise<Partial<CompanyInfoGeneralResponse>> =>
  (await client.get(`application/company-info-general/${session}/`))?.data;

export const getCompanyInfoOwnership = async (
  session: string
): Promise<Partial<CompanyInfoOwnershipResponse>> =>
  (await client.get(`application/company-info-ownership/${session}/`))?.data;

export const getCompanyInfoAffiliated = async (
  session: string
): Promise<Partial<CompanyInfoAffiliatedResponse>> =>
  (await client.get(`application/company-info-affiliated/${session}/`))?.data;

export const getCompanyInfoPeo = async (
  session: string
): Promise<Partial<CompanyInfoPeoResponse>> =>
  (await client.get(`application/company-info-peo/${session}/`))?.data;

export const getCompanyInfoBanking = async (
  session: string
): Promise<Partial<CompanyInfoBankingResponse>> =>
  (await client.get(`application/company-info-banking/${session}/`))?.data;

export const getCompanyInfoEmployeeGeneral = async (
  session: string
): Promise<Partial<CompanyInfoEmployeeGeneralResponse>> =>
  (await client.get(`application/company-info-employee-general/${session}/`))
    ?.data;

export const getCompanyInfoEmployeeOwners = async (
  session: string
): Promise<Partial<CompanyInfoEmployeeOwnersResponse>> =>
  (await client.get(`application/company-info-employee-owners/${session}/`))
    ?.data;

export const getCompanyInfoEmployeeFamily = async (
  session: string
): Promise<Partial<CompanyInfoEmployeeFamilyResponse>> =>
  (await client.get(`application/company-info-employee-family/${session}/`))
    ?.data;

export const getCovidDisruptions = async (
  session: string
): Promise<Partial<CovidDisruptionsResponse>> =>
  (await client.get(`application/covid-disruptions/${session}/`))?.data;

export const getCovidQuartersImpact = async (
  session: string
): Promise<Partial<CovidQuartersImpactResponse>> =>
  (await client.get(`application/covid-quarters-impact/${session}/`))?.data;

export const getCovidDisruptionsAdditional = async (
  session: string
): Promise<Partial<CovidDisruptionsAdditionalResponse>> =>
  (await client.get(`application/covid-disruptions-additional/${session}/`))
    ?.data;

export const getCovidDisruptionsDescription = async (
  session: string
): Promise<Partial<CovidDisruptionsDescriptionResponse>> =>
  (await client.get(`application/covid-disruptions-description/${session}/`))
    ?.data;

export const getPPPGeneral = async (
  session: string
): Promise<Partial<PPPGeneralResponse>> =>
  (await client.get(`application/ppp-general/${session}/`))?.data;

export const getPPPUpload = async (
  session: string
): Promise<Partial<PPPUploadResponse>> =>
  (await client.get(`application/ppp-upload/${session}/`))?.data;

export const getPayrollWages = async (
  session: string
): Promise<Partial<PayrollWagesResponse>> =>
  (await client.get(`application/payroll-wages/${session}/`))?.data;

export const getBenefitsGeneral = async (
  session: string
): Promise<Partial<BenefitsGeneralResponse>> =>
  (await client.get(`application/benefits-general/${session}/`))?.data;

export const getPayrollTaxReturnsGeneral = async (
  session: string
): Promise<Partial<PayrollTaxReturnsGeneralResponse>> =>
  (await client.get(`application/payroll-tax-returns-general/${session}/`))
    ?.data;

export const getPayrollTaxReturns941 = async (
  session: string
): Promise<Partial<PayrollTaxReturns941Response>> =>
  (await client.get(`application/payroll-tax-returns-941/${session}/`))?.data;

export const getPayrollTaxReturns943 = async (
  session: string
): Promise<Partial<PayrollTaxReturns943Response>> =>
  (await client.get(`application/payroll-tax-returns-943/${session}/`))?.data;

export const getProfitAndLoss = async (
  session: string
): Promise<Partial<ProfitAndLossResponse>> =>
  (await client.get(`application/profit-and-loss-upload/${session}/`))?.data;

export const getErrataPage = async (
  session: string
): Promise<Partial<ErrataPageResponse>> =>
  (await client.get(`errata-page/${session}/`))?.data;

  export const getBulkUploaderPage = async (
    session: string
  ): Promise<Partial<BulkUploaderResponse>> =>
    (await client.get(`bulk-upload/${session}/`))?.data;
