import ReactSelect, {
  GroupBase,
  MultiValue,
  Props,
  SingleValue
} from 'react-select';
import { ErrorMessage, FieldHookConfig, useField } from 'formik';
import type { FieldColAlignment } from '../types';

import style from './select.module.scss';
import cx from 'classnames';

type AllowedValue =
  | string
  | number
  | object
  | readonly string[]
  | readonly number[]
  | readonly object[]
  | undefined;

interface Option {
  value: AllowedValue;
  label: string;
  isDisabled?: boolean;
}

type SelectProps = FieldHookConfig<AllowedValue | AllowedValue[]> &
  FieldColAlignment & {
    label?: string;
    touchOnChange?: boolean;
    touchOnBlur?: boolean;
  };

const Select = <
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>({
  options,
  isMulti = false as IsMulti,
  label,
  placeholder = 'Select',
  left,
  right,
  middle,
  touchOnChange = true,
  touchOnBlur = false,
  ...props
}: Props<Option, IsMulti, Group> & SelectProps) => {
  const [field, { error }, { setValue, setTouched }] = useField({ ...props });

  const onChangeSingle = (option: SingleValue<Option>) => {
    touchOnChange && setTouched(true, true);
    setValue(option?.value);
  };

  const onChangeMulti = (options: MultiValue<Option>) => {
    touchOnChange && setTouched(true, true);
    setValue(options ? options.map((option) => option?.value) : []);
  };

  const getValue = (selected: Option | Group) =>
    'value' in selected ? selected.value : undefined;

  const getValueSingle = () =>
    options
      ? options.find((option) => getValue(option) === field.value)
      : undefined;

  const getValueMulti = () => {
    return options
      ? options.filter((option) => {
          if (!Array.isArray(field.value)) {
            return false;
          }

          return field.value?.includes(getValue(option));
        })
      : [];
  };

  return (
    <div
      className={cx(
        style.wrapper,
        props.className,
        left ? 'field-left' : '',
        right ? 'field-right' : '',
        middle ? 'field-middle' : ''
      )}
    >
      {label && <label htmlFor={props.id ?? props.name}>{label}</label>}
      <ReactSelect
        id={props.id}
        placeholder={placeholder}
        {...field}
        value={isMulti ? getValueMulti() : getValueSingle()}
        onChange={(selected) => {
          isMulti
            ? onChangeMulti(selected as MultiValue<Option>)
            : onChangeSingle(selected as SingleValue<Option>);
        }}
        onBlur={() => {
          touchOnBlur && setTouched(true, true);
        }}
        options={options}
        isMulti={isMulti}
        className={cx(
          style.select,
          props.isDisabled ? style.disabled : ''
        )}
        styles={{
          control: (base, state) => {
            return {
              ...base,
              ...(props.isDisabled ? {
                backgroundColor: '#f3f3f3'
              } : {}),
              border: state.isFocused ? 
                '1px solid #9972f0 !important' : '1px solid #cfcfcf !important',
              boxShadow: state.isFocused ? 
                '0px 0px 0px 4px #eee8fd !important' : 'none'
            }
          },
          option: (base, { isSelected, isFocused }) => ({
            ...base,
            backgroundColor: isSelected || isFocused ? '#eee8fd' : '#ffffff',
            color: '#2e2e2e',
            "&:hover": {
              backgroundColor: "#eee8fd"
            }
          })
        }}
      />
      <span className={style.error}>
        {error && <ErrorMessage name={field.name} />}
      </span>
    </div>
  );
};

export default Select;
