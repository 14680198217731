import { getSingleAffiliate } from 'api/services/super-admin/requests';
import { useQuery } from '@tanstack/react-query';

const useSingleAffiliateQuery = (id: string) => {
  return useQuery(
    {
      queryKey: ['super-admin/affiliates', id],
      queryFn: async () => {
        if (!id) {
          //Return an error response when id is empty or null
          return Promise.reject(new Error('Invalid id'));
        }
        return getSingleAffiliate(id);
      },
      retry: 3,
      enabled: !!id,
    }
  );
};

export default useSingleAffiliateQuery;
