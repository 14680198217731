import { FC } from 'react';
import { SVGIconProps } from './types';

const SVGIconQuestionCircle: FC<SVGIconProps> = ({
  width = 49,
  height = 48,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 49 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
        x="0.5"
        width="48"
        height="48"
        rx="24"
        fill="#EEE8FD"
    />
    <path 
        fillRule="evenodd"
        clipRule="evenodd" 
        d="M32.9016 24.0001C32.9016 28.6393 29.1408 32.4001 24.5016 32.4001C19.8624 32.4001 16.1016 28.6393 16.1016 24.0001C16.1016 19.3609 19.8624 15.6001 24.5016 15.6001C29.1408 15.6001 32.9016 19.3609 32.9016 24.0001ZM24.5016 20.8501C24.1142 20.8501 23.7745 21.0593 23.5914 21.3759C23.301 21.8778 22.6587 22.0494 22.1568 21.759C21.6548 21.4686 21.4833 20.8263 21.7736 20.3243C22.3167 19.3855 23.3343 18.7501 24.5016 18.7501C26.2413 18.7501 27.6516 20.1604 27.6516 21.9001C27.6516 23.2716 26.775 24.4384 25.5516 24.8709V25.0501C25.5516 25.63 25.0815 26.1001 24.5016 26.1001C23.9217 26.1001 23.4516 25.63 23.4516 25.0501V24.0001C23.4516 23.4202 23.9217 22.9501 24.5016 22.9501C25.0815 22.9501 25.5516 22.48 25.5516 21.9001C25.5516 21.3202 25.0815 20.8501 24.5016 20.8501ZM24.5016 29.2501C25.0815 29.2501 25.5516 28.78 25.5516 28.2001C25.5516 27.6202 25.0815 27.1501 24.5016 27.1501C23.9217 27.1501 23.4516 27.6202 23.4516 28.2001C23.4516 28.78 23.9217 29.2501 24.5016 29.2501Z"
        fill="#171717"
    />
  </svg>
);

export default SVGIconQuestionCircle;
