import { FC } from 'react';
import { SVGIconProps } from './types';

const SVGIconCarretRight: FC<SVGIconProps> = ({
  width = 7,
  height = 12,
  stroke = 'black',
  strokeWidth = 1.67
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 7 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      stroke={stroke}
      strokeWidth={strokeWidth}
      d="M1 1L6 6L1 11"
      strokeLinecap="round"
    />
  </svg>
);

export default SVGIconCarretRight;
