import { FC } from 'react';
import { SVGIconProps } from './types';

const SVGIconTriangleAlert: FC<SVGIconProps> = ({
  width = 17,
  height = 16,
  stroke = '#CC7A00',
  strokeWidth = 1.67
}) => (
  <svg width={width} height={height} viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.61426 6.43248V8.00002M8.61426 11.1351H8.6221M3.18414 14.2702H14.0444C15.2511 14.2702 16.0053 12.9639 15.4019 11.9189L9.97179 2.51363C9.36844 1.4686 7.86007 1.4686 7.25673 2.51363L1.82661 11.9189C1.22326 12.9639 1.97744 14.2702 3.18414 14.2702Z" stroke={stroke} strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
  </svg>

);

export default SVGIconTriangleAlert;