import { FC } from 'react';
import { SVGIconProps } from './types';

const SVGIconClose: FC<SVGIconProps> = ({
  width = 20,
  height = 20,
  stroke = '#FDFDFD',
  strokeWidth = "1.67",
  className = '',
  onClick
}) => (
  <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className={className} onClick={onClick}>
    <path d="M1 15L8 8M8 8L15 1M8 8L1 1M8 8L15 15" stroke={stroke} strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

export default SVGIconClose;
