import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { LayoutSidebar } from './layout';
import './super-admin.scss';
import { Loader } from 'components/atomic';

const Applications = React.lazy(() => import('./pages/Applications'));
const Members = React.lazy(() => import('./pages/Members'));
const Groups = React.lazy(() => import('./pages/Groups'));
const Managers = React.lazy(() => import('./pages/Managers'));
const ERCAManagers = React.lazy(() => import('./pages/ERCAManagers'));
const Affiliates = React.lazy(() => import('./pages/Affiliates'));
const AddAffiliates = React.lazy(
  () => import('./pages/Affiliates/AddAffiliate')
);
const EditAffiliate = React.lazy(
  () => import('./pages/Affiliates/EditAffiliate')
);

const SuperAdmin = () => {
  return (
    <React.Suspense fallback={<Loader />}>
      <Routes>
        <Route path="" element={<LayoutSidebar />}>
          <Route path="applications" element={<Applications />} />
          <Route path="members" element={<Members />} />
          <Route path="groups" element={<Groups />} />
          <Route path="managers" element={<Managers />} />
          <Route path="erca-managers" element={<ERCAManagers />} />
          <Route path="affiliates">
            <Route path="" element={<Affiliates />} />
            <Route path="add" element={<AddAffiliates />} />
            <Route path="edit/:id" element={<EditAffiliate />} />
          </Route>
        </Route>
        <Route path="*" element={<Navigate to="/404" replace />} />
      </Routes>
    </React.Suspense>
  );
};

export default SuperAdmin;
