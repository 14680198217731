import { FC } from 'react';
import { SVGIconProps } from './types';

const SVGIconFlag: FC<SVGIconProps> = ({
 width = 14,
 height = 14,
 stroke = '#2E2E2E',
 strokeWidth = 1.67
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    >
    <path
      d="M1 15V11.8889M1 11.8889V2.55556C1 1.69645 1.69645 1 2.55556 1H7.61111L8.38889 1.77778H15L12.6667 6.44444L15 11.1111H8.38889L7.61111 10.3333H2.55556C1.69645 10.3333 1 11.0298 1 11.8889ZM8 1.38889V5.66667"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SVGIconFlag;
