import { FC } from 'react';
import { Tabs as VendorTabs, TabsProps as VendorTabProps } from 'antd';
import './tabs.scss';
import cx from 'classnames';

interface TabsProps extends VendorTabProps {}

const Tabs: FC<TabsProps> = ({ className, ...props }) => {
  return <VendorTabs {...props} className={cx('cs-tabs', className)} />;
};

export default Tabs;
