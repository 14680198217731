import { FC } from 'react';
import { SVGIconProps } from './types';

const SVGIconMoney: FC<SVGIconProps> = ({
  width = 16,
  height = 14,
  stroke = 'black',
  strokeWidth = 1.67
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      stroke={stroke}
      strokeWidth={strokeWidth}
      d="M11.8889 4.75857V3.20302C11.8889 2.34391 11.1924 1.64746 10.3333 1.64746H2.55556C1.69645 1.64746 1 2.34391 1 3.20302V7.86968C1 8.72879 1.69645 9.42524 2.55556 9.42524H4.11111M5.66667 12.5363H13.4444C14.3036 12.5363 15 11.8399 15 10.9808V6.31413C15 5.45502 14.3036 4.75857 13.4444 4.75857H5.66667C4.80756 4.75857 4.11111 5.45502 4.11111 6.31413V10.9808C4.11111 11.8399 4.80756 12.5363 5.66667 12.5363ZM11.1111 8.64746C11.1111 9.50657 10.4147 10.203 9.55556 10.203C8.69645 10.203 8 9.50657 8 8.64746C8 7.78835 8.69645 7.09191 9.55556 7.09191C10.4147 7.09191 11.1111 7.78835 11.1111 8.64746Z"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SVGIconMoney;
