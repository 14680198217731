import { FC } from 'react';
import { SVGIconProps } from './types';

const SVGIconMinus: FC<SVGIconProps> = ({
  width = 24,
  height = 24,
  fill = 'black',
  stroke = 'black'
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path fill={fill} stroke={stroke} d="M19 12.998H5V10.998H19V12.998Z" />
  </svg>
);

export default SVGIconMinus;
