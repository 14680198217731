import { FC } from 'react';
import { SVGIconProps } from './types';

const SVGIconSignedAgreements: FC<SVGIconProps> = ({
  width = 16,
  height = 16,
  stroke = 'white',
  strokeWidth = "1.67",
  className = '',
}) => (
  <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.5108 2.84019L13.2553 5.58464M11.6752 1.67582C12.4331 0.917957 13.6618 0.917957 14.4196 1.67582C15.1775 2.43368 15.1775 3.66241 14.4196 4.42027L3.73249 15.1074H1.01562V12.3354L11.6752 1.67582Z" stroke={stroke} strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round" className={className}/>
  </svg>
);

export default SVGIconSignedAgreements;
