import { FC } from 'react';
import { SVGIconProps } from './types';

const SVGIconAlert: FC<SVGIconProps> = ({
  width = 14,
  height = 15,
  stroke = 'white'
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 14 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.04178 10.5278H12.4446L11.4884 9.57165C11.2291 9.31234 11.0834 8.96065 11.0834 8.59395V6.44445C11.0834 4.66654 9.94718 3.15402 8.36122 2.59346V2.36111C8.36122 1.60939 7.75183 1 7.00011 1C6.24839 1 5.639 1.60939 5.639 2.36111V2.59346C4.05304 3.15402 2.91678 4.66654 2.91678 6.44445V8.59395C2.91678 8.96065 2.7711 9.31234 2.5118 9.57165L1.55566 10.5278H4.95844M9.04178 10.5278V11.2083C9.04178 12.3359 8.12769 13.25 7.00011 13.25C5.87253 13.25 4.95844 12.3359 4.95844 11.2083V10.5278M9.04178 10.5278H4.95844"
      stroke={stroke}
      strokeWidth="1.67"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SVGIconAlert;
