import { ClientStorage } from '.';

export abstract class WindowStorage implements ClientStorage {
  protected abstract readonly storage: Storage;

  public set(key: string | symbol, value: any, ttl: number = 0): void {
    try {
      this.storage.setItem(
        String(key),
        JSON.stringify({
          value,
          expires: ttl > 0 ? Date.now() + ttl * 1000 : 0
        })
      );
    } catch (error) {
      throw new TypeError('Failed to stringify storage value.');
    }
  }

  public has(key: string | symbol): boolean {
    const item = this.storage.getItem(String(key));
    if (!item) {
      return false;
    }
    try {
      const { expires } = JSON.parse(item);
      if (expires && expires < Date.now()) {
        this.delete(key);
        return false;
      }
      return true;
    } catch (error) {
      return false;
    }
  }

  public get(key: string | symbol | RegExp): any {
    if (typeof key === 'string' || typeof key === 'symbol') {
      const item = this.storage.getItem(String(key));
      if (!item) {
        return null;
      }
      try {
        const { value, expires } = JSON.parse(item);
        if (expires && expires < Date.now()) {
          this.delete(key);
          return null;
        }
        return value;
      } catch (error) {
        return null;
      }
    }
    const items = [];
    for (let i = 0; i < this.storage.length; ++i) {
      const storageKey = this.storage.key(i) as string;
      if (key.test(storageKey)) {
        const item = this.storage.getItem(storageKey);
        if (!item) {
          continue;
        }

        try {
          const { value, expires } = JSON.parse(item);
          if (expires && expires < Date.now()) {
            this.delete(storageKey);
            continue;
          }
          items.push({ key: storageKey, value });
        } catch (error) {
          items.push({ key: storageKey, value: null });
        }
      }
    }
    return items;
  }

  public delete(key: string | symbol): void {
    this.storage.removeItem(String(key));
  }

  public refresh(key: string | symbol, ttl: number = 0): boolean {
    const value = this.get(key);
    if (!value) {
      return false;
    }
    this.set(key, value, ttl);
    return true;
  }
}
