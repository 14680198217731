import { useQuery } from '@tanstack/react-query';
import { getApplicationData } from 'api/services/application/form-steps/requests';
import { FC, PropsWithChildren, useMemo } from 'react';
import { Navigate, useParams } from 'react-router';
import Loader from '../Loader/Loader';

const ErrataRedirect: FC<PropsWithChildren> = ({ children }) => {
  const { session = '' } = useParams();

  const { data, isLoading } = useQuery(
    ['errata-application-data', session],
    () => getApplicationData(session),
    {
      // TODO: Remove this after invalidateQueries on Errate form is resolved
      staleTime: 0,
      cacheTime: 0
    }
  );

  const shouldRedirect = useMemo(() => {
    if (!data) {
      return false;
    }

    return !['user-profile'].includes(data?.active_step ?? '') && !data?.is_v2;
  }, [data]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      {shouldRedirect ? (
        <Navigate to={`/application/additional-info/${session}`} />
      ) : (
        children
      )}
    </>
  );
};

export const withErrataRedirect =
  (WrappedComponent: (props: PropsWithChildren) => JSX.Element) =>
  (props: PropsWithChildren) =>
    (
      <ErrataRedirect>
        <WrappedComponent {...props}></WrappedComponent>
      </ErrataRedirect>
    );

export default ErrataRedirect;
