import { FC } from 'react';
import { SVGIconProps } from './types';

const SVGIconProfitAndLoss: FC<SVGIconProps> = ({
  width = 20,
  height = 21,
  stroke = 'white'
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.11111 13.203V3.86963M6.11111 3.86963L3 6.98074M6.11111 3.86963L9.22222 6.98074M13.8889 6.98074V16.3141M13.8889 16.3141L17 13.203M13.8889 16.3141L10.7778 13.203"
      stroke={stroke}
      strokeWidth="1.67"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SVGIconProfitAndLoss;
