import { FC } from 'react';
import { SVGIconProps } from './types';

const SVGIconCompanyProfile: FC<SVGIconProps> = ({
  width = 20,
  height = 21,
  stroke = 'white'
}) => (
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.4444 17.0918V4.64735C15.4444 3.78824 14.748 3.0918 13.8889 3.0918H6.11111C5.252 3.0918 4.55556 3.78824 4.55556 4.64735V17.0918M15.4444 17.0918L17 17.0918M15.4444 17.0918H11.5556M4.55556 17.0918L3 17.0918M4.55556 17.0918H8.44444M7.66667 6.20289H8.44444M7.66667 9.314H8.44444M11.5556 6.20289H12.3333M11.5556 9.314H12.3333M8.44444 17.0918V13.2029C8.44444 12.7733 8.79267 12.4251 9.22222 12.4251H10.7778C11.2073 12.4251 11.5556 12.7733 11.5556 13.2029V17.0918M8.44444 17.0918H11.5556"
      stroke={stroke}
      strokeWidth="1.67"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SVGIconCompanyProfile;
