import actions, { AuthActionType, AuthAction } from './actions';
import { User } from 'models/User';

export interface AuthState {
  user: User | null;
  isAuthenticating: boolean;
  error: boolean;
}

const AuthReducer = (state: AuthState, action: AuthAction): AuthState => {
  switch (action.type) {
    case AuthActionType.REQUEST_LOGIN:
      return actions[AuthActionType.REQUEST_LOGIN](state);

    case AuthActionType.LOGOUT:
      return actions[AuthActionType.LOGOUT](state);

    case AuthActionType.LOGIN_SUCCESS:
      return actions[AuthActionType.LOGIN_SUCCESS](state, action.payload);

    case AuthActionType.LOGIN_ERROR:
      return actions[AuthActionType.LOGIN_ERROR](state);

    case AuthActionType.RESUME_SESSION:
      return actions[AuthActionType.RESUME_SESSION](state, action.payload);
  }
};

export default AuthReducer;
