import { Table } from 'antd';
import { SVGIcon } from 'components/icons';
import Papa from 'papaparse';
import style from './csv-viewer.module.scss';
import { useEffect, useState } from 'react';

export const CSVViewer = ({ url }: { url: string; label?: string }) => {
  const [tableData, setTableData] = useState<{}[]>([]);
  const [tableColumns, setTableColumns] = useState<
    { title: string; dataIndex: string }[]
  >([]);

  const parseCSV = async (url: string) => {
    Papa.parse(url, {
      header: true,
      download: true,
      dynamicTyping: true,
      complete: function (results) {
        const columns: { title: string; dataIndex: string }[] = Object.keys(
          results.data[0] as object
        ).map((key) => ({
          title: key,
          dataIndex: key
        }));
        setTableColumns(columns);
        setTableData(results.data as {}[]);
      }
    });
  };

  useEffect(() => {
    parseCSV(url);
  }, [url]);

  return (
    <div className={style.wrapper}>
      <div className={style.previewContainer}>
        <Table
          dataSource={tableData}
          columns={tableColumns}
          loading={tableData.length === 0}
          rowKey="id"
          pagination={{
            showSizeChanger: false,
            defaultPageSize: 100,
            locale: { items_per_page: '' },
            nextIcon: <SVGIcon variant="page-right" stroke="#737373" />,
            prevIcon: <SVGIcon variant="page-left" stroke="#737373" />
          }}
          className="data-table"
        />
      </div>
    </div>
  );
};
