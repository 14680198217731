import { useState, useMemo } from 'react';
import { debounce } from 'helpers/utils';

const useSearch = (delay: number = 400) => {
  const [phrase, setPhraseState] = useState('');

  const setPhrase = useMemo(
    () =>
      debounce((phrase: string) => {
        setPhraseState(phrase);
      }, delay),
    [delay]
  );

  return { setPhrase, phrase };
};

export default useSearch;
