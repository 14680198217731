type ImageViewerProps = {
  url: string;
  label?: string;
};

export const MicrosoftOfficeViewer = ({ url }: ImageViewerProps) => {
  return (
    <iframe
      id="msdoc-iframe"
      className="w--100 h--100"
      title="msdoc-iframe"
      src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(
        url
      )}`}
    />
  );
};
