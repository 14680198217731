import { PropsWithChildren } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuthContext } from 'context/AuthContext';

function RedirectAuthenticated({ children }: PropsWithChildren) {
  const { user } = useAuthContext();

  console.log(user);

  let lawAdminId = user?.firm_id ? user?.firm_id : 1;

  switch (true) {
    case user?.hasAnyRole(['super-admin']):
      return <Navigate to={`/super-admin/applications`} />;

    case user?.hasAnyRole(['law-admin', 'super-admin']):
      return <Navigate to={`/tax-attorney/admin/${lawAdminId}/cases`} />;

    case user?.hasAnyRole(['cpa', 'law-admin', 'super-admin']):
      return <Navigate to={'/tax-attorney'} />;

    case user?.hasAnyRole(['erca-manager']):
      return <Navigate to={`/erca-manager`} />;

    case user?.hasAnyRole(['data-aggregator', 'data-aggregator-admin']):
      return <Navigate to={'/data-aggregator'} />;

    case user?.hasAnyRole(['csc-user', 'csc-team-lead', 'csc-manager']):
      return <Navigate to={'/account-manager'} />;

    case user?.hasAnyRole(['customer']):
      return <Navigate to={'/settings?tab=my-companies&redirect'} />;

    default:
      return user ? (
        <Navigate to={'/settings?tab=my-companies&redirect'} />
      ) : (
        <>{children}</>
      );
  }
}

export const withRedirectAuthenticated =
  (WrappedComponent: (props: PropsWithChildren) => JSX.Element) =>
  (props: PropsWithChildren) =>
    (
      <RedirectAuthenticated>
        <WrappedComponent {...props}></WrappedComponent>
      </RedirectAuthenticated>
    );

export default RedirectAuthenticated;
