import {
  FC,
  PropsWithChildren,
  HTMLAttributes,
  AnchorHTMLAttributes,
  ButtonHTMLAttributes
} from 'react';
import cx from 'classnames';
import style from './button.module.scss';

export type ButtonSize = 'sm' | 'md' | 'lg' | 'icon';
export type ButtonVariant =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'quaternary'
  | 'simple'
  | 'danger'
  | 'text_danger'
  | 'edit'
  | 'dark';
export type ButtonIconType = 'fill' | 'stroke';

type IntrinsicProps =
  | HTMLAttributes<HTMLElement>
  | AnchorHTMLAttributes<HTMLAnchorElement>
  | ButtonHTMLAttributes<HTMLButtonElement>;

type ButtonProps = IntrinsicProps & {
  as?: FC<IntrinsicProps> | string;
  size?: ButtonSize;
  variant?: ButtonVariant;
  iconType?: ButtonIconType;
};

const Button: FC<PropsWithChildren<ButtonProps>> = ({
  as: Wrapper = 'button',
  size = 'lg',
  variant = 'primary',
  iconType = 'stroke',
  children,
  className,
  ...props
}) => {
  return (
    <Wrapper
      className={cx(
        style[size],
        style[variant],
        style[iconType],
        style.wrapper,
        className
      )}
      {...props}
    >
      {children}
    </Wrapper>
  );
};

export default Button;
