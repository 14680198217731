import { useQuery } from '@tanstack/react-query';
import { getUploadDates } from 'api/services/common-dashboards/form-steps/requests';

const useUploadMonths = (session: string) => {
  const { data, isLoading } = useQuery(['upload-dates', session], () =>
    getUploadDates(session)
  );

  return {
    isMonthsLoading: isLoading,
    months: data?.upload_months ?? []
  };
};

export default useUploadMonths;
