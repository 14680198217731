import { FC } from 'react';
import { SVGIconProps } from './types';

const SVGIconChat: FC<SVGIconProps> = ({
  width = 25,
  height = 24,
  stroke = 'white'
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.5979 7.33331H19.4646C20.4955 7.33331 21.3312 8.16904 21.3312 9.19998V14.8C21.3312 15.8309 20.4955 16.6666 19.4646 16.6666H17.5979V20.4L13.8646 16.6666H10.1312C9.61578 16.6666 9.14912 16.4577 8.81132 16.1199M8.81132 16.1199L11.9979 12.9333H15.7312C16.7622 12.9333 17.5979 12.0976 17.5979 11.0666V5.46664C17.5979 4.43571 16.7622 3.59998 15.7312 3.59998H6.39792C5.36699 3.59998 4.53125 4.43571 4.53125 5.46664V11.0666C4.53125 12.0976 5.36699 12.9333 6.39792 12.9333H8.26458V16.6666L8.81132 16.1199Z"
      stroke={stroke}
      strokeWidth="1.67"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SVGIconChat;
