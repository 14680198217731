import { FC } from 'react';
import { SVGIconProps } from './types';

const SVGIconUser: FC<SVGIconProps> = ({
  width = 16,
  height = 14,
  stroke = 'black',
  strokeWidth = 1.67
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      stroke={stroke}
      strokeWidth={strokeWidth}
      d="M11.5 12.6917H15V11.2917C15 10.1319 14.0598 9.1917 12.9 9.1917C12.2311 9.1917 11.6352 9.50446 11.2506 9.99172M11.5 12.6917H4.5M11.5 12.6917V11.2917C11.5 10.8323 11.4115 10.3936 11.2506 9.99172M4.5 12.6917H1V11.2917C1 10.1319 1.9402 9.1917 3.1 9.1917C3.76892 9.1917 4.3648 9.50446 4.74937 9.99172M4.5 12.6917V11.2917C4.5 10.8323 4.5885 10.3936 4.74937 9.99172M4.74937 9.99172C5.26545 8.70242 6.52637 7.7917 8 7.7917C9.47363 7.7917 10.7345 8.70242 11.2506 9.99172M10.1 3.5917C10.1 4.7515 9.1598 5.6917 8 5.6917C6.8402 5.6917 5.9 4.7515 5.9 3.5917C5.9 2.4319 6.8402 1.4917 8 1.4917C9.1598 1.4917 10.1 2.4319 10.1 3.5917ZM14.3 5.6917C14.3 6.4649 13.6732 7.0917 12.9 7.0917C12.1268 7.0917 11.5 6.4649 11.5 5.6917C11.5 4.9185 12.1268 4.2917 12.9 4.2917C13.6732 4.2917 14.3 4.9185 14.3 5.6917ZM4.5 5.6917C4.5 6.4649 3.8732 7.0917 3.1 7.0917C2.3268 7.0917 1.7 6.4649 1.7 5.6917C1.7 4.9185 2.3268 4.2917 3.1 4.2917C3.8732 4.2917 4.5 4.9185 4.5 5.6917Z"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SVGIconUser;
