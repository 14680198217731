import { Nullable } from '../app/common/interfaces/common';

export enum AffiliatesFeeTypes {
  FLAT_FEE = 'flat',
  PERCENTAGE = 'percentage',
  HYBRID = 'hybrid'
}

export enum FeeStatus {
  ACTIVE = 'active',
  EXPIRED = 'expired',
  UPCOMING = 'upcoming'
}

export interface FeeData {
  type: AffiliatesFeeTypes;
  percentage?: number;
  flat_fee?: number;
  offset?: boolean;
}

export interface AffiliateContract {
  fee: FeeData;
  start_date?: string;
  end_date?: null | string;
  perpetuity?: boolean;
}

export interface Member {
  id: number;
  name: string;
  role: number;
}

export interface MemberUser {
  email: string;
  first_name: string;
  last_name: string;
  username?: string;
}

export interface MembersResponse {
  id: number;
  avatar: string;
  hubspot_id: string;
  phone: string;
  role: number;
  role_group: number;
  title: string;
  user: MemberUser;
  firm?: number;
}
export interface MembersRequest {
  avatar: Nullable<File>;
  hubspot_id: string;
  phone: string;
  role: number;
  role_group: number;
  title: string;
  user: MemberUser;
  firm?: number;
}

export interface MemberGroupResponse {
  id: number;
  name: string;
  members: Member[];
}
export interface AffiliatesResponse {
  id: number;
  name: string;
  url: string;
  fee_type: AffiliatesFeeTypes;
  percentage: number;
  flat_fee: number;
  deals: number;
}

export interface SingleAffiliateResponse {
  id: number;
  text_id: string;
  name: string;
  url: string;
  description: string;
  standard_logo: string;
  knockout_logo: string;
  contracts: AffiliateContract[];
}

export interface AffiliateRequest {
  text_id: string;
  name: string;
  url: string;
  description: string;
  standard_logo: Nullable<File>;
  knockout_logo: Nullable<File>;
  contracts: AffiliateContract[];
}

export interface UpdateAffiliateRequest {
  text_id: string;
  name: string;
  url: string;
  description: string;
  standard_logo: Nullable<File> | string;
  knockout_logo: Nullable<File> | string;
  contracts: AffiliateContract[];
}

export interface MemberGroupRequest {
  name: string;
  members: number[];
}

export interface Application {
  id: number;
  session: string;
  company_name: string;
  status: string;
}
export interface AllAplicationsResponse {
  count: number;
  next: string | null;
  previous: string | null;
  results: Application[];
}

export interface ErcaApplicationResponse {
  deal_id: number;
  deal_session: string;
  company_name: string;
  assignee: Member[];
  started_at: string;
}

export interface AssignErcaApplicationRequest {
  deal_id: number;
  assignee_id: number;
}

export interface ErcaAplicationTabResponse {
  count: number;
  next: string | null;
  previous: string | null;
  results: ErcaApplicationResponse[];
}

export interface AssigmentsResponse {
  company: string;
  assignees: Member[];
  session: string;
}

export interface AssignApplicationRequest {
  deal_id: number;
  assignees: Array<number | string>;
}

export interface MemberManagersResponse {
  id: number;
  members: Array<{
    id: number;
    name: string;
    title: string;
    role: string;
  }>;
  name: string;
}

export interface ManagerMembersRequest {
  manager: number;
  members: number[];
}
