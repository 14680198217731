import { FC } from 'react';
import { SVGIconProps } from './types';

const SVGIconNavChevronDown: FC<SVGIconProps> = ({
  width = 10,
  height = 6,
  fill = 'none',
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 10 6"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.8004 1.00038L5.33594 4.53591L8.87147 1.00038"
      stroke="#F3F3F3"
      strokeWidth="2" 
      strokeLinecap="round"/>
  </svg>
);

export default SVGIconNavChevronDown;
