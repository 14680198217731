import { FC } from "react";
import { SVGIconProps } from "./types";

const SVGIconReturn: FC<SVGIconProps> = ({
  width = 16,
  height = 16,
  fill = "#5514E6",
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.8001 2.39999C4.1867 2.39999 4.5001 2.71339 4.5001 3.09999V4.57087C5.38916 3.66341 6.62846 3.09999 8.0001 3.09999C10.1347 3.09999 11.9488 4.46452 12.6211 6.36672C12.7499 6.73122 12.5589 7.13115 12.1944 7.25998C11.8299 7.38882 11.43 7.19777 11.3011 6.83327C10.8203 5.47293 9.52293 4.49999 8.0001 4.49999C6.85565 4.49999 5.83852 5.04951 5.19964 5.89999H7.3001C7.6867 5.89999 8.0001 6.21339 8.0001 6.59999C8.0001 6.98659 7.6867 7.29999 7.3001 7.29999H3.8001C3.4135 7.29999 3.1001 6.98659 3.1001 6.59999V3.09999C3.1001 2.71339 3.4135 2.39999 3.8001 2.39999ZM3.80581 8.74001C4.17031 8.61117 4.57024 8.80222 4.69907 9.16672C5.17988 10.5271 6.47727 11.5 8.0001 11.5C9.14455 11.5 10.1617 10.9505 10.8006 10.1L8.7001 10.1C8.3135 10.1 8.0001 9.78659 8.0001 9.39999C8.0001 9.01339 8.3135 8.69999 8.7001 8.69999H12.2001C12.3857 8.69999 12.5638 8.77374 12.6951 8.90502C12.8263 9.03629 12.9001 9.21434 12.9001 9.39999V12.9C12.9001 13.2866 12.5867 13.6 12.2001 13.6C11.8135 13.6 11.5001 13.2866 11.5001 12.9V11.4291C10.611 12.3366 9.37173 12.9 8.0001 12.9C5.86551 12.9 4.05143 11.5355 3.3791 9.63327C3.25027 9.26877 3.44131 8.86884 3.80581 8.74001Z"
      fill={fill}
    />
  </svg>
);

export default SVGIconReturn;
