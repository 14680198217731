export const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
];

export type Nullable<Type> = Type | null;

export interface CompanyOwner {
  id: number;
  full_name: string;
  stake: number;
  on_payroll: boolean;
}

export interface UploadedFileAssociation {
  id: number;
  label: string;
}
export enum FileStatus {
  PENDING = 1,
  APPROVED = 2,
  REJECTED = 3,
  FLAGGED = 4
}

export enum ProviderStatus {
  PENDING = 'pending',
  APPROVED = 'approved',
  REJECTED = 'rejected'
}

export enum MemberGroupRole {
  CSC_USER = 2,
  CSC_TEAM_LEAD = 3,
  CSC_MANAGER = 4,
  DATA_AGGREGATOR = 5,
  DATA_AGGREGATOR_MANAGER = 6,
  CPA = 7,
  SUPER_ADMIN = 8,
  EXECUTIVE = 9,
  LAW_ADMIN = 10
}

export interface UploadedFile {
  id: number;
  url: string;
  name: string;
  original_name?: string;
  note?: string;
  extension?: string;
  association?: UploadedFileAssociation;
  status?: FileStatus;
  updated_at?: string;
  created_at?: string;
  flagged_reason?: string | null;
  flagged_reason_text?: string | null;
  flagged_updated_at?: string | null;
  flagged_at?: string | null;
  category?: string;
  flagged_updated?: boolean | null;
}

export interface UploadedFileNote {
  id: number;
  role: string;
  note: string;
}

// NON MUTABLE ENTITIES

export interface State {
  id: number;
  name: string;
}

export interface Industry {
  id: number;
  name: string;
}

export interface AnnualRevenue {
  id: number;
  name: string;
}

export interface FilingType {
  id: number;
  name: string;
}

export interface Owner {
  id: number;
  name: string;
}

export interface Relationship {
  id: number;
  name: string;
}

export interface CovidDisruptionType {
  id: number;
  name: string;
}

export interface ConnectionProvider {
  id: string;
  name: string;
  image: string;
}

export interface Discovery {
  id: number;
  name: string;
}
