import { FC } from 'react';
import { SVGIconProps } from './types';

const SVGIconUpDownArrow: FC<SVGIconProps> = ({
  width = 16,
  height = 15,
  stroke = 'black',
  strokeWidth = 1.67
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      stroke={stroke}
      strokeWidth={strokeWidth}
      d="M4.11111 10.203V0.869629M4.11111 0.869629L1 3.98074M4.11111 0.869629L7.22222 3.98074M11.8889 3.98074V13.3141M11.8889 13.3141L15 10.203M11.8889 13.3141L8.77778 10.203"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SVGIconUpDownArrow;
