import { SVGIcon } from 'components/icons';
import { FC } from 'react';
import style from './file-upload-controller.module.scss';
import { Button } from 'components/atomic';

interface FileUploadControllerProps {
  label: string;
  note?: string;
  file?: string | null;
  onOpen?: () => void;
}

const FileUploadController: FC<FileUploadControllerProps> = ({
  label,
  note = null,
  file = null,
  onOpen = () => {}
}) => {
  return (
    <>
      {!file ? (
        <>
          <div className={style.wrapper}>
            <div>
              <div className={style.name}>
                <p>{label}</p>
                {note && <span>{note}</span>}
              </div>
            </div>
            <Button
              variant="primary"
              size="sm"
              onClick={onOpen}
              type="button"
              className="px--4"
            >
              Choose file
            </Button>
          </div>
        </>
      ) : (
        <>
          <div className={style.wrapper}>
            <div>
              <SVGIcon variant="checkmark-circle" />
              <div className={style.name}>
                <p>{label}</p>
                <span>{file}</span>
              </div>
            </div>
            <Button
              variant="edit"
              size="sm"
              iconType="fill"
              onClick={onOpen}
              type="button"
            >
              <SVGIcon variant="edit" />
              Edit
            </Button>
          </div>
        </>
      )}
    </>
  );
};

export default FileUploadController;
