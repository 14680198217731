import { FC } from 'react';
import { SVGIconProps } from './types';

const SVGIconUsers: FC<SVGIconProps> = ({
  width = 38,
  height = 30,
  stroke = '#5514E6',
  strokeWidth = 1.875
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 38 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M27.75 29H36.5V25.5C36.5 22.6005 34.1495 20.25 31.25 20.25C29.5777 20.25 28.088 21.0319 27.1266 22.2501M27.75 29H10.25M27.75 29V25.5C27.75 24.3516 27.5288 23.2548 27.1266 22.2501M10.25 29H1.5V25.5C1.5 22.6005 3.85051 20.25 6.75 20.25C8.42231 20.25 9.912 21.0319 10.8734 22.2501M10.25 29V25.5C10.25 24.3516 10.4712 23.2548 10.8734 22.2501M10.8734 22.2501C12.1636 19.0268 15.3159 16.75 19 16.75C22.6841 16.75 25.8364 19.0268 27.1266 22.2501M24.25 6.25C24.25 9.14949 21.8995 11.5 19 11.5C16.1005 11.5 13.75 9.14949 13.75 6.25C13.75 3.35051 16.1005 1 19 1C21.8995 1 24.25 3.35051 24.25 6.25ZM34.75 11.5C34.75 13.433 33.183 15 31.25 15C29.317 15 27.75 13.433 27.75 11.5C27.75 9.567 29.317 8 31.25 8C33.183 8 34.75 9.567 34.75 11.5ZM10.25 11.5C10.25 13.433 8.683 15 6.75 15C4.817 15 3.25 13.433 3.25 11.5C3.25 9.567 4.817 8 6.75 8C8.683 8 10.25 9.567 10.25 11.5Z"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SVGIconUsers;
