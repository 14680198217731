import { FC } from 'react';
import { SVGIconProps } from './types';

const SVGIconBulkUploads: FC<SVGIconProps> = ({
  width = 20,
  height = 21,
  fill = 'white'
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.875 16.2168C3.875 15.7335 4.26675 15.3418 4.75 15.3418H15.25C15.7333 15.3418 16.125 15.7335 16.125 16.2168C16.125 16.7 15.7333 17.0918 15.25 17.0918H4.75C4.26675 17.0918 3.875 16.7 3.875 16.2168ZM6.75628 7.21052C6.41457 6.86881 6.41457 6.31479 6.75628 5.97308L9.38128 3.34808C9.54538 3.18398 9.76794 3.0918 10 3.0918C10.2321 3.0918 10.4546 3.18398 10.6187 3.34808L13.2437 5.97308C13.5854 6.31479 13.5854 6.86881 13.2437 7.21052C12.902 7.55222 12.348 7.55222 12.0063 7.21052L10.875 6.07923L10.875 12.7168C10.875 13.2 10.4832 13.5918 10 13.5918C9.51675 13.5918 9.125 13.2 9.125 12.7168L9.125 6.07923L7.99372 7.21052C7.65201 7.55222 7.09799 7.55222 6.75628 7.21052Z"
      fill={fill}
    />
  </svg>
);

export default SVGIconBulkUploads;
