import React, {
  Dispatch,
  PropsWithChildren,
  useCallback,
  useReducer
} from 'react';

export type SendbirdState = {
  isOpen: boolean;
};

enum ActionType {
  TOGGLE = 'TOGGLE'
}

interface SendbirdContextProps {
  state: SendbirdState;
  dispatch: Dispatch<keyof typeof ActionType>;
}

const SendbirdContext = React.createContext(
  {} as SendbirdContextProps & {
    toggle: () => void;
  }
);

export function useSendbirdContext() {
  return React.useContext(SendbirdContext);
}

const initialState = {
  isOpen: false
};

function reducer(state: SendbirdState, action: keyof typeof ActionType) {
  switch (action) {
    case ActionType.TOGGLE:
      return { isOpen: !state.isOpen };
    default:
      return state;
  }
}

export const SendbirdContextProvider = ({ children }: PropsWithChildren) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const toggle = useCallback(() => dispatch(ActionType.TOGGLE), []);

  const providerState = {
    state,
    dispatch,
    toggle
  };

  return (
    <SendbirdContext.Provider value={providerState}>
      {children}
    </SendbirdContext.Provider>
  );
};
