import { FC } from 'react';
import { SVGIconProps } from './types';

const SVGIconArrowRight: FC<SVGIconProps> = ({
  width = 24,
  height = 25,
  stroke = '#5C5C5C',
  strokeWidth = 1.67
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.5313 7.83343L17.7218 19.1664C17.652 20.1433 16.8392 20.9001 15.8598 20.9001H8.13601C7.15668 20.9001 6.34386 20.1433 6.27408 19.1664L5.46458 7.83343M10.1313 11.5668V17.1668M13.8646 11.5668V17.1668M14.7979 7.83343V5.03343C14.7979 4.51797 14.38 4.1001 13.8646 4.1001H10.1313C9.61578 4.1001 9.19792 4.51797 9.19792 5.03343V7.83343M4.53125 7.83343H19.4646"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SVGIconArrowRight;
