import { FC } from 'react';
import { SVGIconProps } from './types';

const SVGIconEye: FC<SVGIconProps> = ({
  width = 24,
  height = 22,
  fill = 'black',
  stroke = 'black'
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 22"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill={fill}
      stroke={stroke}
      d="M6.66667 10.6666C6.66667 7.72075 9.05417 5.33325 12 5.33325C14.9458 5.33325 17.3333 7.72075 17.3333 10.6666C17.3333 13.6124 14.9458 15.9999 12 15.9999C9.05417 15.9999 6.66667 13.6124 6.66667 10.6666ZM12 13.9999C13.8417 13.9999 15.3333 12.5083 15.3333 10.6666C15.3333 8.82492 13.8417 7.33325 12 7.33325H11.8792C11.9708 7.54575 12 7.77075 12 7.99992C12 9.47075 10.8042 10.6666 9.33334 10.6666C9.10417 10.6666 8.87917 10.6374 8.66667 10.5458V10.6291C8.66667 12.5083 10.1583 13.9999 12 13.9999ZM3.97584 4.69159C5.9375 2.86825 8.63334 1.33325 12 1.33325C15.3667 1.33325 18.0625 2.86825 20.025 4.69159C21.975 6.49992 23.2792 8.62909 23.8958 10.1541C24.0333 10.4833 24.0333 10.8499 23.8958 11.1791C23.2792 12.6666 21.975 14.7958 20.025 16.6416C18.0625 18.4666 15.3667 19.9999 12 19.9999C8.63334 19.9999 5.9375 18.4666 3.97584 16.6416C2.02584 14.7958 0.722504 12.6666 0.102545 11.1791C0.0348526 11.0167 0 10.8425 0 10.6666C0 10.4907 0.0348526 10.3165 0.102545 10.1541C0.722504 8.62909 2.02584 6.49992 3.97584 4.69159ZM12 3.33325C9.28334 3.33325 7.05 4.56659 5.3375 6.15409C3.73334 7.64575 2.62584 9.37909 2.06 10.6666C2.62584 11.9166 3.73334 13.6874 5.3375 15.1791C7.05 16.7666 9.28334 17.9999 12 17.9999C14.7167 17.9999 16.95 16.7666 18.6625 15.1791C20.2667 13.6874 21.3375 11.9166 21.9417 10.6666C21.3375 9.37909 20.2667 7.64575 18.6625 6.15409C16.95 4.56659 14.7167 3.33325 12 3.33325Z"
    />
  </svg>
);

export default SVGIconEye;
