import { FC } from 'react';
import { SVGIconProps } from './types';

const SVGIconCarretLeft: FC<SVGIconProps> = ({
  width = 7,
  height = 12,
  stroke = 'black',
  strokeWidth = 1.67
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 7 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      stroke={stroke}
      strokeWidth={strokeWidth}
      d="M6 11L1 6L6 1"
      strokeLinecap="round"
    />
  </svg>
);

export default SVGIconCarretLeft;
