import { FC } from 'react';
import style from './dropbox.module.scss';
import { SVGIcon } from 'components/icons';
import { DropzoneState } from 'react-dropzone';
import { UploadFieldState } from '../FormFields/UploadField/UploadField';
import cx from 'classnames';

type DropboxState = UploadFieldState | DropzoneState;

interface DropboxProps {
  state: DropboxState;
  className?: string;
  helperText?: string;
}

const Dropbox: FC<DropboxProps> = ({ state, className, helperText = 'PNG, JPG, GIF up to 2MB' }) => {
  const { isDragActive } = state;

  return (
    <div className={cx(style.container, className)}>
      <div className={cx(style.wrapper, isDragActive ? style.focused : '')}>
        <SVGIcon variant="upload" />
        <p className="fw--500 mt--4 tac">
          {!isDragActive ? (
            <>
              <span className="text-purple-500 tac">Upload a file</span> or drag
              and drop
            </>
          ) : (
            <>Drop it now!</>
          )}
        </p>
        <p className="text-neutral-500 mt--2">{helperText}</p>
      </div>
    </div>
  );
};

export default Dropbox;
