import client from 'api/client';
import {RequestedFileResponse, TaxCreditResponse, TaxDocumentZipResponse} from './interfaces';
import {UploadedFile} from "../../app/common/interfaces/common";

export const getTaxDocumentZip = async (session: string) =>
  (
    await client.get<TaxDocumentZipResponse>(
      `common-dash/documents/${session}/`
    )
  )?.data;

export const getRequestedFiles = async (
  applicationId: string
): Promise<RequestedFileResponse> =>
  (await client.get(`common-dash/requested-docs/${applicationId}/`))?.data;

export const get941x943 = async (
  applicationId: string
): Promise<TaxCreditResponse> =>
  (await client.get(`common-dash/tax/credit/${applicationId}/`))?.data;

export const getFlaggedFiles = async (
  applicationId: string
): Promise<UploadedFile[]> =>
  (await client.get(`common-dash/flagged-files/${applicationId}/`))?.data || null;
