import client from 'api/client';
import {
  BenefitsFilesResponse,
  CompanyInfoAdditionalResponse,
  CompanyInfoBankingResponse,
  CompanyInfoEmployeeResponse,
  CompanyInfoResponse,
  CompanyPEOResponse,
  ContractSignerResponse,
  CovidInfoResponse,
  FileAssociation,
  FinchProviderResponse,
  Payroll941FilesResponse,
  Payroll943FilesResponse,
  PayrollTaxReturnsResponse,
  PayrollWagesFilesResponse,
  PayrollWagesResponse,
  PNLFilesResponse,
  PPPInfoResponse,
  UploadDatesResponse,
} from './interfaces';
import {BenefitsResponse} from 'api/services/account-manager/interfaces/responses/benefits';

export const getUploadDates = async (
  session: string
): Promise<UploadDatesResponse> =>
  (await client.get(`common-dash/upload-dates/${session}/`))?.data;

// FORM REQUESTS SERVICES

export const getContractSigner = async (
  session: string
): Promise<Partial<ContractSignerResponse>> =>
  (await client.get(`common-dash/contract-signer/${session}/`))?.data;

export const getCompanyInfo = async (
  session: string
): Promise<Partial<CompanyInfoResponse>> =>
  (await client.get(`common-dash/company-info/${session}/`))?.data;

export const getCompanyInfoAdditional = async (
  session: string
): Promise<Partial<CompanyInfoAdditionalResponse>> =>
  (await client.get(`common-dash/company-info-additional/${session}/`))?.data;

export const getCompanyPEO = async (
  session: string
): Promise<Partial<CompanyPEOResponse>> =>
  (await client.get(`common-dash/company-peo/${session}/`))?.data;

export const getCompanyInfoBanking = async (
  session: string
): Promise<Partial<CompanyInfoBankingResponse>> =>
  (await client.get(`common-dash/company-info-banking/${session}/`))?.data;

export const getCompanyInfoEmployee = async (
  session: string
): Promise<Partial<CompanyInfoEmployeeResponse>> =>
  (await client.get(`common-dash/company-info-employee/${session}/`))?.data;

export const getCovidInfo = async (
  session: string
): Promise<Partial<CovidInfoResponse>> =>
  (await client.get(`common-dash/covid-info/${session}/`))?.data;

export const getPPPInfo = async (
  session: string
): Promise<Partial<PPPInfoResponse>> =>
  (await client.get(`common-dash/ppp-info/${session}/`))?.data;

export const getPayrollTaxReturns = async (
  session: string
): Promise<Partial<PayrollTaxReturnsResponse>> =>
  (await client.get(`common-dash/payroll-tax-returns/${session}/`))?.data;

export const getPayrollWages = async (
  session: string
): Promise<Partial<PayrollWagesResponse>> =>
  (await client.get(`common-dash/payroll-wages/${session}/`))?.data;

export const getBenefits = async (
  applicationId: string | null
): Promise<Partial<BenefitsResponse>> =>
  (await client.get(`common-dash/benefits/${applicationId}/`))?.data;

// FILES REQUEST SERVICES

export const getFileAssociations = async (): Promise<FileAssociation[]> =>
  (await client.get(`common-dash/file-associations/`))?.data;

export const getBenefitsFiles = async (session: string) =>
  (
    await client.get<BenefitsFilesResponse>(
      `common-dash/benefits-files/${session}/`
    )
  )?.data;

export const getPayroll941Files = async (session: string) =>
  (
    await client.get<Payroll941FilesResponse>(
      `common-dash/payroll-941-files/${session}/`
    )
  )?.data;

export const getPayroll943Files = async (session: string) =>
  (
    await client.get<Payroll943FilesResponse>(
      `common-dash/payroll-943-files/${session}/`
    )
  )?.data;

export const getPayrollWagesFiles = async (session: string) =>
  (
    await client.get<PayrollWagesFilesResponse>(
      `common-dash/payroll-wages-files/${session}/`
    )
  )?.data;

export const getPNLFiles = async (session: string) =>
  (await client.get<PNLFilesResponse>(`common-dash/pnl-files/${session}/`))
    ?.data;

// Finch / Codat

export const getFinchProviderData = async (
  session: string,
  year: number,
  quarter: number
) =>
  (
    await client.get<FinchProviderResponse>(
      `common-dash/payroll-wages-quarterly/${session}/?year=${year}&quarter=${quarter}`
    )
  )?.data;