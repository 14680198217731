import { FC } from 'react';
import { SVGIconProps } from './types';

const SVGIconLink: FC<SVGIconProps> = ({
  width = 15,
  height = 14,
  fill="#4410B8"
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 15 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
        d="M9.51255 2.26256C10.196 1.57915 11.304 1.57915 11.9874 2.26256C12.6708 2.94598 12.6708 4.05402 11.9874 4.73744L9.36242 7.36244C8.679 8.04585 7.57096 8.04585 6.88755 7.36244C6.54584 7.02073 5.99182 7.02073 5.65011 7.36244C5.3084 7.70414 5.3084 8.25816 5.65011 8.59987C7.01694 9.96671 9.23302 9.96671 10.5999 8.59987L13.2249 5.97487C14.5917 4.60804 14.5917 2.39196 13.2249 1.02513C11.858 -0.341709 9.64194 -0.341709 8.27511 1.02513L6.96261 2.33763C6.6209 2.67933 6.6209 3.23335 6.96261 3.57506C7.30432 3.91677 7.85834 3.91677 8.20005 3.57506L9.51255 2.26256Z"
        fill={fill}
    />
    <path
        d="M5.13756 6.63756C5.82098 5.95414 6.92902 5.95414 7.61244 6.63756C7.95414 6.97927 8.50816 6.97927 8.84987 6.63756C9.19158 6.29585 9.19158 5.74183 8.84987 5.40013C7.48304 4.03329 5.26696 4.03329 3.90013 5.40013L1.27513 8.02513C-0.0917087 9.39196 -0.0917087 11.608 1.27513 12.9749C2.64196 14.3417 4.85804 14.3417 6.22487 12.9749L7.53737 11.6624C7.87908 11.3207 7.87908 10.7666 7.53737 10.4249C7.19566 10.0832 6.64164 10.0832 6.29994 10.4249L4.98744 11.7374C4.30402 12.4209 3.19598 12.4209 2.51256 11.7374C1.82915 11.054 1.82915 9.94598 2.51256 9.26256L5.13756 6.63756Z"
        fill={fill}
    />
  </svg>
);

export default SVGIconLink;
