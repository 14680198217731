import { useMutation, useQuery } from '@tanstack/react-query';
import { Modal } from 'antd';

import { Button, Col, Row, Select, TextField } from 'components/atomic';
import { Formik, Form } from 'formik';
import { Dispatch, FC, SetStateAction } from 'react';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import {
  addMemberGroup,
  getAllMembers
} from 'api/services/super-admin/requests';
import { MemberGroupRequest } from 'api/services/super-admin/interfaces';
import { SVGIcon } from 'components/icons';
import { AxiosError } from 'axios';

const AddGroupSchema = Yup.object().shape({
  name: Yup.string().required('You must enter attorney first name')
});

interface AddGroupValues {
  name: string;
  members: number[];
}

interface AddGroupModalProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  onAction: () => void;
}

const memberLabels = ['Member 1', 'Member 2', 'Member 3'];

const AddGroupModal: FC<AddGroupModalProps> = ({
  open = false,
  setOpen,
  onAction
}) => {
  const { data: allMembers } = useQuery(['super-admin/all-members'], () =>
    getAllMembers()
  );
  const { mutate } = useMutation({
    mutationFn: (payload: MemberGroupRequest) => addMemberGroup(payload)
  });

  const initialValues: AddGroupValues = {
    name: '',
    members: []
  };

  return (
    <Modal
      open={open}
      footer={null}
      afterClose={() => {}}
      centered
      width={600}
      onCancel={() => setOpen(false)}
      closeIcon={<SVGIcon variant='close' stroke='black' />}
    >
      <h2 className="fs--3 fw--600 mb--8">Add a group</h2>

      <Formik
        initialValues={initialValues}
        validationSchema={AddGroupSchema}
        enableReinitialize
        validateOnMount
        onSubmit={(values, { setSubmitting, resetForm }) => {
          setSubmitting(true);
          mutate(values, {
            onSuccess() {
              toast.success('Group added successfully.');
              setOpen(false);
              onAction();
              resetForm()
            },
            onError(error) {
              const { response } = error as AxiosError<any>;
              if (response?.data?.non_field_errors.length > 0) {
                toast.error(response?.data?.non_field_errors[0]);
              }else {
                toast.error('There was an issue saving the group')
              }
            },
            onSettled() {
              setSubmitting(false);
            }
          });
        }}
      >
        {({ values, isSubmitting, isValid }) => (
          <Form>
            <Row>
              <Col md={12} className="mb--6">
                <TextField name="name" label="Group name*" value={values.name} />
              </Col>
            </Row>

            <Row>
              <Col md={12} className="mb--6">
                {memberLabels.map((label, index) => {
                  return (
                    <Select
                      key={index}
                      name={`members.${index}`}
                      label={label}
                      placeholder="Select member"
                      options={allMembers?.map(({ id, name }) => ({
                        value: id,
                        label: name,
                        default: 'Choose one'
                      }))}
                      className="mb--6"
                    />
                  );
                })}
              </Col>
            </Row>

            <Row>
              <Col md={12} className="mt--6">
                <Button
                  variant="primary"
                  className="w--100"
                  disabled={!isSubmitting && isValid ? false : true}
                  type="submit"
                >
                  Add Member Group
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default AddGroupModal;
