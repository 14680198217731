import { FC, HTMLAttributes } from 'react';
import { ErrorMessage, FieldHookConfig, useField } from 'formik';
import style from './text-area.module.scss';

type AllowedValue = string | number | string[] | undefined;

type TextAreaProps = FieldHookConfig<AllowedValue> &
  HTMLAttributes<HTMLTextAreaElement> & {
    label?: string;
    labelClass?: string;
    counter?: boolean;
    min?: number;
    max?: number;
    hideError?: boolean
  };

const TextArea: FC<TextAreaProps> = ({ label, hideError, labelClass, disabled, ...props }) => {
  const [field, { error, touched }] = useField({ ...props });

  const generic = props as HTMLAttributes<HTMLTextAreaElement>;
  
  return (
    <div className={style.wrapper}>
      {label && <label htmlFor={props.id ?? props.name} className={labelClass}>{label}</label>}
      <textarea
        className={touched && error && !hideError ? style.error : ''}
        {...generic}
        {...field}
        disabled={disabled}
      >
        {props.children}
      </textarea>
      {!hideError ? <span className={style.error}>
        <ErrorMessage name={props.name} />
      </span> : null}
    </div>
  );
};

export default TextArea;
