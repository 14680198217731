import { FC } from 'react';
import { SVGIconProps } from './types';

const SVGIconChatBubble: FC<SVGIconProps> = ({
  width = 60,
  height = 60,
  stroke = '#FDFDFD'
}) => (
  <svg
    width={height}
    height={width}
    viewBox="-10 -9 60 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="icon-chat-bubble"
  >
    <g>
      <path
        d="M14.1207 20.5016H14.1366M20.4984 20.5016H20.5144M26.8762 20.5016H26.8922M34.8484 20.5016C34.8484 27.5463 28.4237 33.2572 20.4984 33.2572C18.0442 33.2572 15.7338 32.7095 13.7136 31.7442L6.14844 33.2572L8.37267 27.3259C6.9641 25.3525 6.14844 23.0117 6.14844 20.5016C6.14844 13.457 12.5732 7.74609 20.4984 7.74609C28.4237 7.74609 34.8484 13.457 34.8484 20.5016Z"
        stroke={stroke}
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default SVGIconChatBubble;
