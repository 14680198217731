import { FC } from 'react';
import { SVGIconProps } from './types';

const SVGIconForms: FC<SVGIconProps> = ({
  width = 16,
  height = 12,
  stroke = 'black',
  strokeWidth = 1.67
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      stroke={stroke}
      strokeWidth={strokeWidth}
      d="M1 4.53616H15M1 7.64727H15M8 4.53616V10.7584M2.55556 10.7584H13.4444C14.3036 10.7584 15 10.0619 15 9.20282V2.9806C15 2.12149 14.3036 1.42505 13.4444 1.42505H2.55556C1.69645 1.42505 1 2.12149 1 2.9806V9.20282C1 10.0619 1.69645 10.7584 2.55556 10.7584Z"
    />
  </svg>
);

export default SVGIconForms;
