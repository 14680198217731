import { FC } from 'react';
import { SVGIconProps } from './types';

const SVGIconCheckmark: FC<SVGIconProps> = ({
  width = 16,
  height = 12,
  fill = 'black',
  stroke = 'black'
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 12"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill={fill}
      stroke={stroke}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.3801 1.08532C14.6091 1.29524 14.6246 1.65106 14.4146 1.88007L6.16465 10.8801C6.06098 10.9932 5.91561 11.059 5.76222 11.0623C5.60883 11.0657 5.46074 11.0062 5.35225 10.8977L1.60225 7.14772C1.38258 6.92805 1.38258 6.5719 1.60225 6.35223C1.82192 6.13256 2.17808 6.13256 2.39775 6.35223L5.73233 9.68681L13.5854 1.11988C13.7953 0.890873 14.1511 0.875403 14.3801 1.08532Z"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SVGIconCheckmark;
