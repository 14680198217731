import { FC } from 'react';
import { SVGIconProps } from './types';

const SVGIconCovidInfo: FC<SVGIconProps> = ({
  width = 20,
  height = 21,
  stroke = 'white'
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 3.0918V3.86957M10 16.314V17.0918M17 10.0918H16.2222M3.77778 10.0918H3M14.9497 15.0415L14.3998 14.4916M5.60022 5.69202L5.05025 5.14205M14.9498 5.14209L14.3998 5.69206M5.60027 14.4916L5.05029 15.0416M13.1111 10.0918C13.1111 11.81 11.7182 13.2029 10 13.2029C8.28178 13.2029 6.88889 11.81 6.88889 10.0918C6.88889 8.37358 8.28178 6.98069 10 6.98069C11.7182 6.98069 13.1111 8.37358 13.1111 10.0918Z"
      stroke={stroke}
      strokeWidth="1.67"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SVGIconCovidInfo;
