import { FC } from 'react';
import { SVGIconProps } from './types';

const SVGIconChevronDown: FC<SVGIconProps> = ({
  width = 12,
  height = 7,
  fill = 'none',
  stroke = '#2E2E2E',
  strokeWidth = 1.67
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 12 7"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 1L6 6L11 1"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SVGIconChevronDown;
