import client from 'api/client';
import { Nullable } from 'api/services/app/common/interfaces/common';
import {
  NewUserSetPasswordRequest,
  RegisterUserResponse,
  UserLoginPayload,
  UserRegisterPayload,
  UserResponse,
  RegisterInternalUserResponse
} from './types';
import { RegisterPayload } from 'pages/Apply/context/RegisterContext/RegisterContext';

export class User {
  public readonly id: number;
  public readonly username: string;
  public readonly email: string;
  public readonly avatar: string;
  public readonly first_name: string;
  public readonly last_name: string;
  public readonly title: string;
  public readonly firm_id: Nullable<number>;
  public readonly uid: string;
  public readonly test_user: boolean;
  public readonly da_deal_assign: string[];

  protected readonly authorization: {
    roles: string[];
    permissions: string[];
  };

  public constructor({
    id,
    username,
    email,
    avatar,
    first_name,
    last_name,
    title,
    roles,
    permissions,
    firm_id,
    uid,
    test_user = false,
    da_deal_assign,
  }: UserResponse) {
    this.id = id;
    this.username = username;
    this.email = email;
    this.avatar = avatar || '';
    this.first_name = first_name || '';
    this.last_name = last_name || '';
    this.title = title || '';
    this.authorization = {
      roles: roles ?? [],
      permissions: permissions ?? []
    };
    this.firm_id = firm_id || null;
    this.uid = uid || '';
    this.test_user = test_user;
    this.da_deal_assign = da_deal_assign;
  }

  public hasRole(role: string): boolean {
    return this.hasAuthorization('roles', role);
  }

  public hasAnyRole(roles: string[]): boolean {
    if (roles.length === 0) {
      return true;
    }

    return this.hasAnyAuthorization('roles', roles);
  }

  public hasRoles(roles: string[]): boolean {
    if (roles.length === 0) {
      return true;
    }

    return this.hasAuthorizations('roles', roles);
  }

  public get isAccountManager(): boolean {
    return this.hasRoles(['csc-user', 'csc-team-lead', 'csc-manager']);
  }

  public get isDataAggregator(): boolean {
    return this.hasRoles(['data-aggregator', 'data-aggregator-admin']);
  }


  public isDAReadOnly(applicationId: string): boolean {
    return this.isDataAggregator && !this.da_deal_assign.includes(applicationId);
  }

  public get isErcaManager(): boolean {
    return this.hasRole('erca-manager');
  }

  public get isSuperAdmin(): boolean {
    return this.hasRoles(['super-admin']);
  }

  public get isTaxAttorney(): boolean {
    return this.hasRoles(['cpa']);
  }

  public can(permission: string): boolean {
    return this.hasAuthorization('permissions', permission);
  }

  public canAny(permissions: string[]): boolean {
    return this.hasAnyAuthorization('permissions', permissions);
  }

  public canAll(permissions: string[]): boolean {
    return this.hasAuthorizations('permissions', permissions);
  }

  public static clone({ authorization, ...data }: User): User {
    return new User({
      ...data,
      roles: authorization.roles.slice(),
      permissions: authorization.permissions.slice()
    });
  }

  private hasAuthorization(
    key: 'roles' | 'permissions',
    authorization: string
  ): boolean {
    return this.authorization[key].includes(authorization);
  }

  private hasAnyAuthorization(
    key: 'roles' | 'permissions',
    authorizations: string[]
  ): boolean {
    if (this.authorization[key].length === 0) {
      return false;
    }

    return this.authorization[key].some(
      Set.prototype.has,
      new Set(authorizations)
    );
  }

  private hasAuthorizations(
    key: 'roles' | 'permissions',
    authorizations: string[]
  ): boolean {
    if (this.authorization[key].length === 0) {
      return false;
    }

    return this.authorization[key].every(
      Set.prototype.has,
      new Set(authorizations)
    );
  }

  /** API METHODS */

  public static async register(payload: UserRegisterPayload) {
    const { data } = await client.post('auth/register/', payload);

    if (data.redirect_link) return { redirect_link: data.redirect_link };
    return {
      session: data.session,
      user: new User(data.user),
      accessToken: data.access_token,
      refreshToken: data.refresh_token,
      redirect_link: data.redirect_link
    };
  }

  public static async apply(
    payload: RegisterPayload
  ): Promise<RegisterUserResponse> {
    const { data } = await client.post('auth/apply', payload);

    if (data.redirect_link) return { redirect_link: data.redirect_link };
    return {
      session: data.session,
      user: new User(data.user),
      accessToken: data.access_token,
      refreshToken: data.refresh_token,
      redirect_link: data.redirect_link,
      deal_id: data.deal_id,
      deal_owner: data.deal_owner
    };
  }

  public static async newUserSetPassword(
    payload: NewUserSetPasswordRequest
  ): Promise<RegisterInternalUserResponse> {
    const { data } = await client.post(
      `auth/password-set/${payload.token}/`,
      payload
    );

    return {
      user: new User(data.user),
      accessToken: data.access_token,
      refreshToken: data.refresh_token
    };
  }

  public static async login(payload: UserLoginPayload) {
    const { data } = await client.post('auth/token/', payload);

    return {
      user: new User(data.user),
      accessToken: data.access,
      refreshToken: data.refresh
    };
  }

  public static async session() {
    const { data } = await client.get('auth/user/');

    return {
      user: new User(data)
    };
  }
}
