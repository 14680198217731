import client from 'api/client';
import {
  AllTaxFirmResponse,
  AssignCasesRequest,
  AssignedCasesResponse,
  AttorneyStatusRequest,
  CompanyProfileRequest,
  CompletedAttorneyCasesResponse,
  CompletedCasesResponse,
  CompanyProfileResponse,
  UnassignedCasesResponse,
  AttorneyResponse,
  AttorneyRequest,
  AttorneyListResponse,
  AttorneyDocumentsResponse,
  AttorneyDocumentsRequest,
  AttorneyTemplateIdRequest
} from './interfaces';
import { toFormData } from 'axios';

//** GET METHODS **//
export const getAllTaxFirms = async (): Promise<AllTaxFirmResponse[]> =>
  (await client.get(`super-admin/firms/`))?.data;

export const getUnassignedCases = async (
  companyId: string
): Promise<UnassignedCasesResponse[]> =>
  (
    await client.get(
      `tax-attorney/admin/cases/?tab=unassigned&lawfirm_id=${companyId}`
    )
  )?.data;
export const getAssignedCases = async (
  companyId: string
): Promise<AssignedCasesResponse[]> =>
  (
    await client.get(
      `tax-attorney/admin/cases/?tab=assigned&lawfirm_id=${companyId}`
    )
  )?.data;
export const getCompletedCases = async (
  companyId: string
): Promise<CompletedCasesResponse[]> =>
  (
    await client.get(
      `tax-attorney/admin/cases/?tab=completed&lawfirm_id=${companyId}`
    )
  )?.data;

export const getAttorneyProgressCases = async (
  companyId: string,
  id: string
): Promise<UnassignedCasesResponse[]> =>
  (
    await client.get(
      `tax-attorney/admin/attorneys/${id}/cases/?tab=pending&lawfirm_id=${companyId}`
    )
  )?.data;

export const getAttorneyCompletedCases = async (
  companyId: string,
  id: string
): Promise<CompletedAttorneyCasesResponse[]> =>
  (
    await client.get(
      `tax-attorney/admin/attorneys/${id}/cases/?tab=completed&lawfirm_id=${companyId}`
    )
  )?.data;

export const getCompanyAttorneys = async (
  companyId: string
): Promise<AttorneyListResponse[]> =>
  (await client.get(`tax-attorney/admin/attorneys/?lawfirm_id=${companyId}`))
    ?.data;

export const getSingleAttorney = async (
  companyId: string,
  id: string
): Promise<{ data: AttorneyResponse }> =>
  await client.get(
    `tax-attorney/admin/attorneys/${id}/?lawfirm_id=${companyId}`
  );

export const getAttorneyDocuments = async (
  companyId: string
): Promise<AttorneyDocumentsResponse[]> =>
  (await client.get(`tax-attorney/admin/documents/?lawfirm_id=${companyId}`))
    ?.data;

export const uploadAttorneyDocument = async (
  payload: AttorneyDocumentsRequest
) => {
  return client.patch(
    `tax-attorney/admin/documents/${payload.id}/?lawfirm_id=${payload.companyId}`,
    toFormData({ sample: payload.file }),
    {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
  );
};

export const updateAttorneyTemplate = async (
  payload: AttorneyTemplateIdRequest
) => {
  return client.patch(
    `tax-attorney/admin/documents/${payload.id}/?lawfirm_id=${payload.companyId}`,
    { template_id: payload.template_id }
  );
};

export const getLawFirmInfo = async (
  companyId: string
): Promise<{ data: CompanyProfileResponse }> =>
  await client.get(`super-admin/firms/${companyId}`);

/** POST METHODS **/
export const postAssignCases = async (payload: AssignCasesRequest) =>
  (await client.post(`tax-attorney/admin/case-assign/`, payload))?.data;

export const postAddAttorney = async (payload: AttorneyRequest) =>
  (await client.post(`tax-attorney/admin/attorneys/`, payload))?.data;

export const updateAttorney = async (
  id: number,
  companyId: string,
  payload: AttorneyRequest
) =>
  (
    await client.patch(
      `tax-attorney/admin/attorneys/${id}/?lawfirm_id=${companyId}`,
      payload
    )
  )?.data;

export const updateAttorneyStatus = async (
  id: number,
  companyId: string,
  payload: AttorneyStatusRequest
) =>
  (
    await client.patch(
      `tax-attorney/admin/attorneys/${id}/?lawfirm_id=${companyId}`,

      payload
    )
  )?.data;

export const updateCompanyProfile = async (
  companyId: string,
  payload: CompanyProfileRequest
) => {
  return client.patch(`super-admin/firms/${companyId}/`, toFormData(payload), {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};

export const addCompanyProfile = async (payload: CompanyProfileRequest) => {
  return client.post(`super-admin/firms/`, toFormData(payload), {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};
