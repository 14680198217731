import { FC } from 'react';
import { SVGIconProps } from './types';

const SVGIconCaseNotes: FC<SVGIconProps> = ({
  width = 16,
  height = 17,
  stroke = 'black',
  strokeWidth = 1.67
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15 9.10938V2.98438C15 2.01788 14.2165 1.23438 13.25 1.23438H2.75C1.7835 1.23438 1 2.01788 1 2.98438V9.10938M15 9.10938V13.4844C15 14.4509 14.2165 15.2344 13.25 15.2344H2.75C1.7835 15.2344 1 14.4509 1 13.4844V9.10938M15 9.10938H12.7374C12.5054 9.10938 12.2828 9.20156 12.1187 9.36566L10.0063 11.4781C9.84219 11.6422 9.61963 11.7344 9.38756 11.7344H6.61244C6.38037 11.7344 6.15781 11.6422 5.99372 11.4781L3.88128 9.36566C3.71719 9.20156 3.49463 9.10938 3.26256 9.10938H1"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SVGIconCaseNotes;
