import { FC } from 'react';
import { SVGIconProps } from './types';

const SVGIconArrowRight: FC<SVGIconProps> = ({
  width = 17,
  height = 14,
  fill = 'black',
  stroke = 'black',
  strokeWidth = 2
}) => (
  <svg
    width={width}
    height={height}
    fill={fill}
    viewBox="0 0 17 14"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.0556 12.4446L15.5 7.00011M15.5 7.00011L10.0556 1.55566M15.5 7.00011H1.5"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SVGIconArrowRight;
