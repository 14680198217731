import { FC } from 'react';
import { SVGIconProps } from './types';

const SVGIconPageLeft: FC<SVGIconProps> = ({
  width = 7,
  height = 12,
  fill = 'none',
  stroke = '#737373',
  strokeWidth = 1.67
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 7 12"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
        d="M6 11L1 6L6 1"
        stroke={stroke}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"/>
  </svg>
);

export default SVGIconPageLeft;
