import { FC } from 'react';
import { SVGIconProps } from './types';

const SVGIconClockCircle: FC<SVGIconProps> = ({
  width = 14,
  height = 14,
  stroke = '#CC7A00',
  strokeWidth = "1.67",
  className = '',
  onClick
}) => (
  <svg width={width} height={height} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={onClick} className={className}>
    <path d="M7.00039 4.50955V6.99844L8.86706 8.8651M12.6004 6.99844C12.6004 10.0912 10.0932 12.5984 7.00039 12.5984C3.9076 12.5984 1.40039 10.0912 1.40039 6.99844C1.40039 3.90564 3.9076 1.39844 7.00039 1.39844C10.0932 1.39844 12.6004 3.90564 12.6004 6.99844Z" stroke={stroke} strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

export default SVGIconClockCircle;
