import { FC } from 'react';
import { SVGIconProps } from './types';

const SVGIconZoomOut: FC<SVGIconProps> = ({
    width = 16,
    height = 16,
    fill = "#2E2E2E",
    onClick
}) => (
    <svg
      viewBox="0 0 16 16"
      width={width}
      height={height}
      onClick={onClick}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.39844 7.99922C2.39844 7.55739 2.75661 7.19922 3.19844 7.19922L12.7984 7.19922C13.2403 7.19922 13.5984 7.55739 13.5984 7.99922C13.5984 8.44105 13.2403 8.79922 12.7984 8.79922L3.19844 8.79922C2.75661 8.79922 2.39844 8.44105 2.39844 7.99922Z"
            fill={fill}
        />
    </svg>
);

export default SVGIconZoomOut;
