import { FC } from 'react';
import { SVGIconProps } from './types';

const SvgIconAddFIle: FC<SVGIconProps> = ({
  width = 12,
  height = 14,
  stroke = '#2E2E2E',
  strokeWidth = 1.67
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 12 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.12951 7.62212H7.86285M5.99618 5.75546L5.99618 9.48879M9.10729 12.5999H2.88507C2.19778 12.5999 1.64062 12.0427 1.64062 11.3555V2.64435C1.64062 1.95706 2.19778 1.3999 2.88507 1.3999H6.36067C6.52569 1.3999 6.68396 1.46546 6.80065 1.58215L10.1695 4.95099C10.2862 5.06768 10.3517 5.22595 10.3517 5.39097V11.3555C10.3517 12.0427 9.79458 12.5999 9.10729 12.5999Z"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgIconAddFIle;
